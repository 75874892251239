import { ActivityContext } from 'context/activities/ActivityState';
import { useState, useContext, useEffect } from 'react';
import { Button, Card, Table, Container, Row, Col, Modal, Accordion, Form, InputGroup, ListGroup, FormControl } from "react-bootstrap";
import ActivityForm from './ActivityForm';
import moment from 'moment';
import "moment/locale/es"; //always use French
import { useAuth } from 'context/auth/AuthContext';


const ActivityList = () => {
  const { activities, removeActivity } = useContext(ActivityContext);

  
  const [data, setData] = useState(activities);
  const [modalEdit, setModalEdit] = useState(false);
  const [modalDelete, setModalDelete] = useState(false);

  const [activitySelected, setActivitySelected] = useState<any>(null);
  const { matchRole } = useAuth();


  useEffect(() => {
    setData(activities);
  }, [activities]);

  const filterList = (event: any) => {
		let updatedList = [...activities];
		updatedList = updatedList.filter(({ name }) => {
      return name.toLowerCase().search(event.target.value.toLowerCase()) !== -1;
    });
    setData(updatedList);
	}

  const selectActivity= (model: any)=>{
    setActivitySelected(model);
    setModalEdit(true);
  }

  const deleteActivity =()=>{
    setModalDelete(false);
    removeActivity(activitySelected);
  }
  const closeModal = () => {
    setModalEdit(false);
    setActivitySelected(null);
  }

  return (
    <>
      <Col>
        <Row>
          <Col md="6">
            <h4>Lista de actividades agregadas</h4>
            <p className="card-category">
              Puedes agregar, editar y eliminar actividades
            </p>
          </Col>
          <Col md="3">  
            <InputGroup className="mb-3">
              <InputGroup.Text id="search-input" >
                <i className="nc-icon nc-zoom-split"></i>
              </InputGroup.Text>
              <FormControl
                placeholder="Buscar Actividad"
                aria-label="Burcar"
                aria-describedby="search-input"
                onChange={filterList}
              />
            </InputGroup>
          </Col>
          <Col>
          {!matchRole(['consulta','contabilidad']) && 
            <Button className="float-end" variant="outline-primary" onClick={() => selectActivity({})}>Agregar Actividad <i className="fas fa-plus"></i></Button>
          }
          </Col>
        </Row>
        <Accordion>
          {data.map((model: any, index: any)=>(
            <Accordion.Item key={`activity-${model._id || index}`} eventKey={`activity-${model._id || index}`}>
              <Accordion.Header className="mt-0">
                <div className="d-flex flex-grow-1 align-items-center pe-4">
                  {model.name}
                  {!matchRole(['consulta','contabilidad']) && 
                  <span className="ms-auto btn btn-sm btn-outline-secondary" title="Editar" onClick={(e)=> {e.stopPropagation(); selectActivity(model)}}>
                    <i className="fas fa-pencil-alt"></i></span>
                  }
                </div>
              </Accordion.Header>
              <Accordion.Body>
                <Row className="pb-3">
                  <Col md='12'>
                    <b>Recetas</b>
                    <ListGroup variant="flush">
                      {model.recipes &&
                        model.recipes.map((r: any, index: number) => (
                          <ListGroup.Item key={index} className="d-flex"><b>{r.recipe.name}</b>&nbsp; Porciones: {r.servings} | Fecha: {moment(r.date).format('LL')} | Sesión: {r.session} | Encargado: {r.teacher} </ListGroup.Item>
                        ))
                      }
                    </ListGroup>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    {model.description && <><b>Descripción</b>
                    {model.amountOfPeople && <span> ({model.amountOfPeople} {`persona${model.amountOfPeople > 1 ? 's' : ''}`})</span>}
                    <p>{model.description}</p></>}
                  </Col>
                  <Col>
                    {model.initialDate && <><b>Fecha de Inicio</b>
                    <p>{moment(model.initialDate).format('LL')}</p></>}
                  </Col>
                  <Col>
                    {model.initialDate && <><b>Fecha de Finalización</b>
                    <p>{moment(model.endDate).format('LL')}</p></>}
                  </Col>
                </Row>
                <Row>
                  <Col md="6">
                    <b>Programa</b>
                    {model.activityType && <p>{model.activityType.name}</p>}
                  </Col>
                </Row>
                <div className="d-flex justify-content-between align-items-center">
                  {model.weeksDuration && <small className="fw-lighter">Semanas Duración {model.weeksDuration}</small>}
                  {!matchRole(['consulta','contabilidad']) && 
                  <Button className="text-danger" title="Eliminar" variant="outline-light" size="sm" onClick={()=> { setActivitySelected(model); setModalDelete(true); }}><i className="fas fa-trash-alt"></i></Button>
                  }
                </div>
              </Accordion.Body>
            </Accordion.Item>
          ))}
        </Accordion>
      </Col>
      <Modal show={modalDelete}>
        <Modal.Body>
          Estás seguro que deseas eliminar la actividad {activitySelected && activitySelected.name}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={()=>deleteActivity()}>Sí</Button>
          <Button variant="secondary" onClick={()=>setModalDelete(false)}>No</Button>
        </Modal.Footer>
      </Modal>

      {modalEdit && 
        <ActivityForm show={modalEdit}
        activity={activitySelected}
        close={() => closeModal()}/>
      }
    </>
  );
}

export default ActivityList;
