import { useLocation, Link } from "react-router-dom";
import { useAuth } from "../context/auth/AuthContext";
import { Navbar, Container, Nav, NavDropdown, Button } from "react-bootstrap";
import IRoute from "interfaces/route";
import { MouseEvent } from "react";

function Header({ name, children: routes, layout }: { name: string, children: IRoute[], layout: string }) {
  const { logout, user } = useAuth();
  const location = useLocation();
  const mobileSidebarToggle = (e: MouseEvent<HTMLElement>) => {
    e.preventDefault();
    document.documentElement.classList.toggle("nav-open");
    var node = document.createElement("div");
    node.id = "bodyClick";
    node.onclick = (ev: any): void => {
      if (ev.currentTarget && ev.currentTarget.parentElement) {
        ev.currentTarget.parentElement.removeChild(ev.currentTarget);
        document.documentElement.classList.toggle("nav-open");
      } 
    };
    document.body.appendChild(node);
  };
  const activeRoute = (routeName: string) => {
    return location.pathname == routeName ? "active" : "";
  };


  const roleRoutes = routes.filter(r => !r.permit || r.permit?.includes(user.role));
  return (
    <Navbar variant="light" bg="light" expand="lg">
      <Container className="px-4 py-lg-3">
        <Button variant="dark" className="d-lg-none btn-fill d-flex justify-content-center align-items-center rounded-circle p-2" onClick={mobileSidebarToggle}>
          <i className="fas fa-ellipsis-v"></i>
        </Button>
        <Navbar.Brand className="pt-0 align-self-center"><h4>{name}</h4></Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse>
          <Nav>
            {roleRoutes && roleRoutes.map(r => {
              const url = layout + r.path;
              return r.showMenu && (!r.permit || r.permit?.includes(user.role)) ? (
                <Nav.Item key={r.path}>
                  <Nav.Link className={activeRoute(url)} as={Link} eventKey={url} to={url}>{r.name}</Nav.Link>
                </Nav.Item>
              ) : null
            })}
          </Nav>
        </Navbar.Collapse>
        <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
          <Nav>
            <Nav.Item>
              <Nav.Link onClick={logout}><i className="fas fa-sign-out-alt"></i> Log Out</Nav.Link>
            </Nav.Item>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default Header;
