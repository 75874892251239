import { useLocation, NavLink } from "react-router-dom";
import { Nav } from "react-bootstrap";
import sidebarImage from "assets/img/sidebar.jpg";
import { useAuth } from "context/auth/AuthContext";

function Sidebar({ routes }) {
  const { logout, user } = useAuth();
  const location = useLocation();
  const activeRoute = (routeName) => {
    return location.pathname.indexOf(routeName) > -1 ? "active" : "";
  };

  // const roleRoutes = routes.filter(r => !r.permit || r.permit?.includes(user.role));
  return (
    <div className="sidebar" data-image={sidebarImage} data-color="orange">
      <div className="sidebar-background"/>
      <div className="sidebar-wrapper">
        <div className="logo d-flex align-items-center justify-content-center">
          <a href="https://academiaguaitil.org/" className="simple-text logo-mini me-4">
            <div className="logo-img">
              <img className="rounded-circle" src={require("assets/img/Logo-simbolo-GUAITIL.jpg").default} alt="..."/>
            </div>
          </a>
          <a className="simple-text">
            SMART KITCHEN
          </a>
        </div>
        <Nav>
          {routes.map((prop, key) => {
            if (!prop.redirect)
              return (
                <li className={ activeRoute(prop.layout + prop.path) } key={key}>
                  <NavLink to={prop.layout + prop.path} className="nav-link" activeClassName="active">
                    <i className={prop.icon} />
                    <p>{prop.name}</p>
                  </NavLink>
                </li>
          
              );
            return null;
          })}
        </Nav>
        {/* <div className="position-absolute bottom-0 width-0">
            <img src={require("assets/img/orange-logo-sm.png").default}/>
        </div> */}
      </div>
    </div>
  );
}

export default Sidebar;
