import { ActivityContext } from "context/activities/ActivityState";
import { CatalogContext } from "context/catalogs/CatalogState";
import { PreorderContext } from "context/preorders/PreorderState";
import { ProductContext } from "context/products/ProductState";
import { RecipeContext } from "context/recipes/RecipeState";
import { SupplierContext } from "context/suppliers/SupplierState";
import { ICatalog, IProduct, ISupplier } from "interfaces";
import moment from "moment";
import { useContext, useEffect, useRef, useState } from "react";
import { Card } from "react-bootstrap";
import ExportCSV from "utils/exportCSV";
import ExportExcel from "utils/exportExcel";
import { calculateIva } from "utils/utilities";

const ReportList = () => {

  const { catalogs } = useContext(CatalogContext);
  const { products: _products, productsHash } = useContext(ProductContext);
  const { suppliers: _suppliers } = useContext(SupplierContext);
  const { recipes: _recipes } = useContext(RecipeContext);
  const { activities: _activities } = useContext(ActivityContext);
  const { preorders: _preorders } = useContext(PreorderContext);
  const [programs, setPrograms] = useState<ICatalog[]>([]);
  const [modulos, setModulos] = useState<ICatalog[]>([]);
  const [products, setProducts] = useState<IProduct[]>([]);
  const [suppliers, setSuppliers] = useState<any[]>([]);
  const [recipes, setRecipes] = useState<any[]>([]);
  const [activities, setActivities] = useState<any[]>([]);
  const [preorders, setPreorders] = useState<any[]>([]);
  const [preorderDetails, setPreorderDetails] = useState<any[]>([]);

  const [averageProducts, setAverageProducts] = useState<{}>(null);
  const [recipesCost, setRecipesCost] = useState<{}>(null);

  // hashes
  const [suppliersHash, setSuppliersHash] = useState<{}>({});

  useEffect(() => {
    if (catalogs && catalogs['programas']) {
      setPrograms(catalogs['programas']);
    }

    if (catalogs && catalogs['modulos']) {
      setModulos(catalogs['modulos']);
    }
  }, [catalogs]);

  useEffect(() => {
    if (_products) {
      setProducts(_products);
    }
  }, [_products]);

  useEffect(() => {
    if (productsHash && Object.keys(productsHash).length  && suppliers) {
      const supplierProducts: any[] = [];
      const auxAverageProducts: any = {};
      const auxSuppliersHash = {};
      _suppliers.forEach(s => {
        auxSuppliersHash[s._id] = s.name;
        const data: any = { title: s.name, rows: [] };
        s.products.forEach(p => {
          if (p.product._id && !auxAverageProducts[p.product._id]) {
            auxAverageProducts[p.product._id] = [p.price / p.presentation];
          } else {
            auxAverageProducts[p.product._id].push(p.price / p.presentation);
          }
          data.rows.push({ ...p.product, presentation: p.presentation, price: `${p.price}`, priceIva: `${calculateIva(productsHash[p.product._id], p.price, 1, p.price)}` });
        });
        supplierProducts.push(data);
      });

      setSuppliersHash(auxSuppliersHash);
      setAverageProducts(auxAverageProducts);
      setSuppliers(supplierProducts);
    }
  }, [_suppliers, productsHash])

  useEffect(() => {
    if (averageProducts && _recipes) {
      const recipesProducts: any[] = [];
      const auxRecipesCost: any = {};
      _recipes.forEach(s => {
        const data = { title: s.name, details: [`Descripción: ${s.description}`, `Preparación: ${s.preparation}`, 'Ingredientes:'], rows: [], total: '', totalIva: '' };
        let total = 0, totalIva = 0;
        s.ingredients.forEach((p, i) => {
          if (p.product) {
            const price = averageProducts[p.product._id]
              ? averageProducts[p.product._id].reduce((a, b) => a + b, 0) / averageProducts[p.product._id].length
              : 0;
            const priceIva = calculateIva(productsHash[p.product._id], price, 1, price);
            data.rows.push({ ...p.product, quantity: p.quantity, price: price ? `${price}` : 'n/d', priceIva: `${priceIva}` });
            total += price;
            totalIva += priceIva;
          }
        });
        data.total = `${total}`;
        data.totalIva = `${totalIva.toFixed(2)}`;
        auxRecipesCost[s._id] = { total, totalIva };
        recipesProducts.push(data);
      });

      setRecipesCost(auxRecipesCost);
      setRecipes(recipesProducts);
    }
  }, [averageProducts, _recipes])

  useEffect(() => {
    if (recipesCost && Object.keys(recipesCost).length && _activities && recipes) {
      const activitiesReport: any[] = [];
      _activities.forEach(a => {
        let total = 0,
          totalIva = 0;
        const data = { title: `${a.name}`, 
        details: [
          `Descripción: ${a.description}`,
          `Programa: ${a.activityType?.name}`,
          `Inicio: ${moment(a.initialDate).format('LL')}`,
          `Fin: ${a.endDate ? moment(a.endDate).format('LL') : 'N/A'}`,
          'Recetas:'
        ], rows: [], total: '' };
        a.recipes.forEach(r => {
          if (recipesCost[r.recipe._id]) {  
            const price = recipesCost[r.recipe._id].total;
            const priceIva = recipesCost[r.recipe._id].totalIva;
            data.rows.push({
              recipe: r.recipe.name, portion: r.recipe.portion, price: `${price}`, priceIva: `${priceIva.toFixed(2)}`
            });
            total += price;
            totalIva += priceIva;
          }
        })
        data.total = `${total}`;
        data.totalIva = `${totalIva.toFixed(2)}`;
        activitiesReport.push(data);
      });
      setActivities(activitiesReport);
    }
  }, [recipesCost, _activities, recipes])


  useEffect(() => {
    if (_preorders && Object.keys(suppliersHash).length) {
      const preordersReport: any[] = [];
      const preorderDetailsReport: any[] = [];
      _preorders.map(p => {
        let total = 0,
          totalIva = 0;
        const productRows = [];
        const data = { title: p.preorderCode, details: [`${moment(p.initialDate).format('LL')} - ${moment(p.endDate).format('LL')}`], rows: [] };
        p.activities.map((a: any) => {
          data.rows.push({ activity: a.name, date: moment(a.initialDate).format('LL'), program: a.activityType?.name, module: a.category?.name });
        });
        p.products.sort((a, b) => parseInt(a.supplier) - parseInt(b.supplier)).map(({ product: p, recipes, supplier, quantity, required, presentation, totalPrice }) => {
          const priceIva = calculateIva(productsHash[p._id], totalPrice, 1, totalPrice);
          productRows.push({ product: p.name, recipes: recipes.join(', '),
            supplier: suppliersHash[supplier],
            presentation: `${presentation}`,
            quantity,
            required,
            unitOfMeasure: p.unitOfMeasure,
            price: `${totalPrice}`,
            priceIva: `${priceIva}` });
          total += totalPrice;
          totalIva += priceIva;
        });
        preordersReport.push(data);
        preorderDetailsReport.push({ ...data, rows: productRows, total: `${total}`, totalIva: `${totalIva}` });
      })
      setPreorders(preordersReport);
      setPreorderDetails(preorderDetailsReport);
    }
  }, [_preorders, suppliersHash])

  // Programas
  const programasHeader = [
    { label: 'Nombre', key: 'name' },
    { label: 'Acrónimo', key: 'acronym' }
  ];

  // Products
  const productsHeader = [
    { label: 'Nombre', key: 'name' },
    { label: 'Unidad', key: 'unitOfMeasure' }
  ];

  // Suppliers
  const supplierHeader = [
    // { label: 'Proveedor', key: 'supName' },
    { label: 'Producto', key: 'name' },
    { label: 'Unidad', key: 'unitOfMeasure' },
    { label: 'Presentación', key: 'presentation' },
    { label: 'Precio (₡)', key: 'price' },
    { label: 'Precio + Iva (₡)', key: 'priceIva' },
  ];

  const recipesHeader = [
    { label: 'Receta', key: 'name' },
    { label: 'Cantidad', key: 'quantity' },
    { label: 'Unidad', key: 'unitOfMeasure' },
    { label: 'Precio promedio (₡)', key: 'price' },
    { label: 'Precio + Iva (₡)', key: 'priceIva' }
  ];

  const activitiesHeader = [
    { label: 'Actividad', key: 'recipe' },
    { label: 'Porciones', key: 'portion' },
    { label: 'Precio promedio (₡)', key: 'price' },
    { label: 'Precio promedio Iva (₡)', key: 'priceIva' },
  ];

  const preordersHeader = [
    { label: 'Actividad', key: 'activity' },
    { label: 'Fecha', key: 'date' },
    { label: 'Programa', key: 'program' },
    { label: 'Modulo', key: 'module' },
  ]

  const preordersDetailsHeader = [
    { label: 'Producto', key: 'product' },
    { label: 'Recetas', key: 'recipes' },
    { label: 'Proveedor', key: 'supplier' },
    { label: 'Cantidad a comprar', key: 'quantity' },
    { label: 'Presentación', key: 'presentation' },
    { label: 'Unidad', key: 'unitOfMeasure' },
    { label: 'Requerido por recetas', key: 'required' },
    { label: 'Precio (proveedor) (₡)', key: 'price' },
    { label: 'Precio + Iva (₡)', key: 'priceIva' },
  ]


  // // Módulos
  // const programasHeader = () => [
  //   { label: 'Nombre', key: 'name' },
  //   { label: 'Acrónimo', key: 'acronym' }
  // ];

  // const programasData = () => {
  //   return catalogs?.programas || [];
  // }

  return (
    <div className="d-flex flex-wrap flex-direction-row justify-content-center">
      <Card className="m-2" style={{ width: '20%' }}>
        <Card.Body>
          <Card.Title>Programas</Card.Title>
          <Card.Text>
            Descargar el reporte de programas
          </Card.Text>
          {/* <ExportCSV
                disabled={!catalogs?.programas}
                csvHeaders={programasHeader()}
                csvData={programasData()}
                fileName="Guaitil_Programas.csv"
          /> */}
          {programs.length ?
            <ExportExcel
              id="excel-programs"
              columns={programasHeader}
              // csvHeaders={headers}
              csvDatas={[{ rows: programs }]}
              fileName="Guaitil_Programas"
            /> : 'Cargando...'
          }
        </Card.Body>
      </Card>
      <Card className="m-2" style={{ width: '20%' }}>
        <Card.Body>
          <Card.Title>Módulos</Card.Title>
          <Card.Text>
            Descargar el reporte de módulos
          </Card.Text>
          {modulos.length ?
            <ExportExcel
              id="excel-modules"
              columns={programasHeader}
              // csvHeaders={headers}
              csvDatas={[{ rows: modulos }]}
              fileName="Guaitil_Modulos"
            /> : 'Cargando...'
          }
        </Card.Body>
      </Card>
      <Card className="m-2" style={{ width: '20%' }}>
        <Card.Body>
          <Card.Title>Productos</Card.Title>
          <Card.Text>
            Descargar el reporte de productos
          </Card.Text>
          {products.length ?
            <ExportExcel
              id="excel-products"
              columns={productsHeader}
              // csvHeaders={headers}
              csvDatas={[{ rows: products }]}
              fileName="Guaitil_Productos"
            />
            : 'Cargando...'
          }
        </Card.Body>
      </Card>
      <Card className="m-2" style={{ width: '20%' }}>
        <Card.Body>
          <Card.Title>Proveedores</Card.Title>
          <Card.Text>
            Descargar el reporte de proveedores
          </Card.Text>
          {suppliers.length ?
            <ExportExcel
              id="excel-suppliers"
              columns={supplierHeader}
              // csvHeaders={headers}
              csvDatas={suppliers}
              fileName="Guaitil_Proveedores"
            /> : 'Cargando...'
          }
        </Card.Body>
      </Card>
      <br />
      <Card className="m-2" style={{ width: '20%' }}>
        <Card.Body>
          <Card.Title>Recetas</Card.Title>
          <Card.Text>
            Descargar el reporte de recetas
          </Card.Text>
          {recipes.length ?
            <ExportExcel
              id="excel-recetas"
              columns={recipesHeader}
              // csvHeaders={headers}
              csvDatas={recipes}
              fileName="Guaitil_Recetas"
            /> : "Cargando..."
          }
        </Card.Body>
      </Card>
      <Card className="m-2" style={{ width: '20%' }}>
        <Card.Body>
          <Card.Title>Actividades</Card.Title>
          <Card.Text>
            Descargar el reporte de actividades
          </Card.Text>
          {activities.length ?
            <ExportExcel
              id="excel-actividates"
              columns={activitiesHeader}
              // csvHeaders={headers}
              csvDatas={activities}
              fileName="Guaitil_Actividades"
            /> : 'Cargando...'
          }
        </Card.Body>
      </Card>
      <Card className="m-2" style={{ width: '20%' }}>
        <Card.Body>
          <Card.Title>Preordenes / Actividades</Card.Title>
          <Card.Text>
            Descargar el reporte de preordenes
          </Card.Text>
          {preorders.length ?
            <ExportExcel
              id="excel-preordenes"
              columns={preordersHeader}
              // csvHeaders={headers}
              csvDatas={preorders}
              fileName="Guaitil_Preordenes"
            /> : 'Cargando...'
          }
        </Card.Body>
      </Card>
      <Card className="m-2" style={{ width: '20%' }}>
        <Card.Body>
          <Card.Title>Preordenes / Productos</Card.Title>
          <Card.Text>
            Descargar el reporte de preordenes
          </Card.Text>
          {preorderDetails.length ?
            <ExportExcel
              id="excel-preordenes-productos"
              columns={preordersDetailsHeader}
              // csvHeaders={headers}
              csvDatas={preorderDetails}
              fileName="Guaitil_PreordenesProductos"
            /> : 'Cargando...'
          }
        </Card.Body>
      </Card>
    </div>
  );
}

export default ReportList;
