import { IActivity } from 'interfaces';
import  { createContext, useEffect, useReducer } from 'react';
import axios from "axios";
import activityReducer from './ActivityReducer';
import queryString from 'query-string';

export interface IState {
  activities: IActivity[];
  foundActivities: IActivity[];
  addActivity?: any;
  editActivity?: any;
  removeActivity?: any;
  searchActivities?: any
}

const initialState: IState = {
  activities: [],
  foundActivities: [],
};

export const ActivityContext = createContext(initialState);

export const ActivityProvider = ({ children }: { children: any }) => {
  const [state, dispatch] = useReducer(activityReducer, initialState);

  const getActivities = async () => {
    const res = await axios.get("/api/activities");
    if (res){
      dispatch({
        type: "LOAD_ACTIVITIES",
        payload: res.data
      });
    }
  }

  const searchActivities = async (filters: {}) => {
    const res = await axios.get(`/api/activities/search?${queryString.stringify(filters)}`);
    if (res){
      dispatch({
        type: "FOUND_ACTIVITIES",
        payload: res.data
      });
    }
  }

  useEffect(()=> {
    getActivities();
  },[])

  const addActivity = async (activity: IActivity) => {
    const res = await axios.post("/api/activities", activity);
    if (res){  
      dispatch({
        type: "ADD_ACTIVITY",
        payload: res.data
      });
    }
  }

  const editActivity = async (activity: IActivity) => {
    const res = await axios.put(`/api/activities/${activity._id}`, activity);
    if (res){  
      dispatch({
        type: "EDIT_ACTIVITY",
        payload: res.data
      });
    }
  }

  const removeActivity = async (activity: IActivity) => {
    const res = await axios.put(`/api/activities/${activity._id}`,{ ...activity, state: 'inactive', name:`${activity.name}-INACTIVA` });
    if (res){      
      dispatch({
        type: "EDIT_ACTIVITY",
        payload: res.data
      });
      dispatch({
        type: "REMOVE_ACTIVITY",
        payload: res.data
      });
    }
  }


  return (
    <ActivityContext.Provider
      value={{
        activities: state.activities,
        foundActivities: state.foundActivities,
        addActivity,
        editActivity,
        removeActivity,
        searchActivities
      }}
    >
      {children}
    </ActivityContext.Provider>
  );
};