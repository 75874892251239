import { IProduct } from "interfaces";
import { IState } from "./ProductState";

export default function productReducer(state: IState, action: { type: string; payload: any; }) {
  switch (action.type) {
    case "LOADING_PRODUCTS":
      return {
        ...state,
        loading: true,
      };
    case "LOAD_PRODUCTS":
      return {
        ...state,
        ...processProducts(action.payload)
      };

    case "ADD_PRODUCT":
      return {
        ...state,
        products: [action.payload , ...state.products],
        productsHash: Object.assign({}, {...state.productsHash, [action.payload._id]: action.payload})
      };

    case "EDIT_PRODUCT":
      const updatedProduct = action.payload;

      const updatedProducts = state.products.map((product: IProduct) => {
        if (product._id === updatedProduct._id) {
          return updatedProduct;
        }
        return product;
      });


      return {
        ...state,
        products: updatedProducts,
        productsHash: updatedProducts.reduce((map: any, obj: Partial<IProduct>) => {
          map[obj._id] = obj;
          return map;
        }, {})
      };

    case "REMOVE_PRODUCT":
      const new_products = state.products.filter(
        (product: IProduct) => !product.state || product.state !== 'inactive'
      );
      return {
        ...state,
        ...processProducts(state.products)
      };

    default:
      return state;
  }
};

function processProducts(payload: any) {
  const products: any[] = [],
    productsHash: any = {};
  payload.forEach((p: any) => {
    productsHash[p._id] = p;
    if (!p.state || p.state !== 'inactive') {
      products.push(p);
    }
  });
  return { products, productsHash };
}