import { IOrder } from "interfaces";
import { IState } from "./OrderState";

export default function orderReducer(state: IState, action: { type: string; payload: any; }) {
  switch (action.type) {
    case "LOADING_ORDERS":
      return {
        ...state,
        loading: true,
      };
    case "LOAD_ORDERS":
      return {
        ...state,
        orders: action.payload,
        loading: false
      };

    case "ADD_ORDER":
      return {
        ...state,
        orders: [action.payload, ...state.orders],
      };

    case "EDIT_ORDER":
      const updatedPreorder = action.payload;

      const updatedPreorders = state.orders.map((order: IOrder) => {
        if (order._id === updatedPreorder._id) {
          return updatedPreorder;
        }
        return order;
      });

      return {
        ...state,
        orders: updatedPreorders,
      };

    case "FOUND_ORDERS":
      return {
        ...state,
        foundOrders: action.payload,
      };
  

    case "REMOVE_ORDER":
      return {
        ...state,
        orders: state.orders.filter(
          (order: IOrder) => order._id !== action.payload
        ),
      };

    default:
      return state;
  }
};