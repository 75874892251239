import { PurchaseContext } from 'context/purchases/PurchaseState';
import { useState, useContext, useEffect } from 'react';
import { Button, Card, Table, Container, Row, Col, Modal, Accordion, Form, ListGroup, InputGroup, FormControl } from "react-bootstrap";
import moment from 'moment';
import { formatWithOptions } from 'util';
import PurchaseForm from './PurchaseForm';
import { useHistory } from "react-router";
import { useAuth } from 'context/auth/AuthContext';
import Loading from 'views/Loading';



const PurchaseList = () => {
  const { purchases, loading, removePurchase } = useContext(PurchaseContext);
  const history = useHistory();
  const [data, setData] = useState(purchases);
  const [modalEdit, setModalEdit] = useState(false);
  const [modalDelete, setModalDelete] = useState(false);
  const [purchaseSelected, setPurchaseSelected] = useState<any>(null);
  const { matchRole } = useAuth();


  useEffect(() => {
    setData(purchases);
  }, [purchases]);

  const filterList = (event: any) => {
		let updatedList = [...purchases];
		updatedList = updatedList.filter(({ purchaseCode, purchaseDate, order }) => {
      return `${purchaseCode}${purchaseDate}${order}`.toLowerCase().search(event.target.value.toLowerCase()) !== -1;
    });
    setData(updatedList);
	}

  const selectPurchase= (model: any)=>{
    setPurchaseSelected(model);
    setModalEdit(true);
  }

  const deletePurchase =()=>{
    removePurchase(purchaseSelected._id);
    setModalDelete(false);
    setPurchaseSelected(null);
  }
  const closeModal = () => {
    setModalEdit(false);
    setPurchaseSelected(null);
  }

  return (
    <>
      <Col>
        <Row>
          <Col md="6">
            <h4>Lista de compras realizadas</h4>
            <p className="card-category">
              Puedes agregar y eliminar una compra
            </p>
          </Col>
          <Col>  
            <InputGroup className="mb-3">
              <InputGroup.Text id="search-input" >
                <i className="nc-icon nc-zoom-split"></i>
              </InputGroup.Text>
              <FormControl
                placeholder="Buscar Compra"
                aria-label="Buscar"
                aria-describedby="search-input"
                onChange={filterList}
              />
            </InputGroup>
          </Col>
        </Row>
        {!!loading && <Loading scale="0.5"></Loading> }
        <Accordion>
          {data.map((model, index)=>(
            <Accordion.Item key={`purchase-${model._id || index}`} eventKey={`purchase-${model._id || index}`}>
              <Accordion.Header className="mt-0">
                <div className="d-flex flex-grow-1 align-items-center pe-4">
                  <b className="text-capitalize">{model.purchaseCode}</b>
                  {model.purchaseDate && <span>, {moment(model.purchaseDate).format('LL')}</span>}
                  {matchRole(['administrador','contabilidad']) && 
                    <span className="ms-auto btn btn-sm btn-outline-secondary" title="Editar" onClick={(e)=> {history.push(`purchases/${model._id}`);}}>
                      <i className="fas fa-pencil-alt"></i></span>
                  }
                </div>
              </Accordion.Header>
              <Accordion.Body>
              <Row className="pb-3">
                <Col md="6">
                  {model.program?.length && <><b>Programa: </b>
                    {model.program?.map((p: any) => p.name).join(', ')}</>}
                </Col>
                <Col md="6">
                  {model.supplierInvoice && <><b>Número de Factura Proveedor: </b>
                    {model.supplierInvoice}</>}
                </Col>
              </Row>
                <Row className="pb-3">
                <Col md="6">
                  {model.purchaseDate && <><b>Fecha de Compra: </b>
                    {moment(model.purchaseDate).format('LL')}</>}
                  </Col>
                  <Col md='6'>
                  {model.order && <><b>Orden Asociada: </b>
                    {model.order}</>}

                  {/* {model.order.name && <span> ({model.order.name} {`Proveedor: ${model.order.name}`})</span>} */}
                  </Col>
                  <Col md='6'></Col>
                  <Col md='4'>                            
                    {!!model.totalAmount && <><b>Subtotal: </b> ₡{model.totalAmount}</>}
                    <br></br>
                    {!!model.totalAmount && <><b>Monto Total de Compra: ₡{model.totalAmountIva} </b> </>}
                  </Col>
                </Row>
                <Row className="pb-3">
                <Col>
                    <b>Productos de Compra</b>
                    <ListGroup variant="flush">
                      <Table>
                        <thead>
                          <tr>
                            <th className="col-5">Producto</th>
                            <th className="col-2">Cantidad</th>
                            <th className="col-2">Precio Unitario</th>
                            <th className="col-2">Precio Total</th>
                            <th className="col-2">Precio Total + Iva</th>
                          </tr>
                        </thead>
                        <tbody>
                          {model.products.map(({ product: p, quantity, unitPrice, totalPrice, priceIva }: any, index: number) => (                                 
                            <tr key={`preorder-list-product${index}`}>
                              <td>{p.presentation}{p.unitOfMeasure} de {p.name}</td>
                              <td>{quantity}</td>
                              <td>₡{unitPrice}</td>
                              <td>₡{totalPrice}</td>
                              <td>₡{priceIva}</td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </ListGroup>
                  </Col>
                </Row>
                <div className="d-flex justify-content-end align-items-center">
                  {model.createdBy && <small className="fw-lighter me-3">Creado por {model.createdBy}</small>}
                  {matchRole(['administrador','contabilidad']) && 
                    <Button className="text-danger" title="Eliminar" variant="outline-light" size="sm" onClick={()=>{ setPurchaseSelected(model); setModalDelete(true);}}><i className="fas fa-trash-alt"></i></Button>
                  }
                </div>
              </Accordion.Body>
            </Accordion.Item>
          ))}
        </Accordion>
      </Col>
      <Modal show={modalDelete}>
        <Modal.Body>
          Estás seguro que deseas eliminar la compra {purchaseSelected && purchaseSelected.purchaseCode}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={()=>deletePurchase()}>Sí</Button>
          <Button variant="secondary" onClick={()=>setModalDelete(false)}>No</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default PurchaseList;
