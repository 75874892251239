import { IPurchase } from 'interfaces';
import React, { createContext, useEffect, useReducer } from 'react';
import axios from "axios";
import purchaseReducer from './PurchaseReducer';

export interface IState {
  purchases: IPurchase[];
  loading?: boolean;
  addPurchase?: any;
  editPurchase?: any;
  removePurchase?: any;
}

const initialState: IState = {
  purchases: []
};

export const PurchaseContext = createContext(initialState);

export const PurchaseProvider = ({ children }: { children: any }) => {
  const [state, dispatch] = useReducer(purchaseReducer, initialState);

  const getPurchases = async () => {

    dispatch({
      type: "LOADING_PURCHASES"
    });
    
    const res = await axios.get("/api/purchases");
    if (res){
      dispatch({
        type: "LOAD_PURCHASES",
        payload: res.data
      });
    }
  }

  useEffect(()=> {
    getPurchases();
  },[])

  const addPurchase = async (purchase: IPurchase) => {
    const res = await axios.post("/api/purchases", purchase);
    if (res){  
      dispatch({
        type: "ADD_PURCHASE",
        payload: res.data
      });
    }
  }

  const editPurchase = async (purchase: IPurchase) => {
    const res = await axios.put(`/api/purchases/${purchase._id}`, purchase);
    if (res){  
      dispatch({
        type: "EDIT_PURCHASE",
        payload: res.data
      });
    }
  }

  const removePurchase = async (id: number) => {
    const res = await axios.delete(`/api/purchases/${id}`);
    if (res){      
      dispatch({
        type: "REMOVE_PURCHASE",
        payload: id
      });
    }
  }


  return (
    <PurchaseContext.Provider
      value={{
        purchases: state.purchases,
        loading: state.loading,
        addPurchase,
        editPurchase,
        removePurchase
      }}
    >
      {children}
    </PurchaseContext.Provider>
  );
};