import { IPreorder } from 'interfaces';
import React, { createContext, useEffect, useReducer } from 'react';
import axios from "axios";
import preorderReducer from './PreorderReducer';

export interface IState {
  preorders: IPreorder[];
  loading?: boolean;
  addPreorder?: any;
  editPreorder?: any;
  removePreorder?: any;
}

const initialState: IState = {
  preorders: []
};

export const PreorderContext = createContext(initialState);

export const PreorderProvider = ({ children }: { children: any }) => {
  const [state, dispatch] = useReducer(preorderReducer, initialState);

  const getPreorders = async () => {
    dispatch({
      type: "LOADING_PREORDERS"
    });
    
    const res = await axios.get("/api/preorders");
    if (res){
      dispatch({
        type: "LOAD_PREORDERS",
        payload: res.data
      });
    }
  }

  useEffect(()=> {
    getPreorders();
  },[])

  const addPreorder = async (preorder: IPreorder) => {
    const res = await axios.post("/api/preorders", preorder);
    if (res){  
      dispatch({
        type: "ADD_PREORDER",
        payload: res.data
      });
    }
  }

  const editPreorder = async (preorder: IPreorder) => {
    const res = await axios.put(`/api/preorders/${preorder._id}`, preorder);
    if (res){  
      dispatch({
        type: "EDIT_PREORDER",
        payload: res.data
      });
    }
  }

  const removePreorder = async (id: number) => {
    const res = await axios.delete(`/api/preorders/${id}`);
    if (res){      
      dispatch({
        type: "REMOVE_PREORDER",
        payload: id
      });
    }
  }


  return (
    <PreorderContext.Provider
      value={{
        preorders: state.preorders,
        loading: state.loading,
        addPreorder,
        editPreorder,
        removePreorder
      }}
    >
      {children}
    </PreorderContext.Provider>
  );
};