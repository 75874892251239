import { RecipeContext } from 'context/recipes/RecipeState';
import { useState, useContext, useEffect } from 'react';
import { Button, Card, Table, Container, Row, Col, Modal, Accordion, Form, ListGroup, InputGroup, FormControl } from "react-bootstrap";
import { formatWithOptions } from 'util';
import RecipeForm from './RecipeForm';
import { useAuth } from 'context/auth/AuthContext';
import Loading from 'views/Loading';


const RecipeList = () => {
  const { recipes, loading, removeRecipe, getRecipes } = useContext(RecipeContext);

  const [data, setData] = useState(recipes);
  const [modalEdit, setModalEdit] = useState(false);
  const [modalDelete, setModalDelete] = useState(false);

  const [recipeSelected, setRecipeSelected] = useState<any>(null);
  const { matchRole } = useAuth();

  useEffect(()=> {
    getRecipes();
  },[])
  
  useEffect(() => {
    setData(recipes);
  }, [recipes]);

  const filterList = (event: any) => {
		let updatedList = [...recipes];
		updatedList = updatedList.filter(({ name }) => {
      return name.toLowerCase().search(event.target.value.toLowerCase()) !== -1;
    });
    setData(updatedList);
	}

  const selectRecipe= (model: any)=>{
    setRecipeSelected(model);
    setModalEdit(true);
  }

  const deleteRecipe =()=>{
    setModalDelete(false);
    removeRecipe(recipeSelected);
  }
  const closeModal = () => {
    setModalEdit(false);
    setRecipeSelected(null);
  }

  return (
    <>
      <Col>
        <Row>
          <Col md="6">
            <h4>Lista de recetas agregadas</h4>
            <p className="card-category">
              Puedes agregar, editar y eliminar recetas
            </p>
          </Col>
          <Col md="3">  
            <InputGroup className="mb-3">
              <InputGroup.Text id="search-input" >
                <i className="nc-icon nc-zoom-split"></i>
              </InputGroup.Text>
              <FormControl
                placeholder="Buscar Receta"
                aria-label="Burcar"
                aria-describedby="search-input"
                onChange={filterList}
              />
            </InputGroup>
          </Col>
          <Col>
          {!matchRole(['consulta','contabilidad']) && 
            <Button className="float-end" variant="outline-primary" onClick={() => selectRecipe({})}>Agregar Receta <i className="fas fa-plus"></i></Button>
          }
          </Col>
        </Row>
        {!!loading && <Loading scale="0.5"></Loading> }
        <Accordion>
          {data.map((model, index)=>(
            <Accordion.Item key={`recipe-${model._id || index}`} eventKey={`recipe-${model._id || index}`}>
              <Accordion.Header className="mt-0">
                <div className="d-flex flex-grow-1 align-items-center pe-4">
                  <b className="text-capitalize">{model.name}</b>
                  {model.portion && <span>, {model.portion} {`${model.portion > 1 ? 'porciones' : 'porción'}`}</span>}
                  {model.variation && <span className="ms-1">(variación)</span>}
                  {!matchRole(['consulta','contabilidad']) && 
                  <span className="ms-auto btn btn-sm btn-outline-secondary" title="Editar" onClick={(e)=> {e.stopPropagation(); selectRecipe(model)}}>
                    <i className="fas fa-pencil-alt"></i></span>}
                </div>
              </Accordion.Header>
              <Accordion.Body>
                <Row className="pb-3">
                  <Col md='4'>
                    <b>Ingredientes</b>
                    <ListGroup variant="flush">
                      {model.ingredients &&
                        model.ingredients.map(({ product: p, quantity }, index: number) => {
                          if (!p) { return null; }
                          return <ListGroup.Item key={index} className="d-flex justify-content-between"><span className="text-capitalize">{p.name}</span> <span>{quantity} {p.unitOfMeasure}</span></ListGroup.Item>
                        })
                      }
                    </ListGroup>
                  </Col>
                  <Col md="8">
                    {model.description && 
                      <><b>Descripción</b><p>{model.description}</p></>}
                    {model.preparation && <><b>Preparación</b>
                    {model.variation && <span className="text-warning"> (Variación)</span>}
                    {model.portion && <span> ({model.portion} {`${model.portion > 1 ? 'porciones' : 'porción'}`})</span>}
                    <p>{model.preparation}</p></>}
                  </Col>
                </Row>
                <div className="d-flex justify-content-end align-items-center">
                  {model.createdBy && <small className="fw-lighter me-3">Creado por {model.createdBy}</small>}
                  {!matchRole(['consulta','contabilidad']) && 
                  <Button className="text-danger" title="Eliminar" variant="outline-light" size="sm" onClick={()=>{ setRecipeSelected(model); setModalDelete(true);}}><i className="fas fa-trash-alt"></i></Button>
                  }
                  </div>
              </Accordion.Body>
            </Accordion.Item>
          ))}
        </Accordion>
      </Col>
      <Modal show={modalDelete}>
        <Modal.Body>
          Estás seguro que deseas eliminar la receta {recipeSelected && recipeSelected.name}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={()=>deleteRecipe()}>Sí</Button>
          <Button variant="secondary" onClick={()=>setModalDelete(false)}>No</Button>
        </Modal.Footer>
      </Modal>
                              

      {modalEdit && 
        <RecipeForm show={modalEdit}
        recipe={recipeSelected}
        close={() => closeModal()}/>
      }
    </>
  );
}

export default RecipeList;
