import { IRecipe } from "interfaces";
import { IState } from "./RecipeState";

export default function recipeReducer(state: IState, action: { type: string; payload: any; }) {
  switch (action.type) {
    case "LOADING_RECIPES":
      return {
        ...state,
        loading: true,
      };
    case "LOAD_RECIPES":
      return {
        ...state,
        loading: false,
        recipes: action.payload.filter(
          (recipes: IRecipe) => !recipes.state || recipes.state !== 'inactive'
        ),
      };

    case "ADD_RECIPE":
      return {
        ...state,
        recipes: [action.payload, ...state.recipes],
      };

    case "EDIT_RECIPE":
      const updatedRecipe = action.payload;

      const updatedRecipes = state.recipes.map((recipe: IRecipe) => {
        if (recipe._id === updatedRecipe._id) {
          return updatedRecipe;
        }
        return recipe;
      });

      return {
        ...state,
        recipes: updatedRecipes,
      };

    case "REMOVE_RECIPE":
      return {
        ...state,
        recipes: state.recipes.filter(
          (recipes: IRecipe) => !recipes.state || recipes.state !== 'inactive'
        ),
      };

    default:
      return state;
  }
};