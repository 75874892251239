
import { useState, useContext, useEffect } from 'react';
import { Button, Card, Table, Container, Row, Col, Modal, Accordion, Form, ListGroup, InputGroup, FormControl } from "react-bootstrap";
import { CatalogContext } from 'context/catalogs/CatalogState';
import CatalogForm from './CatalogForm';
import { ICatalog } from 'interfaces';
import { useAuth } from 'context/auth/AuthContext';


const CatalogList = () => {
  const { catalogs, addCatalog, editCatalog, removeCatalog } = useContext(CatalogContext);

  const [data, setData] = useState<ICatalog[]>([]);
  const [modalEdit, setModalEdit] = useState(false);
  const [modalEliminar, setModalEliminar] = useState(false);

  const [catalogSelected, setCatalogSelected] = useState<any>(null);
  const [codeCatalog, setCodeCatalog] = useState<any>('programas');
  const { matchRole } = useAuth();

  useEffect(() => {
    if (catalogs && catalogs[codeCatalog]) {
      setData(catalogs[codeCatalog]);
    }
  }, [catalogs]);

  const filterList = (event: any) => {
    const code = event.target.value;
		let updatedList = [...catalogs[event.target.value]];
		// updatedList = updatedList.filter(({ name }) => {
    //   return name.toLowerCase().search(event.target.value.toLowerCase()) !== -1;
    // });
    setCodeCatalog(code);
    setData(updatedList);
	}

  const selectCatalog= (model: any)=>{
    setCatalogSelected(model);
    setModalEdit(true);
  }

  const eliminar =()=>{
    removeCatalog(catalogSelected._id, catalogSelected.code);
    setModalEliminar(false);
    setCatalogSelected(null);
  }

  const closeModal = () => {
    setModalEdit(false);
    setCatalogSelected(null);
  }

  // const edit = (catalogEdited: ICatalog) =>{
  //   // var dataNueva=data;
  //   // dataNueva.map((catalog: any) =>{
  //   //   if(catalog.catalogCode === catalogEdited.catalogCode){
  //   //     catalog.description = catalogEdited.description;
  //   //     catalog.name = catalogEdited.name;
  //   //   }
  //   // });
  //   // setData(dataNueva);
  //   editCatalog(catalogEdited);
  //   closeModal();
  // }

  // const add = (catalogNew: ICatalog) =>{
  //   addCatalog(catalogNew);
  //   closeModal();
  // }
  return (
    <>
      <Col>
        <Row>
          <Col md="6">
            <h4>Lista de parámetros agregados</h4>
            <p className="card-category">
              Puedes agregar, editar y eliminar parámetros
            </p>
          </Col>
          <Col md="3">
            <Form.Group className="mb-3">
              <Form.Label>Tipos de Parámetros</Form.Label>
              <Form.Select aria-label="Tipo de Parámetros"
                name="codeCatalog"
                value={codeCatalog}
                onChange={filterList}>
                {Object.keys(catalogs).map((item: any) => <option key={`a-type-o-${item}`} value={item}>{item.charAt(0).toUpperCase() + item.replace('_',' ').slice(1)}</option>)}
              </Form.Select>
            </Form.Group>
          </Col>
          <Col>
          {!matchRole(['consulta','contabilidad']) && 
            <Button className="float-end" variant="outline-primary" onClick={() => selectCatalog({})}>Agregar Parámetro <i className="fas fa-plus"></i></Button>
          }
          </Col>
        </Row>
        <Col>
          <ListGroup>
            {data.map((model, index)=>(
              <ListGroup.Item key={`catalog-${model._id || index}`} eventKey={`catalog-${model._id || index}`}>
                  <div className="d-flex flex-grow-1 align-items-center">
                  { model.acronym &&  `${model.acronym} -`} {model.name}
                  {!matchRole(['consulta','contabilidad']) && 
                   <span className="ms-auto btn btn-sm btn-outline-secondary" title="Editar" onClick={(e)=> {e.stopPropagation(); selectCatalog(model)}}>
                      <i className="fas fa-pencil-alt"></i></span>}
                    {!matchRole(['consulta','contabilidad']) && 
                    <Button className="text-danger" title="Eliminar" variant="outline-light" size="sm" onClick={()=>{ setCatalogSelected(model); setModalEliminar(true);}}><i className="fas fa-trash-alt"></i></Button>
                    }
                  </div>
              </ListGroup.Item>
            ))}
          </ListGroup>
        </Col>
      </Col>
      <Modal show={modalEliminar}>
        <Modal.Body>
          Estás seguro que deseas eliminar el parámetro {catalogSelected && catalogSelected.name}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={()=>eliminar()}>Sí</Button>
          <Button variant="secondary" onClick={()=>setModalEliminar(false)}>No</Button>
        </Modal.Footer>
      </Modal>

      {modalEdit && 
      <CatalogForm show={modalEdit}
        catalog={catalogSelected}
        close={() => closeModal()}/>
      }
    </>
  );
};

export default CatalogList;
