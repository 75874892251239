// import Fuse from 'fuse.js'
import { SupplierContext } from 'context/suppliers/SupplierState';
import { useState, useContext, useEffect } from 'react';
import { Button, Card, Table, Container, Row, Col, Modal, Accordion, Form, ListGroup, InputGroup, FormControl } from "react-bootstrap";
import { formatWithOptions } from 'util';
import SupplierForm from './SupplierForm';
import Loading from '../../views/Loading';
import { useAuth } from 'context/auth/AuthContext';
import { calculateIva } from 'utils/utilities';
import { ProductContext } from 'context/products/ProductState';

const SupplierList = () => {
  const { suppliers, loading, removeSupplier } = useContext(SupplierContext);
  const { productsHash } = useContext(ProductContext);
  const [data, setData] = useState(suppliers);
  const [modalEdit, setModalEdit] = useState(false);
  const [modalDelete, setModalDelete] = useState(false);
  const { matchRole } = useAuth();
  const [supplierSelected, setSupplierSelected] = useState<any>(null);

  useEffect(() => {
    if (productsHash && Object.keys(productsHash).length  && suppliers) {
      setData(suppliers);
    }
  }, [suppliers, productsHash]);

  const filterList = (event: any) => {
		let updatedList = [...suppliers];
		updatedList = updatedList.filter(({ name }) => {
      return name.toLowerCase().search(event.target.value.toLowerCase()) !== -1;
    });
    setData(updatedList);
    // if (!event.target.value) {
    //   setData(updatedList);
    //   return;
    // }
    // const options = {
    //   threshold: 0.2,
    //   ignoreLocation: true,
    //   keys: ['name']// , 'products.product.name']
    // }
    // const fuse = new Fuse(updatedList, options)
    // const result = fuse.search(event.target.value.toLowerCase()).map((result) => result.item);
    // setData(result);
	}

  const selectSupplier= (model: any)=>{
    setSupplierSelected(model);
    setModalEdit(true);
  }

  const deleteSupplier =()=>{
    removeSupplier(supplierSelected._id);
    setModalDelete(false);
    setSupplierSelected(null);
  }
  const closeModal = () => {
    setModalEdit(false);
    setSupplierSelected(null);
  }

  return (
    <>
      <Col>
        <Row>
          <Col md="6">
            <h4>Lista de proveedores agregados</h4>
            <p className="card-category">
              Puedes agregar, editar y eliminar proveedores
            </p>
          </Col>
          <Col md="3">  
            <InputGroup className="mb-3">
              <InputGroup.Text id="search-input" >
                <i className="nc-icon nc-zoom-split"></i>
              </InputGroup.Text>
              <FormControl
                placeholder="Buscar Proveedor"
                aria-label="Burcar"
                aria-describedby="search-input"
                onChange={filterList}
              />
            </InputGroup>
          </Col>
          <Col>
          {!matchRole(['consulta','contabilidad']) && 
            <Button className="float-end" variant="outline-primary" onClick={() => selectSupplier({})}>Agregar Proveedor <i className="fas fa-plus"></i></Button>
          }
          </Col>
        </Row>
        {!!loading && <Loading scale="0.5"></Loading> }
        <Accordion>
          {data.map((model, index)=>(
            <Accordion.Item key={`supplier-${model._id || index}`} eventKey={`supplier-${model._id || index}`}>
              <Accordion.Header className="mt-0">
                <div className="d-flex flex-grow-1 align-items-center pe-4">
                  <b className="text-capitalize">{model.name}</b>
                  {matchRole(['administrador','academico_principal']) && 
                  <span className="ms-auto btn btn-sm btn-outline-secondary" title="Editar" onClick={(e)=> {e.stopPropagation(); selectSupplier(model)}}>
                    <i className="fas fa-pencil-alt"></i></span>}
                </div>
              </Accordion.Header>
              <Accordion.Body>
                <Row>
                  <Col md="6">
                    <b>Productos</b>
                    <ListGroup variant="flush">
                      {model.products &&
                        model.products.map(({ product: p, price, presentation }, index: number) => {
                          if (!p) { return null; }
                         return <ListGroup.Item key={index} className="d-flex justify-content-between"> <span className="text-capitalize">{productsHash[p._id].name}</span> <span> &nbsp; Presentación: {presentation}{productsHash[p._id].unitOfMeasure} | Precio: ₡{price} | Precio IVI: ₡{calculateIva(productsHash[p._id], price, 1, price)} </span>
                         </ListGroup.Item>
                        })
                      }
                    </ListGroup>
                  </Col>
                  <Col md="6">
                    {model.description && 
                      <><b>Descripción</b><p>{model.description}</p></>}
                  </Col>
                </Row>
                <div className="d-flex justify-content-end align-items-center">
                  {/* {model.createdBy && <small className="fw-lighter me-3">Creado por {model.createdBy}</small>} */}
                  {matchRole(['administrador']) && 
                  <Button className="text-danger" title="Eliminar" variant="outline-light" size="sm" onClick={()=>{ setSupplierSelected(model); setModalDelete(true);}}><i className="fas fa-trash-alt"></i></Button>
                  }
                </div>
              </Accordion.Body>
            </Accordion.Item>
          ))}
        </Accordion>
      </Col>
      <Modal show={modalDelete}>
        <Modal.Body>
          Estás seguro que deseas eliminar el proveedor {supplierSelected && supplierSelected.name}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={()=>deleteSupplier()}>Sí</Button>
          <Button variant="secondary" onClick={()=>setModalDelete(false)}>No</Button>
        </Modal.Footer>
      </Modal>
                              

      {modalEdit && 
        <SupplierForm show={modalEdit}
        supplier={supplierSelected}
        close={() => closeModal()}/>
      }
    </>
  );
}

export default SupplierList;
