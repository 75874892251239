
import IRoute from "../interfaces/route";
// import Dashboard from "views/Dashboard.js";

import UserList from "modules/security/UserList";
// import RolList from "modules/security/RolList";

import RecipeList from "modules/academic/RecipeList";
import ActivityList from "modules/academic/ActivityList";

import ProductList from "modules/administrative/ProductList";
import CatalogList from "modules/administrative/CatalogList";
import SupplierList from "modules/administrative/SupplierList";

import PurchaseList from "modules/sales/purchases/PurchaseList";
import PurchaseForm from "modules/sales/purchases/PurchaseForm";
// import PurchaseEdition from "modules/sales/purchases/PurchaseEdition";

// import AccountingReportSelection from "modules/reports/AccountingReportSelection";
// import InternalReportSelection from "modules/reports/InternalReportSelection";

import PreorderList from "modules/sales/preorders/PreorderList";
import PreorderEdition from "modules/sales/preorders/PreorderEdition";
import OrderList from "modules/sales/orders/OrderList";

import ReportList from "modules/reports/ReportList";

// import Icons from "views/Icons";

const routes: IRoute[] = [
  // {
  //   path: "/dashboard",
  //   name: "Dashboard",
  //   icon: "nc-icon nc-chart-pie-35",
  //   component: Dashboard,
  //   layout: "/admin",
  // {
  //   path: "/icons",
  //   name: "Icons",
  //   icon: "nc-icon nc-atom",
  //   component: Icons,
  //   layout: "/admin",
  // },
  {
    path: "/administrativo",
    name: "Administrativo",
    icon: "nc-icon nc-settings-90",
    layout: "/admin",
    //permit: ['administrador'],
    showMenu: true,
    // component: UserProfile,
    children: [
      {
        path: "/catalogos",
        name: "Parámetros",
        showMenu: true,
        component: CatalogList
      },
      {
        path: "/productos",
        name: "Productos",
        showMenu: true,
        component: ProductList
      },
      {
        path: "/proveedores",
        name: "Proveedores",
        showMenu: true,
        component: SupplierList
      }
    ]
  },
  {
    path: "/academico",
    name: "Académico",
    icon: "nc-icon nc-ruler-pencil",
    layout: "/admin",
    showMenu: true,
    // component: UserProfile,
    children: [
      {
        path: "/recetas",
        name: "Recetas",
        showMenu: true,
        component: RecipeList
      },
      {
        path: "/actividades",
        name: "Actividades",
        showMenu: true,
        component: ActivityList
      }
    ]
  },
  {
    path: "/compras",
    name: "Compras",
    icon: "nc-icon nc-cart-simple",
    layout: "/admin",
    showMenu: true,
    // component: UserProfile,
    children: [
      {
        path: "/preorders",
        name: "Preórdenes",
        exact: true,
        showMenu: true,
        component: PreorderList
      },
      {
        path: "/preorders/new",
        name: "Nueva Preorden",
        exact: true,
        showMenu: true,
        permit: ['administrador','academico_principal','academico_secundario'],
        component: PreorderEdition
      },
      {
        path: "/preorders/:id",
        name: "Editar Preorden",
        showMenu: true,
        permit: ['administrador','academico_principal','academico_secundario'],
        component: PreorderEdition
      },
      {
        path: "/orders",
        name: "Órdenes",
        showMenu: true,
        component: OrderList
      },
      {
        path: "/purchases",
        name: "Compras",
        exact: true,
        showMenu: true,
        component: PurchaseList
      },
      {
        path: "/purchases/new/:id",
        name: "Nueva Compra",
        showMenu: false,
        component: PurchaseForm
      },
      {
        path: "/purchases/:id",
        name: "Editar Compra",
        showMenu: false,
        // permit: ['administrador','academico_principal','academico_secundario'],
        component: PurchaseForm
      },
    ]
  },
  {
    path: "/reportes",
    name: "Reportes",
    icon: "nc-icon nc-single-copy-04",
    layout: "/admin",
    showMenu: true,
    children: [
      // {
      //   path: "/reportesInternos",
      //   name: "Gestión Interna",
      //   showMenu: true,
      //   component: InternalReportSelection
      // },
      // {
      //   path: "/reportesContables",
      //   name: "Contables",
      //   showMenu: true,
      //   component: AccountingReportSelection
      // }
      {
        path: "/descargables",
        name: "Descargables",
        showMenu: true,
        component: ReportList
      }
    ]
  },
  {
    path: "/seguridad",
    name: "Seguridad",
    icon: "nc-icon nc-single-02",
    layout: "/admin",
    showMenu: true,
   // permit: ['administrador'],
    children: [
      {
        path: "/usuarios",
        name: "Usuarios",
        showMenu: true,
        component: UserList
      }
    ]
  }
];

// routes.push(...rootRoutes);
// modulesRoutes.forEach(({routes: r}) => {
//   routes.push(...r)
// });

// console.log(routes);
export default routes;




  // {
  //   upgrade: true,
  //   path: "/upgrade",
  //   name: "Upgrade to PRO",
  //   icon: "nc-icon nc-alien-33",
  //   component: Upgrade,
  //   layout: "/admin",
  // },
  // {
  //   path: "/user",
  //   name: "User Profile",
  //   icon: "nc-icon nc-circle-09",
  //   component: UserProfile,
  //   layout: "/admin",
  // },
  // {
  //   path: "/table",
  //   name: "Table List",
  //   icon: "nc-icon nc-notes",
  //   component: TableList,
  //   layout: "/admin",
  // },
  // {
  //   path: "/typography",
  //   name: "Typography",
  //   icon: "nc-icon nc-paper-2",
  //   component: Typography,
  //   layout: "/admin",
  // },
  // {
  //   path: "/icons",
  //   name: "Icons",
  //   icon: "nc-icon nc-atom",
  //   component: Icons,
  //   layout: "/admin",
  // },
  // {
  //   path: "/maps",
  //   name: "Maps",
  //   icon: "nc-icon nc-pin-3",
  //   component: Maps,
  //   layout: "/admin",
  // },
  // {
  //   path: "/notifications",
  //   name: "Notifications",
  //   icon: "nc-icon nc-bell-55",
  //   component: Notifications,
  //   layout: "/admin",
  // },
