// import Landing from "views/Landing";
import IRoute from "../interfaces/route";
import Login from "../views/Login";

export const unauthenticatedRoutes: IRoute[] = [
  {
    path: '/login',
    name: 'Login Page',
    component: Login,
    exact: true
  },
  // {
  //   path: '',
  //   name: 'Landing',
  //   component: Landing,
  //   exact: true
  // },
]
