const AuthReducer = (state: any, action: any) => {
  switch (action.type) {
    case "LOGIN_START":
      return {
        user: null,
        isFetching: true,
        error: false,
      };
    case "LOGIN_SUCCESS":
      return {
        user: action.payload,
        isFetching: false,
        error: false,
      };
    case "LOGIN_FAILURE":
      return {
        user: null,
        isFetching: false,
        error: action.error,
      };
    case "LOGOUT_SUCCESS":
      return {
        user: null,
        isFetching: false,
        error: action.error,
      };
    // case "FOLLOW":
    //   return {
    //     ...state,
    //     user: {
    //       ...state.user,
    //       followings: [...state.user.followings, action.payload],
    //     },
    //   };
    // case "UNFOLLOW":
    //   return {
    //     ...state,
    //     user: {
    //       ...state.user,
    //       followings: state.user.followings.filter(
    //         (following) => following !== action.payload
    //       ),
    //     },
    //   };
    default:
      return state;
  }
};

export default AuthReducer;