import React, { useState } from 'react'
import { useAuth } from '../context/auth/AuthContext';
import Button from 'react-bootstrap/Button';
import { Container, Form, Card, Badge, Toast } from 'react-bootstrap';
import "../assets/styles/views/login.scss";

const Login = () => {
  const { login } = useAuth();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [showForgotMessage, setForgotMessage] = useState(false);
  const [error, setError] = useState('');

  async function handleLogin(e: any) {
    e.preventDefault();
    try {
      setError('');
      const res = await login(username, password);
    } catch (err) {
      console.log(err);
      setError(err.response.data);
    }
  }
   return (
    <Container fluid>
      <div className="login-page">
        <Card className="shadow-lg">
          <Card.Header>
            <h4>Bienvenid@ a</h4>
            <h2>SMART KITCHEN</h2>
            <small className="float-end">por Academia Guaitil</small>
          </Card.Header>
          <Card.Body>
            <Form onSubmit={handleLogin}>
              <Form.Group className="mb-3" controlId="formBasicUsername">
                <Form.Label>Usuario</Form.Label>
                <Form.Control isInvalid={!!error} type="text" placeholder="Ingrese usuario" onChange={(e) => setUsername(e.target.value)}/>
                {/* <Form.Text className="text-muted">
                  We'll never share your email with anyone else.
                </Form.Text> */}
              </Form.Group>

              <Form.Group className="mb-3" controlId="formBasicPassword">
                <Form.Label>Contraseña</Form.Label>
                <Form.Control isInvalid={!!error} type="password" placeholder="Contraseña" onChange={(e) => setPassword(e.target.value)}/>
                {error &&
                  <Form.Control.Feedback type="invalid">
                      Usuario o Contraseña incorrecta!
                  </Form.Control.Feedback>
                }
              </Form.Group>
              {/* <Form.Group className="mb-3" controlId="formBasicCheckbox">
                <Form.Check type="checkbox" label="Check me out" />
              </Form.Group> */}
              <Form.Group className="text-center mt-4">
                <Button className="" variant="outline-primary" type="submit">
                  Ingresar
                </Button>
              </Form.Group>
              <Button onClick={() => { setForgotMessage(true) }} variant="link" className="mt-4 mb-2">
                 ¿Olvidaste la contraseña?
              </Button>
              <Toast show={showForgotMessage} onClose={() => { setForgotMessage(false) }}>
                <Toast.Header>
                  Debes contactar al administrador para que te envíe una contraseña temporal!
                </Toast.Header>
                <Toast.Body>email: bzuniga@academiaguaitil.org</Toast.Body>
              </Toast>
            </Form>
          </Card.Body>
        </Card>
      </div>
    </Container>
   )
}

export default Login;
