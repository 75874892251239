import { Typeahead } from 'react-bootstrap-typeahead';
import { ProductContext } from 'context/products/ProductState';
import { useContext, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { IProduct } from 'interfaces';

const CommodityRow = ({ commodity, onChange, onRemove, onAddProduct }: any) => {
  const { products } = useContext(ProductContext);
  const [ editingCommodity, setEditingCommodity ] = useState<any>(commodity);
  const [ activeEdition, setActiveEdition ] = useState(!!commodity.product ? false : true);
  const firstUpdate = useRef(true);
  let changed = false;
  let selected = null

  useEffect(() => { 
    setEditingCommodity(commodity || {});
  }, [commodity]);

  useLayoutEffect(() => { 
    if(firstUpdate.current) { firstUpdate.current = false; return; }
    onChange(editingCommodity);
  }, [editingCommodity]);

  // const changeProduct = (i: any) => {
  //   console.log(i);
  // }

  const handleProductChange = (selected: any[]) => {
    const [option] = selected;
    if (option && option.customOption) {
      onAddProduct(option.label);
      return;
    }
    changed = true;
    setEditingCommodity({ ...editingCommodity, product: selected.length ? option : '' });
    // onChange(editingCommodity);
  }

  const handleChange = (e:any) => {
    const {name, value}=e.target;
    changed = true;
    setEditingCommodity((prevState: any)=>({
      ...prevState,
      [name]: value
    }));
  }

  if (products && !products.length) {
    return null;
  } else if (commodity.product) {
    selected = products.find(p => commodity.product._id == p._id) || {};
  }

  return <tr>
    { (!activeEdition && !!editingCommodity.product) &&
      <>
        <td>
          <span>{`${editingCommodity.product.name}${editingCommodity.product.unitOfMeasure ? ' en ' + editingCommodity.product.unitOfMeasure : ''}`}</span>
        </td>
        <td className="col-2">
          {editingCommodity.presentation}
        </td>
        <td className="col-2">
          {editingCommodity.price}
        </td>
        <td>
          <Button className="text-primary" variant="outline-light" title="Editar" onClick={(e)=> {e.stopPropagation(); setActiveEdition(true)}}><i className="fas fa-pencil-alt"></i></Button>
        </td>
      </>
    }
    { (!!activeEdition || !editingCommodity.product) &&
      <>
        <td>
          <Typeahead
            allowNew
            clearButton
            id={`commodity-${commodity._id}`}
            labelKey={(o) => `${o.name}${o.unitOfMeasure ? ' en ' + o.unitOfMeasure : ''}`}
            options={products}
            placeholder="Elige un producto..."
            newSelectionPrefix="Presiona para registrar: "
            onChange={handleProductChange}
            selected={editingCommodity.product ? [editingCommodity.product] : []}
            defaultSelected={[selected]}
            maxHeight={200}
            positionFixed={true}
          />
        </td>
        <td className="col-2">
          <Form.Control
            name="presentation"
            type="number"
            min="0"
            max="999999999"
            value={editingCommodity.presentation || ''}
            onChange={handleChange}
          ></Form.Control>
        </td>
        <td className="col-2">
          <Form.Control
            name="price"
            type="number"
            min="0"
            max="999999999"
            value={editingCommodity.price || ''}
            onChange={handleChange}
          ></Form.Control>
        </td>
        <td>
          <Button className="text-info" title="Cancel" variant="outline-light" size="sm" onClick={(e)=> {e.stopPropagation(); setActiveEdition(false)}}><i className="fas fa-window-close"></i></Button>
          <Button className="text-danger" title="Eliminar" variant="outline-light" size="sm" onClick={onRemove}><i className="fas fa-trash-alt"></i></Button>
        </td>
      </>
    }
  </tr>;
}

export default CommodityRow;