import { CatalogContext } from 'context/catalogs/CatalogState';
import { Formik } from 'formik';
import { ICatalog } from 'interfaces';
import { useState, useEffect, useContext } from 'react';
import { Button, Card, Table, Container, Row, Col, Modal, Accordion, Form, ListGroup } from "react-bootstrap";
import * as Yup from 'yup';

export const catalogCodes = [
  'programas',
  'modulos',
  'tiempo_menu', 
  'impuestos'
];

const schema = Yup.object().shape({
  name: Yup.string().required('Requerido'),
  code: Yup.string().required('Requerido'),
});

const CatalogForm = (props: { show: boolean, catalog: ICatalog, close: Function }) => {
  const { addCatalog, editCatalog } = useContext(CatalogContext);
  const { show, catalog, close } = props;
  const [editingCatalog, setEditingSelected] = useState<any>({});

  useEffect(() => { 
    setEditingSelected(catalog || {});
  }, [catalog]);

  const checkCatalog= (values: any) => {
    return { ...values, _id: catalog._id }
  }
  
  const saveCatalog = (values: any) => {
    const savingCatalog = checkCatalog(values);
    savingCatalog._id ? editCatalog(savingCatalog) : addCatalog(savingCatalog);
    close();
  }

  return (
    <Modal show={show} onHide={close} entered>
      <Modal.Header closeButton>
        <Modal.Title>{editingCatalog.catalogCode ? 'Editar' : 'Nuevo'} Parámetro</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Formik
          validateOnChange={false}
          validateOnBlur={false}
          validationSchema={schema}
          onSubmit={saveCatalog}
          initialValues={{
            name: catalog.name,
            code: catalog.code,
            acronym: catalog.acronym,
          }}>
          {({
            handleSubmit,
            handleChange,
            handleBlur,
            values,
            touched,
            isValid,
            errors,
          }) => (
          <Form id="catalog-form" noValidate onSubmit={(e)=> { e.preventDefault(); handleSubmit(); }}>
            <Row className="pb-3 justify-content-md-center">
              <Col md="8">
                <Form.Group className="mb-3">
                  <label>Nombre</label>
                  <Form.Control
                    name="name"
                    value={values.name || ''}
                    type="text"
                    placeholder="Nombre"
                    isInvalid={!!errors.name}
                    onChange={handleChange}/>
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Categoría</Form.Label>
                  <Form.Select aria-label="Categoría"
                    name="code"
                    value={values.code}
                    isInvalid={!!errors.code}
                    onChange={handleChange}>
                    <option></option>
                    {catalogCodes.map(item => <option key={`unity-o-${item}`} value={item}>{item.charAt(0).toUpperCase() + item.slice(1)}</option>)}
                  </Form.Select>
                </Form.Group>
                <Form.Group className="mb-3">
                  <label>Siglas / Valor</label>
                  <Form.Control
                    name="acronym"
                    value={values.acronym || ''}
                    type="text"
                    placeholder="Siglas / Valor"
                    isInvalid={!!errors.acronym}
                    onChange={handleChange}/>
                </Form.Group>
              </Col>
            </Row>
          </Form>
        )}
        </Formik>
      </Modal.Body>
      <Modal.Footer>
      <Button variant="danger" onClick={()=>close()}>Cancelar</Button>
        <Button variant="primary" type="submit" form="catalog-form">Guardar</Button>
      </Modal.Footer>
    </Modal>
  )
}

export default CatalogForm;