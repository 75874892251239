import { useState, useRef, useEffect } from "react";
import { Route, Redirect, RouteComponentProps, Switch } from "react-router-dom";

import AdminNavbar from "layouts/AdminNavbar";
import Footer from "layouts/Footer";
import Sidebar from "layouts/Sidebar";

import routes from "config/authenticated.routes";

import IRoute from "interfaces/route";
import NotFound from "views/NotFound";
import { useAuth } from "context/auth/AuthContext";

function Admin() {
  const { logout, user } = useAuth();
  // const location = useLocation();
  const mainPanel = useRef<HTMLHeadingElement>(null);
  const getRoutes = (routes: IRoute[]) => {
    return routes.map((prop: any, key) => {
      if (prop.layout === "/admin") {
        return (
          <Route
            path={prop.layout + prop.path}
            exact={prop.exact}
            render={(props) => (
              <>
                <AdminNavbar {...prop} layout={prop.layout + prop.path}/>
                <div className="content">
                  <Switch>
                    {prop.children && prop.children.map((child: any, index: number) => (
                      <Route
                        key={index}
                        path={prop.layout + prop.path + child.path} 
                        exact={child.exact}
                        render={(props: RouteComponentProps) => (
                          <child.component
                            name={child.name}
                            {...props}
                            {...child.props}
                          />
                        )}/>
                    ))}
                    {prop.component && <Route
                      path={prop.layout + prop.path}
                      exact={prop.exact}
                      render={(props) => <prop.component {...props} />}/>}
                    {prop.children && !prop.component &&
                      <Redirect from={prop.layout + prop.path} to={prop.layout + prop.path + prop.children[0].path} />}
                    <Route component={NotFound}/>
                  </Switch>
                </div>
                <Footer />
              </>
            )}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    if (document.scrollingElement) { document.scrollingElement.scrollTop = 0; }
    if (mainPanel && mainPanel.current) { mainPanel.current.scrollTop = 0; }

    if (window.innerWidth < 993 &&
      document.documentElement.className.indexOf("nav-open") !== -1) {
      document.documentElement.classList.toggle("nav-open");
      const element = document.getElementById("bodyClick");
      if (element && element.parentNode) { element.parentNode.removeChild(element); }
    }
  }, []);


  const roleRoutes = routes.filter(r => !r.permit || r.permit?.includes(user.role));
  return (
    <>
      <div className="wrapper">
        <Sidebar routes={roleRoutes} />
        <div className="main-panel" ref={mainPanel}>
          <Switch>
            <Route path="/admin" exact>
              <Redirect to={'/admin' + roleRoutes[0].path} />
            </Route>
            { getRoutes(roleRoutes) }
            <Redirect path="**" to={'/admin' + routes[0].path} />
          </Switch>
        </div>
      </div>
    </>
  );
}

export default Admin;
