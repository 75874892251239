import { Typeahead } from 'react-bootstrap-typeahead';
import { ProductContext } from 'context/products/ProductState';
import { useContext, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { IProduct } from 'interfaces';
import { SupplierContext } from 'context/suppliers/SupplierState';
import { calculateIva } from 'utils/utilities';

const PurchaseRow = ({ row, products, exclude, onChange, onRemove, onAddProduct }: any) => {
  const { productsHash } = useContext(ProductContext);
  const [ editingRow, setEditingRow ] = useState<any>(row);

  const firstUpdate = useRef(true);
  const options = useRef<any[]>([]);
  // const products = useRef(supplier.products);
  let changed = false;
  let selected: any = null

  useEffect(() => { 
    setEditingRow(row || {});
  }, [row]);

  useLayoutEffect(() => { 
    if(firstUpdate.current) { firstUpdate.current = false; return; }
    onChange(editingRow);
  }, [editingRow]);

  // const changeProduct = (i: any) => {
  //   console.log(i);
  // }

  const handleProductChange = (selected: any[]) => {
    const [option] = selected;
    if (!option) {
      return;
    }
    if (option.customOption) {
      onAddProduct(option.label);
      return;
    }

    const totalPrice = toFixedIfNecessary(option.price * editingRow.quantity)
    const newRow = option
      ? { 
        product: option.product,
        totalPrice,
        priceIva: toFixedIfNecessary(calculateIva(option, option.price, editingRow.quantity, totalPrice)),
        unitPrice: option.price,
        presentation: option.presentation
      }
      : { unitPrice: 0, totalPrice: 0, product: '' };


    changed = true;

    setEditingRow({ ...editingRow, ...newRow });
    // onChange(editingRow);
  }

  const toFixedIfNecessary = (value) => {
    return +parseFloat(value).toFixed(2);
  }

  const handleChange = (e:any) => {
    const {name, value }=e.target;
    changed = true;
    const newRow = { [name]: value, totalPrice: 0, priceIva: 0 };
    if (name === 'quantity') {
      newRow.totalPrice = toFixedIfNecessary(Number(value) * Number(editingRow.unitPrice));
      newRow.priceIva = toFixedIfNecessary(calculateIva(productsHash[editingRow.product._id], editingRow.unitPrice, Number(value), newRow.totalPrice));
    }
    if (name === 'unitPrice') {
      newRow.totalPrice = toFixedIfNecessary(Number(value) * Number(editingRow.quantity));
      newRow.priceIva = toFixedIfNecessary(calculateIva(productsHash[editingRow.product._id], Number(value), editingRow.quantity, newRow.totalPrice));
    }
    setEditingRow((prevState: any)=>({
      ...prevState,
      ...newRow
    }));
  }

  if (products && !products.length) {
    return null;
  } else {
    options.current = [];
    products.forEach((p: any) => {
      if (row.product && row.product._id == p.product._id) {
        options.current.push({ ...p });
        selected = { ...p };
      } else if (!exclude.includes(p.product._id)) {
        options.current.push({ ...p });
      }
    });
  }

  return <tr>
    <td>
      <Typeahead
        clearButton
        id={`row-${row._id}`}
        labelKey={(o) => `${o.product.name} ${o.product.unitOfMeasure ? `en ${o.presentation} ` + o.product.unitOfMeasure : ''}`}
        options={options.current}
        placeholder="Elige un producto..."
        newSelectionPrefix="Presiona para registrar: "
        onChange={handleProductChange}
        selected={editingRow && editingRow.product ? [{ ...editingRow } ] : []}
        defaultSelected={[selected]}
      />
    </td>
    <td className="text-center">
      {row.orderQuantity || row.quantity || 0}
    </td>
    <td>
      <Form.Control
        name="quantity"
        type="number"
        min="0"
        max="999999999"
        value={editingRow.quantity || ''}
        onChange={handleChange}
      ></Form.Control>
    </td>
    <td className="text-center">
      ₡{row.orderUnitPrice || row.unitPrice || 0}
    </td>
    <td>
      <Form.Control
        name="unitPrice"
        type="number"
        min="0"
        max="999999999"
        value={editingRow.unitPrice || ''}
        onChange={handleChange}
      ></Form.Control>
    </td>
    <td>
      ₡{editingRow.totalPrice || 0}
    </td>
    <td>
      ₡{editingRow.priceIva || 0}
    </td>
    <td>
      <Button className="text-danger" title="Eliminar" variant="outline-light" size="sm" onClick={onRemove}><i className="fas fa-trash-alt"></i></Button>
    </td>
  </tr>;
}

export default PurchaseRow;