import { Formik } from 'formik';
import { IOrder } from 'interfaces';
import { Button, Row, Col, Modal, Form } from "react-bootstrap";
import jsPDF from "jspdf";
import 'jspdf-autotable';
import calibryTtf from "utils/calibry";
import logo from 'assets/img/orange-logo-sm.png';
import { useEffect, useState } from 'react';
import * as Yup from 'yup';

const schema = Yup.object().shape({
});


const OrderPrintForm = (props: { show: boolean, order: IOrder, close: Function }) => {
  const { show, order, close } = props;
  
  const saveOrderDetails = (values: any) => {
    const details = values;
    const head1 = [['Proveedor','Dirección de entrega']];
    const data1 = [[`Nombre: ${order.supplier.name || ''}\nDirección: ${order.supplier.address || ''}\nTeléfonos: ${order.supplier.phoneNumber || ''}\nContacto: ${order.supplier.contactName || ''}\nCorreo electrónico: ${order.supplier.email || ''}`,details.delivery]]
    const head2 = [['Términos de pago', 'Solicitado por:', 'Departamento:','Fecha de entrega y hora:']];
    const data2 = [[details.paymentTerms, details.requestedBy, details.department, new Date().toLocaleDateString() + ' ' + new Date().toLocaleTimeString()]];
    const head3 = [['Producto', 'Presentación', 'U/M', 'Cantidad', 'Precio Unitario', 'Total', 'Total + Iva']];
    const data3 = order.products.map(({ product: p, quantity, unitPrice, totalPrice, priceIva, presentation }: any, index: number) => [p.name, presentation, p.unitOfMeasure, quantity, `₡${unitPrice}`, `₡${totalPrice}`, `₡${priceIva}`]);
    const head4 = [['Instrucciones:','Autorizado por:']];
    const data4 = [[details.instructions, details.approvedBy]];
    const foot = [[],['', '', '', 'TOTAL:', `₡${order.total}`, `₡${order.totalIva}`]];

    var doc = new jsPDF();
    doc.addFileToVFS('calibrib.ttf', calibryTtf);
    doc.addFont('calibrib.ttf', 'custom', 'normal');
    doc.setFont('helvetica', 'normal', 'normal');
    doc.setFontSize(10);
    doc.addImage(logo, 'PNG', 10, 10, 35, 9);
    doc.text('Ced. Jurídica 3 002 045651-09\nSan José, Montes de Oca, Sabanilla\nDiagonal al plantel de buses de la UCR\nTeléfonos: (506) 2283-6915/2283-6916', 10, 30);
    doc.text(`ORDEN DE COMPRA\nFecha de solicitud:\nNo: ${order.orderCode}`, 120, 10);
    
    doc.autoTable({
      head: head1,
      body: data1,
      theme: 'striped',
      startY: 55,
      margin: { left: 10, right: 10 },
      headStyles: { fillColor: '#F27410' },
      styles: { font: "custom" },
    })
    
    doc.autoTable({
      head: head2,
      body: data2,
      theme: 'striped',
      startY: 55,
      margin: { left: 10, right: 10 },
      headStyles: { fillColor: '#F27410' },
      bodyStyles: { minCellHeight: 10},
      styles: { font: "custom" },
      startY: doc.lastAutoTable.finalY 
    })

    doc.autoTable({
      head: head3,
      foot,
      body: data3,
      theme: 'striped',
      startY: 55,
      margin: { left: 10, right: 10 },
      headStyles: { fillColor: '#F27410' },
      footStyles: { fillColor: '#fef1e7', fontStyle: 'bold', textColor: '#000' },
      styles: { font: "custom" },
      startY: doc.lastAutoTable.finalY + 10      
    })

    doc.autoTable({
      head: head4,
      body: data4,
      theme: 'striped',
      startY: 55,
      margin: { left: 10, right: 10 },
      headStyles: { fillColor: '#F27410' },
      bodyStyles: { minCellHeight: 20},
      styles: { font: "custom" },
      startY: doc.lastAutoTable.finalY + 10
    })
    
   // doc.autoPrint();  // <<--------------------- !!
    doc.output('dataurlnewwindow', { filename: `OrdenCompra-${order.orderCode}.pdf` });
    // doc.save(`OrdenCompra-${order.orderCode}.pdf`) 
    close();
  }

  return (
    <Modal show={show} onHide={close} entered>
      <Modal.Header closeButton>
        <Modal.Title>Agregar Detalle de Orden</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Formik
          validateOnChange={false}
          validateOnBlur={false}
          validationSchema={schema}
          onSubmit={saveOrderDetails}
          initialValues={{
            delivery: 'San José, Montes de Oca, Sabanilla, Diagonal al plantel de buses de la UCR',
            paymentTerms: '',
            requestedBy: '',
            department: '',
            instructions: '',
            approvedBy: ''
          }}>
          {({
            handleSubmit,
            handleChange,
            handleBlur,
            values
          }) => (
          <Form id="order-print-form" noValidate onSubmit={(e)=> { e.preventDefault(); handleSubmit(); }}>
            <Row className="pb-3 justify-content-md-center">
              <Col md="8">
                <Form.Group className="mb-3">
                  <label>Dirección de Entrega</label>
                  <Form.Control
                    name="delivery"
                    value={values.delivery || ''}
                    rows={2}
                    onChange={handleChange}
                    defaultValue="San José, Montes de Oca, Sabanilla, Diagonal al plantel de buses de la UCR"
                    as="textarea"/>         
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Término de pago</Form.Label>
                  <Form.Control
                    name="paymentTerms"
                    value={values.paymentTerms}
                    onChange={handleChange}/>
                </Form.Group>
                <Form.Group className="mb-3">
                  <label>Solicitado por</label>
                  <Form.Control
                    name="requestedBy"
                    value={values.requestedBy || ''}
                    type="text"
                    onChange={handleChange}/>
                </Form.Group>
                <Form.Group className="mb-3">
                  <label>Departamento</label>
                  <Form.Control
                    name="department"
                    value={values.department || ''}
                    type="text"
                    onChange={handleChange}/>
                </Form.Group>
                <Form.Group className="mb-3">
                  <label>Instrucciones</label>
                  <Form.Control
                    name="instructions"
                    value={values.instructions || ''}
                    rows={2}
                    onChange={handleChange}
                    as="textarea"/> 
                </Form.Group>
                <Form.Group className="mb-3">
                  <label>Autorizado por</label>
                  <Form.Control
                    name="approvedBy"
                    value={values.approvedBy || ''}
                    type="text"
                    onChange={handleChange}/>
                </Form.Group>
              </Col>
            </Row>
          </Form>
        )}
        </Formik>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" type="submit" form="order-print-form">Imprimir</Button>
        <Button variant="danger" onClick={()=>close()}>Cancelar</Button>
      </Modal.Footer>
    </Modal>
  )
}

export default OrderPrintForm;