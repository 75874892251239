import React from 'react';
import ReactDOM from 'react-dom';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import "@fortawesome/fontawesome-free/css/all.min.css";
import "./assets/styles/index.scss";
import App from './App';
import { AuthProvider } from "./context/auth/AuthContext"
import { BrowserRouter } from 'react-router-dom';
import { ErrorHandler } from 'context/ErrorProvider';

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <AuthProvider>
        <ErrorHandler>
          <App />
        </ErrorHandler>
      </AuthProvider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);