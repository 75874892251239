import React from "react";
import { Button } from "react-bootstrap";
import FileSaver from "file-saver";
import XLSX from "xlsx";
import * as XLSXStyle from "sheetjs-style";


// var url = process.env.PUBLIC_URL + '/template.xlsx';
// var req = new XMLHttpRequest();
// req.open("GET", url, true);
// req.responseType = "arraybuffer";
// let workbook: any;
// req.onload = function(e) {
//   workbook = XLSX.read(req.response, {cellStyles:true});
// };
// req.send();

const ExportExcel = ({ id, columns, csvDatas, fileName, wscols, buttonText }) => {

  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

  const tableToCSV = (fileName: string, wscols = []) => {
    var wb = XLSX.utils.book_new();
    /* convert table "table1" to worksheet named "Sheet1" */
    var ws = XLSX.utils.table_to_sheet(document.getElementById(id), { origin: 3 });
    ws.A1 = {
      v: "GUAITIL",
      s: {
        font: { name: 'calibry', sz: 24, bold: true, color: { rgb: "F27410" } },
        alignment: { horizontal: 'center', vertical: 'bottom' }
      }
    }
    ws.A2 = {
      v: "ACADEMIA",
      s: {
        font: { name: 'calibry', sz: 8, bold: true, color: { rgb: "999999" } },
        alignment: { horizontal: 'center', vertical: 'top' }
      }
    }

    const letters:any = {};
    Object.keys(ws).forEach((l: string) => {
      const letterMatch = l?.match(/\D+/),
        numMatch = l?.match(/\d+/),
        letter = letterMatch && letterMatch[0],
        num =  numMatch && numMatch[0];

      if (letter && num == '4') {
        if (!letters[letter]) {
          letters[letter] = { wch: ws[l].v.length };
        }
        ws[l].s = {
          fill: { //patternType: "solid", // none / solid
          fgColor: {rgb: "F27410"},
          bgColor: {rgb: "F27410"} }
        }
      }
      if (letter && num && !ws[letter+4]) {
        delete ws[l];
      } else if (letter && ws[l].v) {
        letters[letter] ={ wch: Math.min(Math.max(letters[letter].wch, ws[l].v.length), 40) };
      }
    });

    ws["!cols"] = wscols.length ? wscols : Object.values(letters);

    XLSX.utils.book_append_sheet(wb, ws, fileName); 

    

    const excelBuffer = XLSXStyle.write(wb, { bookType: "xlsx", type: "array", bookSST: true });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  }

  // const exportToCSV = (columns, csvData, fileName, wscols = []) => {
  //   const heading: any = {};
  //   const values: any[] = [];
  //   columns.forEach((c: any) => {
  //     heading[c.key] = c.label
  //     values.push(c.key);
  //   })
  //   // const data = csvData.map();
  
  //   const ws = XLSXStyle.utils.json_to_sheet([heading], {
  //     header: values,
  //     skipHeader: true,
  //     origin: 3 //ok
  //   });
    
  //   const ref = ws['!ref'];
  //   XLSXStyle.utils.sheet_add_json(ws, csvData, {
  //     header: values,
  //     skipHeader: true,
  //     origin: 5 //ok
  //   });
  //   // ws['!ref'] = ref?.slice(0, ref.length-1) + (csvData.length + 2)
  //   ws.A1 = {
  //       v: "GUAITIL",
  //       s: {
  //         font: {
  //           name: 'calibry',
  //           sz: 24,
  //           bold: true,
  //           color: {
  //             rgb: "F27410"
  //           }
  //         },
  //         alignment: {
  //           horizontal: 'center',
  //           vertical: 'bottom'
  //         }
  //       }
  //   }
  //   ws.A2 = {
  //     v: "ACADEMIA",
  //     s: {
  //       font: {
  //         name: 'calibry',
  //         sz: 8,
  //         bold: true,
  //         color: {
  //           rgb: "999999"
  //         }
  //       },
  //       alignment: {
  //         horizontal: 'center',
  //         vertical: 'top'
  //       }
  //     }
  //   }
  //   const letters:any = {};
  //   Object.keys(ws).forEach((l: string) => {
  //     const letterMatch = l?.match(/\D+/),
  //       numMatch = l?.match(/\d+/),
  //       letter = letterMatch && letterMatch[0],
  //       num =  numMatch && numMatch[0];

  //     if (letter && num == '4') {
  //       if (!letters[letter]) {
  //         letters[letter] = { wch: ws[l].v.length };
  //       }
  //       ws[l].s = {
  //         fill: { //patternType: "solid", // none / solid
  //         fgColor: {rgb: "F27410"},
  //         bgColor: {rgb: "F27410"} }
  //       }
  //     }
  //     if (letter && num && !ws[letter+4]) {
  //       delete ws[l];
  //     } else if (letter && ws[l].v) {
  //       letters[letter] ={ wch: Math.min(Math.max(letters[letter].wch, ws[l].v.length), 40) };
  //     }
  //   });

  //   ws["!cols"] = wscols.length ? wscols : Object.values(letters);// Object.values(heading).map((c: any) => ({ wch: c.length + 10 }));


  //   const wb = { Sheets: { data: ws }, SheetNames: ["data"] }; 
  //   const excelBuffer = XLSXStyle.write(wb, { bookType: "xlsx", type: "array", bookSST: true });
  //   const data = new Blob([excelBuffer], { type: fileType });
  //   FileSaver.saveAs(data, fileName + fileExtension);
  // };

  return (
    <>
      <Button
        variant="warning"
        onClick={e => tableToCSV(fileName, wscols)}
      >
        { buttonText || 'Descargar' }
      </Button>
      <table id={id} style={{ display: 'none' }}>
        <thead>
          <tr>
            {
              columns.map((c: any, i: number) => (
                <th key={'header-' + id + i} >{c.label}</th>
              ))
            }
          </tr>
        </thead>
        <tbody>
          {
            csvDatas.map((csvData: any, i: number) => (
              <React.Fragment key={'cvs-data-' + id + i}>
                {(!!csvData.details || !! csvData.title) && 
                  <tr><td colSpan={columns.length}></td></tr>
                }
                {!!csvData.title &&
                  <tr><td colSpan={columns.length}>{csvData.title.toUpperCase()}</td></tr>
                }
                {!!csvData.details && 
                  csvData.details.map((d: any) => (
                    <tr><td colSpan={columns.length}>{d}</td></tr>
                  ))
                }
                {(!!csvData.details || !! csvData.title) && 
                  <tr><td colSpan={columns.length}></td></tr>
                }
                {
                  csvData.rows.map((r: any, i: number) => (
                    <tr key={'row-' + id + r._id + i}>
                      {
                        columns.map((c: any, i: number) => (
                          <td key={'cell-' + id + r._id + i} >{r[c.key]}</td>
                        ))
                      }
                    </tr>
                  ))
                }
                {!!csvData.total &&
                  <>
                    <tr><td colSpan={columns.length}></td></tr>
                    <tr>
                      <td colSpan={columns.length - 1  - (!!csvData.totalIva ? 1 : 0)}>Total:</td>
                      <td>{csvData.total}</td>
                      {!!csvData.totalIva &&
                        <td>{csvData.totalIva}</td>
                      }
                    </tr>
                  </>
                }
                {!!csvData.title && 
                  <>
                    <tr><td colSpan={columns.length}></td></tr>
                    <tr><td colSpan={columns.length}>
                      {Array(columns.length * 20).fill(' - ').map(c => c)}
                    </td></tr>
                  </>
                }
              </React.Fragment>
            ))
          }
        </tbody>
      </table>
    </>
  );
};

export default ExportExcel;
