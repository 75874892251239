
import { RecipeContext } from 'context/recipes/RecipeState';
import { IRecipe } from 'interfaces';
import { useState, useContext, useRef } from 'react';
import { Button, Table, Row, Col, Modal, Form } from "react-bootstrap";
import { Formik } from 'formik';
import IngredientRow from './IngredientRow';
import * as Yup from 'yup';
import ProductForm from 'modules/administrative/ProductForm';

const schema = Yup.object().shape({
  name: Yup.string().min(2, '¡Muy corto!').max(50, '¡Muy Largo!').required('Requerido'),
  portion: Yup.number().required('Requerido'),
  preparation: Yup.string().min(2, '¡Muy corto!').required('Requerido')
});

const RecipeForm = (props: { show: boolean, recipe: IRecipe, close: Function  }) => {
  const { addRecipe, editRecipe } = useContext(RecipeContext);
  const { show, recipe, close } = props;
  const [ ingredientRows, setingredientRows ] = useState<any>(recipe && recipe.ingredients ?  recipe.ingredients : [{}]);
  const [ ingredients, setIngredients ] = useState<any>([...ingredientRows]);
  const [ ingredientsError, setIngredientsError ] = useState<any>('');
  const [productAdd, setProductAdd] = useState<string>('');

  const table = useRef(null);

  const setIngredientByIndex = (index: number, ingredient: any) => {
    setIngredientsError('');
    const foundProduct = ingredients.findIndex((p: any, i: any) => i !== index && p?.product?._id === ingredient.product?._id);
    if (foundProduct !== -1) {
      if (!ingredient.quantity || !ingredient.product) { return; }
      const result = window.confirm(`Producto ya existe! ¿Desea sumar la cantidad ${ingredient.quantity} al ingrediente?`);
      if (result) {
        ingredient.quantity = parseFloat(ingredient.quantity) + parseFloat(ingredients[foundProduct].quantity);
        setIngredients(Object.assign([...ingredients], {[foundProduct]: ingredient }));
        setingredientRows(Object.assign([...ingredientRows], { [index]: {}, [foundProduct]: ingredient }));
      } else {
        setingredientRows(Object.assign([...ingredientRows], {[index]: {} }));
      }
      return;
    }
    setIngredients(Object.assign([...ingredients], {[index]: ingredient }));
  }

  const addIngredient = () => {
    setTimeout(() => {
      table.current.scrollTo(0, table.current.scrollHeight);
    });
    if (ingredientRows.length > 50) { return; }
    const last = ingredients[ingredients.length - 1];
    if (last && !last.product) { return; }
    setingredientRows([...ingredientRows, { }]);
    setIngredients([...ingredients, { }]);
  }

  const removeIngredient = (index: number) => {
      if (ingredientRows.length == 1) {
        setingredientRows([{}]);
        setIngredients([{}]);
      } else {
        setingredientRows([...removeFromArray(ingredientRows, index)]);
        setIngredients([...removeFromArray(ingredients, index)]);
      }
  }

  const removeFromArray = (arr: any[], indexToRemove: number) => {
    return [...arr.slice(0, indexToRemove), ...arr.slice(indexToRemove + 1)]
  }

  const checkRecipe = (values: any) => {
    return {...values,
      _id: recipe._id,
      variation: !!(values.variation && values.variation[0] == 'on'),
      ingredients: ingredients.filter((i: any) => !!i.product).map((i: any) => ({ product: i.product._id || i.product._id, quantity: i.quantity || 0 }))
    }
  }
  
  const saveRecipe = (values: any) => {
    const savingRecipe = checkRecipe(values);
    if (!savingRecipe.ingredients.length) {
      setIngredientsError('Debes agregar ingredientes');
    } else {
      savingRecipe._id ? editRecipe(savingRecipe) : addRecipe(savingRecipe);
      close();
    }
  }

  return (
    <>
      <Modal show={show} onHide={close} size="xl" centered>
        <Modal.Header closeButton>
          <Modal.Title>{recipe._id ? 'Edición' : 'Nueva'} Receta</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Formik
          validateOnChange={false}
          validateOnBlur={false}
          validationSchema={schema}
          onSubmit={saveRecipe}
          initialValues={{
            name: recipe.name,
            portion: recipe.portion,
            variation: recipe.variation,
            preparation: recipe.preparation,
            description: recipe.description,
            state: 'active',
          }}>
          {({
            handleSubmit,
            handleChange,
            handleBlur,
            values,
            touched,
            isValid,
            errors,
          }) => (
          <Form id="recipe-form" noValidate onSubmit={(e)=> { e.preventDefault(); handleSubmit(); }} autoComplete="off">
            <Row className="m-3">
              <Col md='6'>
                <Form.Group className="mb-3">
                  <Form.Label>Nombre</Form.Label>
                  <Form.Control
                    name="name" 
                    type="text"
                    isInvalid={!!errors.name}
                    value={values.name}
                    onChange={handleChange}/>
                </Form.Group>
                <Row className="mb-4 align-items-center">
                  <Col sm="9">
                    <Form.Group as={Row}>
                      <Form.Label column sm="3">Porción</Form.Label>
                      <Col sm="4">
                        <Form.Control
                          name="portion"
                          type="number"
                          min="0"
                          max="99"
                          isInvalid={!!errors.portion}
                          onChange={handleChange}
                          value={values.portion}/>
                      </Col>
                    </Form.Group>
                  </Col>
                  <Col sm="3">
                    <Form.Group>
                      <Form.Check
                        id="variation-ckeck"
                        name="variation"
                        type="switch"
                        label="Variación"
                        onChange={handleChange}
                        checked={values.variation}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Form.Group className="mb-3">
                  <Form.Label>Preparación</Form.Label>
                  <Form.Control
                    name="preparation"
                    as="textarea"
                    rows={8}
                    isInvalid={!!errors.preparation}
                    value={values.preparation}
                    onChange={handleChange}/>
                </Form.Group>
              </Col>
              <Col md="6">
                <Row>
                  <Form.Group  className="mb-4">
                    <Form.Label>Descripción</Form.Label>
                    <Form.Control
                      rows={2}
                      as="textarea"
                      name="description"
                      value={values.description}
                      onChange={handleChange}/>
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <div className="d-flex justify-content-between">
                      <Form.Label column sm="3">Ingredientes</Form.Label>
                      <Button className="text-info" title="Eliminar" variant="outline-light" size="sm" onClick={addIngredient}><i className="fas fa-plus-square"></i> Agregar</Button>
                    </div>
                    <Form.Control type="hidden" isInvalid={!!ingredientsError} />
                    <Form.Control.Feedback type="invalid">
                      {ingredientsError}
                    </Form.Control.Feedback>
                    <Table size="sm" variant="flush" style={{ display: 'block', maxHeight: '500px', overflowY: 'auto' }} ref={table}>
                      <thead className="position-sticky top-0 bg-white" style={{ zIndex: 2 }}>
                        <tr>
                          <th>Producto</th>
                          <th>Cantidad</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        {ingredientRows &&
                          ingredientRows.map((ingredient: any, index: number) => (
                            <IngredientRow ingredient={ingredient} key={`in-row-${ingredient._id}-${index}`} onAddProduct={(label: string) => setProductAdd(label)} onChange={(i: any) => {setIngredientByIndex(index, i)}} onRemove={() => {removeIngredient(index)}}/>
                          ))
                        }
                      </tbody>
                    </Table>
                  </Form.Group>
                </Row>
              </Col>
            </Row>
          </Form>
          )}
          </Formik>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={()=>close()}>Cancelar</Button>
          <Button variant="primary" type="submit" form="recipe-form">Guardar</Button>
        </Modal.Footer>
      </Modal>
      {productAdd && 
        <ProductForm show={!!productAdd}
          product={{ name: productAdd }}
          close={() => setProductAdd('')}/>
        }
    </>
  )
}

export default RecipeForm;