import { PurchaseContext } from 'context/purchases/PurchaseState';
import { OrderContext } from 'context/orders/OrderState';
import { IOrder, IPurchase, ISupplier } from 'interfaces';
import { useState, useContext, useEffect } from 'react';
import { Button, Table, Row, Col, Modal, Form } from "react-bootstrap";
import { Formik } from 'formik';
import PurchaseRow from './PurchaseRow';
import * as Yup from 'yup';
import ProductForm from 'modules/administrative/ProductForm';
import { useHistory, useParams } from 'react-router';
import { SupplierContext } from 'context/suppliers/SupplierState';
import { pbkdf2 } from 'crypto';
import { round } from 'utils/utilities';

const schema = Yup.object().shape({
  // name: Yup.string().min(2, '¡Muy corto!').max(50, '¡Muy Largo!').required('Requerido'),
});

const PurchaseForm = () => {
  const history = useHistory();
  const { purchases, addPurchase, editPurchase } = useContext(PurchaseContext);
  const { orders } = useContext(OrderContext);
  const { suppliers } = useContext(SupplierContext);
  const [ purchaseRows, setPurchaseRows ] = useState<any>([{}]);
  const [ editingPurchase, setEditingPurchase ] = useState<IPurchase>(null);
  const [ totalAmount, setTotalAmount] = useState<number>(0);
  const [ totalAmountIva, setTotalAmountIva] = useState<number>(0);

  const [ excludeOptions, setExcludeOptions ] = useState<any>([]);
  const [ products, setProducts ] = useState<any>([]);
  const [ productsError, setProductsError ] = useState<any>('');
  const [ productAdd, setProductAdd ] = useState<string>('');

  const params = useParams<{ id?: string | undefined; }>();

  const [ order, setOrder ] = useState<IOrder>({});
  const [ supplier, setSupplier ] = useState<ISupplier>({});

  useEffect(() => {
    if (!params.id || !orders.length || !suppliers.length) { return; }
    if (history.location.pathname.includes('new')) {
      const orderById = orders.find(o => o._id === params.id);
      if (orderById) {
        setOrder(orderById);
        const supplierById = suppliers.find(s => s._id === orderById.supplier?._id);
        if (supplierById && orderById.products) {
          setProducts(orderById.products);
          calculateTotal(orderById.products);
          setSupplier(supplierById);
          setExcludeOptions(supplierById.products.filter(({ product: p1 }) => orderById?.products?.some(({ product: p2}) => p2._id === p1._id )).map(p => p.product._id));
          setPurchaseRows(orderById.products);
        }
      }
    } else {
      const purchaseById: any = purchases.find(o => o._id === params.id);
      if (purchaseById) {
        const orderById: any = orders.find(o => o.orderCode === purchaseById.order);
        const supplierById = suppliers.find(s => s._id === orderById.supplier?._id);
        if (orderById && supplierById) {
          setOrder(orderById);
          setProducts(purchaseById.products);
          calculateTotal(purchaseById.products);
          setSupplier(supplierById);
          setExcludeOptions(purchaseById.supplier.products.filter(({ product: p1 }) => purchaseById.products.some(({ product: p2}) => p2._id === p1._id )).map(p => p.product._id));
          setPurchaseRows(purchaseById.products.map((p: any) => {
            const orderP = orderById?.products?.find((oP: any) => p.product._id === oP.product._id);
            return { ...p, orderUnitPrice: orderP?.unitPrice, orderQuantity: orderP?.quantity, orderPresentation: orderP?.presentation }
          }));
          setTotalAmount(purchaseById.totalAmount);
          setTotalAmountIva(purchaseById.totalAmountIva);
          setEditingPurchase(purchaseById);
        }
      }
    }
  }, [params, orders, suppliers]);

  // useEffect(() => {
  //   if (history.location.pathname.includes('new')) { return; }
  //   if (!params.id || !orders.length || !suppliers.length) { return; }
    
  // }, [params, orders, suppliers]);

  const setCommodityByIndex = (index: number, commodity: any) => {
    setProductsError('');
    const newProducts = Object.assign([...products], {[index]: commodity });
    setExcludeOptions(supplier.products.filter(({ product: p1 }) => newProducts.some(({ product: p2}) => !!p2 && p2._id === p1._id )).map(p => p.product._id));
    calculateTotal(newProducts);
    setProducts(newProducts);
  }

  const calculateTotal = (products) => {
    let total = 0, totalIva = 0;
    products.forEach((p: any) => {
      total += (isNaN(p.totalPrice) ? 0 : p.totalPrice);
      totalIva += (isNaN(p.priceIva) ? 0 : p.priceIva);
    });
    setTotalAmount(total);
    setTotalAmountIva(totalIva);
  }

  const addCommodity = () => {
    setPurchaseRows([...purchaseRows, { }]);
    setProducts([...products, { }]);
  }

  const removeCommodity = (index: number) => {
      if (purchaseRows.length == 1) {
        setPurchaseRows([{}]);
        setProducts([{}]);
      } else {
        const newProducts = [...removeFromArray(products, index)];
        setExcludeOptions(supplier.products.filter(({ product: p1 }) => newProducts.some(({ product: p2}) => !!p2 && p2._id === p1._id )).map(p => p.product._id));
        setPurchaseRows([...removeFromArray(purchaseRows, index)]);
        setProducts(newProducts);
        calculateTotal(newProducts);
      }
  }

  const removeFromArray = (arr: any[], indexToRemove: number) => {
    return [...arr.slice(0, indexToRemove), ...arr.slice(indexToRemove + 1)]
  }

  const checkPurchase = (values: any) => {

    // id:           req.body.id,
    // purchaseCode:  req.body.purchaseCode,
    // flagIVA:      req.body.flagIVA,
    // totalAmount:  req.body.totalAmount,
    // purchaseDate:  req.body.purchaseDate,
    // createdBy:    req.body.createdBy,
    // order:        req.body.order,
    // comments:     req.body.comments,
    // program:      req.body.program,
    // products:     req.body.products,
    // supplier:
    // products: [{
    //   quantity: { type: Number, required: true },
    //   product: { type: mongoose.Schema.Types.ObjectId, ref: "product", required: true },
    //   unitPrice: { type: Number, required: true },
    //   totalPrice: { type: Number, required: true }
    // }]

    return {...values,
      purchaseCode: editingPurchase ? editingPurchase.purchaseCode : order.orderCode?.replace('O', 'C'),
      order: order.orderCode,
      supplier: supplier._id,
      program: order.program,
      products: products.filter((i: any) => !!i.product).map((i: any) => ({ ...i, product: i.product._id || i.product._id })),
      totalAmount,
      totalAmountIva

    }
  }
  
  const savePurchase = (values: any) => {
    const savingPurchase = checkPurchase(values);
    if (!savingPurchase.products.length) {
      setProductsError('Debes agregar productos');
    } else {
      editingPurchase ? editPurchase({ _id: editingPurchase._id, ...savingPurchase }) : addPurchase(savingPurchase);
      history.push('/admin/compras/purchases');
      // close();

    }
  }

  if (!supplier._id) {
    return null;
  }
  return (
    <>
      <h3 className="mt-0">{editingPurchase ? 'Editar ' : ''} Compra / Post-Orden</h3>
      <Formik
      validateOnChange={false}
      validateOnBlur={false}
      validationSchema={schema}
      onSubmit={savePurchase}
      initialValues={{
        flagIVA: false,
        totalAmount: 0,
        purchaseDate: new Date(),
        supplierInvoice: editingPurchase?.supplierInvoice || '',
        comments: editingPurchase?.comments || ''
      }}>
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
      }) => (
      <Form id="supplier-form" noValidate onSubmit={(e)=> { e.preventDefault(); handleSubmit(); }}>
        <Row className="m-2">
          <Col>
            <Form.Group className="mb-3">
              <Form.Control type="hidden" isInvalid={!!productsError} />
              <Form.Control.Feedback type="invalid">
                {productsError}
              </Form.Control.Feedback>
              <Table size="sm" variant="flush">
                <thead>
                  <tr>
                    <th className="col-5">Producto</th>
                    <th colSpan={2} className="col-2 text-center">Cantidad</th>
                    <th colSpan={2} className="col-4 text-center">Precio unitario(₡)</th>
                    <th className="col-1">Total</th>
                    <th className="col-1">Total + IVA</th>
                  </tr>
                  <tr>
                    <th></th>
                    <th className="col-1 text-center">Orden</th>
                    <th className="col-1 text-center">Real</th>
                    <th className="col-2 text-center">Anterior</th>
                    <th className="col-2 text-center">Nuevo</th>
                    <th></th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {purchaseRows &&
                    purchaseRows.map((commodity: any, index: number) => (
                      <PurchaseRow row={commodity} products={supplier.products} exclude={excludeOptions} key={`in-row-${commodity._id}-${index}`} onAddProduct={(label: string) => setProductAdd(label)} onChange={(i: any) => {setCommodityByIndex(index, i)}} onRemove={() => {removeCommodity(index)}}/>
                    ))
                  }
                </tbody>
              </Table>
            </Form.Group>
            <div className="mb-5 d-flex justify-content-between">
              <h5></h5>
              {(purchaseRows.length < supplier.products.length) &&
                <Button className="text-info" title="Agregar Producto" variant="outline-light" size="sm" onClick={addCommodity}><i className="fas fa-plus-square"></i> Agregar</Button>}
            </div>
            <div className="d-flex justify-content-end">
              <h5>Subtotal: <b>{`₡${totalAmount}`}</b></h5>
            </div>
            <div className="d-flex justify-content-end">
              <h3>Total + iva: <b>₡{round(totalAmountIva,2)}</b></h3>
            </div>
          </Col>
        </Row>
        <hr/>
        <Row className="m-1">
          <Col>
            <p>
                Programa(s): <b>{order.program?.map((p: any) => p.name).join(', ')} </b>
                <br/>
                Realizada en: <b>{supplier.name} </b>
                <br/>
              {supplier.contactName && <>Contacto:  {supplier.contactName} <br/></>}
              {supplier.phoneNumber && <>Teléfono:  {supplier.phoneNumber} <br/></>}
              {supplier.email && <>Correo:  {supplier.email}</>}
            </p>

          </Col>
          <Col sm="6">
          <Row>
            <Form.Group  className="m-1">
              <Form.Label>Número de Factura Proveedor:</Form.Label>
              <Form.Control
                type="text"
                name="supplierInvoice"
                value={values.supplierInvoice}
                onChange={handleChange}/>
            </Form.Group>
            </Row>
            <Row>
            <Form.Group  className="mb-3">
              <Form.Label>Comentarios:</Form.Label>
              <Form.Control
                rows={2}
                as="textarea"
                name="comments"
                value={values.comments}
                onChange={handleChange}/>
            </Form.Group>
            </Row>
          </Col>
        </Row>
        <hr/>
        <Row>
          <Col className="text-center">
            <Button variant="primary" size="lg" type="submit" form="supplier-form">Guardar</Button>
          </Col>
        </Row>
      </Form>
      )}
      </Formik>
      {productAdd && 
      <ProductForm show={!!productAdd}
        product={{ name: productAdd }}
        close={() => setProductAdd('')}/>
      }
    </>
  )
}

export default PurchaseForm;