
import { useContext, useEffect, useRef, useState } from "react";
import { Accordion, Badge, Breadcrumb, Button, Col, ListGroup, InputGroup, FormControl, Row, Table } from "react-bootstrap"
import { useHistory } from "react-router";
import moment from "moment";
import OrderPrintForm from './OrderPrintForm';
import { PreorderContext } from "context/preorders/PreorderState";
import { SupplierContext } from "context/suppliers/SupplierState";
import { OrderContext } from "context/orders/OrderState";
import { IPreorder } from "interfaces";
import logo from 'assets/img/orange-logo-sm.png';
// import XLSX from 'xlsx-js-style';
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import 'jspdf-autotable';
import calibryTtf from "utils/calibry";
import { Field } from "formik";
import { useAuth } from 'context/auth/AuthContext';
import { round } from "utils/utilities";
import Loading from "views/Loading";

// import pdfMake from 'pdfmake';
// import pdfFonts from 'pdfmake/build/vfs_fonts';
// import htmlToPdfmake from 'html-to-pdfmake';

const PreorderList = () => {
  const history = useHistory();
  const { orders, loading } = useContext(OrderContext);
  const [data, setData] = useState<any>(orders);
  const [modalEdit, setModalEdit] = useState(null);
  const { matchRole } = useAuth();

  useEffect(() => {
    setData(orders);
  }, [orders]);

  const filterList = (event: any) => {
		let updatedList = [...orders];
		updatedList = updatedList.filter(({ orderCode, createdAt, supplier }) => {
      return `${orderCode}${createdAt}${supplier}`.toLowerCase().search(event.target.value.toLowerCase()) !== -1;
    });
    setData(updatedList);
	}

  const closeModal = () => {
    setModalEdit(null);
  }

  const printDocument = (order: any) => {
    setModalEdit(order);
  
  }

  return (
    <Col>
      <Row>
        <Col md="6">
          <h4 className="mt-0">Lista de Órdenes</h4>
        </Col>
        <Col>  
            <InputGroup className="mb-3">
              <InputGroup.Text id="search-input" >
                <i className="nc-icon nc-zoom-split"></i>
              </InputGroup.Text>
              <FormControl
                placeholder="Buscar Orden"
                aria-label="Buscar"
                aria-describedby="search-input"
                onChange={filterList}
              />
            </InputGroup>
          </Col>
      </Row>
      {!!loading && <Loading scale="0.5"></Loading> }
      <Accordion>
        {data.map((model: any, index: any)=>(
          <Accordion.Item key={`preorder-${model._id || index}`} eventKey={`preorder-${model._id || index}`}>
            <Accordion.Header className="mt-0">
              <div className="d-flex flex-grow-1 align-items-center pe-4">
              
                <Col lg="4"><b className="text-capitalize">{model.orderCode}</b>&nbsp;</Col>
                <Col lg="4">
                {model.createdAt && <span> {moment(model.createdAt).format('LL')} </span>}&nbsp;</Col>
                <Col lg="4">Proveedor: {model.supplier?.name}</Col>&nbsp;
              </div>
            </Accordion.Header>
            <Accordion.Body>
              <p>
                {model.program?.length && <><b>Programa(s): </b>{model.program?.map(p => p.name).join(', ')}</>}
              </p>
              <div id={`order-table-${model._id}`}>
                <h4>Productos de la Orden</h4>
                {matchRole(['administrador','contabilidad']) && 
                  <Button title="Incluir" variant="primary" className="float-end text-white" onClick={() => { history.push(`purchases/new/${model._id}`); }}>Agregar Compra<i className="fas"></i></Button>
                }
                <Table>
                  <thead>
                    <tr>
                      <th className="col-6">Producto</th>
                      <th className="col-2">Cantidad</th>
                      <th className="col-2">Precio Unitario</th>
                      <th className="col-2 text-end">Precio</th>
                      <th className="col-2 text-end">Precio + IVA</th>
                    </tr>
                  </thead>
                  <tbody>
                    {model.products.map(({ product: p, quantity, presentation, unitPrice, totalPrice, priceIva }: any, index: number) => (                                 
                      <tr key={`preorder-list-product${index}`}>
                        <td>{p.name} {presentation}{p.unitOfMeasure}</td>
                        <td>{quantity}</td>
                        <td>₡{unitPrice}</td>
                        <td className="text-end">₡{totalPrice}</td>
                        <td className="text-end">₡{priceIva}</td>
                      </tr>
                    ))}
                    <tr>
                      <td colSpan={4}  className="text-end border-0">total:  ₡{model.total}</td>
                      <td className="border-0">₡{round(model.totalIva, 2)}</td>
                    </tr>
                  </tbody>
                </Table>
              </div>
              {/* <Button onClick={()=>exportOrderToExcel(`order-table-${model._id}`)}>Descargar</Button> */}
              {!matchRole(['consulta']) && 
                <Button onClick={()=>printDocument(model)}>Descargar</Button>
              }
            </Accordion.Body>
          </Accordion.Item>
        ))}
      </Accordion>
      {modalEdit && 
          <OrderPrintForm show={modalEdit}
            order={modalEdit}
            close={() => closeModal()}/>
      }
    </Col>
  )
}

export default PreorderList

