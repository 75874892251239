import { IUser } from "interfaces";
import { IState } from "./UserState";

export default function userReducer(state: IState, action: any) {
  switch (action.type) {
    case "LOAD_USERS":
      return {
        ...state,
        users: action.payload,
      };

    case "LOAD_USERS_BY_ROLE":
      return {
        ...state,
        users: { ...state, ...action.payload },
      };

    case "ADD_USER":
      return {
        ...state,
        users: [...state.users, action.payload],
      };

    case "EDIT_USER":
      const updatedUser = action.payload;

      const updatedUsers = state.users.map((user: IUser) => {
        if (user._id === updatedUser._id) {
          return updatedUser;
        }
        return user;
      });

      return {
        ...state,
        users: updatedUsers,
      };

    case "REMOVE_USER":
      return {
        ...state,
        users: state.users.filter(
          (user: IUser) => user._id !== action.payload
        ),
      };

    default:
      return state;
  }
};