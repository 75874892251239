import { ActivityContext } from 'context/activities/ActivityState';
import { CatalogContext } from 'context/catalogs/CatalogState';
import CustomDatePicker from 'components/CustomDatePicker';
import { IActivity } from 'interfaces';
import { useState, useEffect, useContext } from 'react';
import { Button, Table, Row, Col, Modal, Accordion, Form, ListGroup } from "react-bootstrap";
import { Formik } from 'formik';
import * as Yup from 'yup';
import RecipeRow from './RecipeRow';

const schema = Yup.object().shape({
  name: Yup.string().min(2, '¡Muy corto!').max(25, '¡Muy Largo!').required('Requerido'),
  description: Yup.string().required('Requerido'),
  activityType: Yup.string().required('Requerido'),
  category: Yup.string().required('Requerido'),
  initialDate: Yup.date().required('Requerido'),
  weeksDuration: Yup.number().required('Requerido'),
  amountOfPeople: Yup.number().required('Requerido')
});

const ActivityForm = (props: { show: boolean, activity: IActivity, close: Function  }) => {
  const { addActivity, editActivity } = useContext(ActivityContext);
  const { catalogs } = useContext(CatalogContext);
  const { show, activity, close } = props;

  const [activityTypes, setActivityTypes] = useState<any>([]);
  const [categories, setCategories] = useState<any>([]);

  const [ recipeRows, setrecipeRows ] = useState<any>(activity && activity.recipes ?  activity.recipes : [{}]);
  const [ recipes, setRecipes ] = useState<any>([...recipeRows]);
  const [ recipesError, setRecipesError ] = useState<any>('');

  useEffect(() => {
    if (catalogs) { 
      if (catalogs['programas']) { setActivityTypes(catalogs['programas']); }
      if (catalogs['modulos']) { setCategories(catalogs['modulos']); }
    }
  }, [catalogs]);

  const setRecipeByIndex = (index: number, recipe: any) => {
    setRecipesError('');
    setRecipes(Object.assign([...recipes], {[index]: recipe }));
  }

  const addRecipe = () => {
    if (recipeRows.length > 50) { return; }
    const last = recipes[recipes.length - 1];
    if (!last.recipe) { return; }
    setrecipeRows([...recipeRows, { }]);
    setRecipes([...recipes, { }]);
  }

  const removeRecipe = (index: number) => {
      if (recipeRows.length == 1) {
        setrecipeRows([{}]);
        setRecipes([{}]);
      } else {
        setrecipeRows([...removeFromArray(recipeRows, index)]);
        setRecipes([...removeFromArray(recipes, index)]);
      }
  }

  const removeFromArray = (arr: any[], indexToRemove: number) => {
    return [...arr.slice(0, indexToRemove), ...arr.slice(indexToRemove + 1)]
  }

  const checkActivity = (values: any) => {
    return {...values,
      _id: activity._id,
      recipes: recipes.filter((i: any) => !!i.recipe).map((i: any) => ({ recipe: i.recipe._id, date: i.date, servings: i.servings, session: i.session, teacher: i.teacher || 0 }))
    }
  }
  
  const saveActivity = (values: any) => {
    const savingActivity = checkActivity(values);
    if (!savingActivity.recipes.length) {
      setRecipesError('Debes agregar recetas');
    } else {
      savingActivity._id ? editActivity(savingActivity) : addActivity(savingActivity);
      close();
    }
  }

  return (
    <Modal show={show} onHide={close} size="xl" centered>
      <Modal.Header closeButton>
        <Modal.Title>{activity._id ? 'Edición' : 'Nueva'} Actividad</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Formik
        validateOnSubmit={false}
        validateOnChange={false}
        validateOnBlur={false}
        validationSchema={schema}
        onSubmit={saveActivity}
        initialValues={{
          name: activity.name,
          description: activity.description,
          activityType: activity.activityType?._id,
          category: activity.category?._id,
          initialDate: activity.initialDate ? new Date(activity.initialDate) : undefined,
          endDate: activity.endDate ? new Date(activity.endDate) : undefined,
          weeksDuration: activity.weeksDuration,
          amountOfPeople: activity.amountOfPeople,
          state: 'active',
        }}>
        {({
          handleSubmit,
          handleChange,
          handleBlur,
          values,
          touched,
          isValid,
          errors,
        }) => (
        <Form id="activity-form" noValidate onSubmit={(e)=> { console.log(e); e.preventDefault(); handleSubmit(); }} autoComplete="off">
          <Row>
            <Col md='6'>
              <Form.Group className="mb-3">
                <Form.Label>Nombre</Form.Label>
                <Form.Control
                  name="name" 
                  type="text"
                  isInvalid={!!errors.name}
                  value={values.name}
                  onChange={handleChange}/>
              </Form.Group>
              <Form.Group  className="mb-4">
                <Form.Label>Descripción</Form.Label>
                <Form.Control
                  rows={2}
                  as="textarea"
                  name="description"
                  isInvalid={!!errors.description}
                  value={values.description}
                  onChange={handleChange}/>
              </Form.Group>
              <Form.Group as={Row}>
                <Form.Label column sm="6">Cantidad de Personas</Form.Label>
                <Col sm="6">
                  <Form.Control
                    name="amountOfPeople"
                    type="number"
                    min="0"
                    max="1000"
                    isInvalid={!!errors.amountOfPeople}
                    onChange={handleChange}
                    value={values.amountOfPeople}/>
                </Col>
              </Form.Group>
              <Form.Group as={Row}>
                <Form.Label column sm="6">Semanas de duración</Form.Label>
                <Col sm="6">
                  <Form.Control
                    name="weeksDuration"
                    type="number"
                    min="0"
                    max="1000"
                    isInvalid={!!errors.weeksDuration}
                    onChange={handleChange}
                    value={values.weeksDuration}/>
                </Col>
              </Form.Group>
            </Col>
            <Col md="6">
              <Row>
                <Form.Group className="mb-4">
                  <Form.Label>Fecha de Inicio</Form.Label>
                  <CustomDatePicker
                      name="initialDate"
                      isInvalid={!!errors.initialDate}
                      value={values.initialDate}
                      onChange={handleChange} 
                    />
                </Form.Group>
                <Form.Group className="mb-4">
                  <Form.Label>Fecha de Finalización</Form.Label>
                  <CustomDatePicker
                      name="endDate"
                      value={values.endDate}
                      onChange={handleChange} 
                    />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Programa</Form.Label>
                  <Form.Select aria-label="Programa"
                    name="activityType"
                    value={values.activityType}
                    isInvalid={!!errors.activityType}
                    onChange={handleChange}>
                    <option></option>
                    {activityTypes.map((a: any) => <option key={`a-type-o-${a._id}`} value={a._id}>{a.acronym} - {a.name}</option>)}
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                      {errors.activityType}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Modulo</Form.Label>
                  <Form.Select aria-label="Modulo"
                    name="category"
                    value={values.category}
                    isInvalid={!!errors.category}
                    onChange={handleChange}>
                    <option></option>
                    {categories.map((c: any) => <option key={`c-type-o-${c._id}`} value={c._id}>{c.name}</option>)}
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                      {errors.category}
                  </Form.Control.Feedback>
                </Form.Group>
              </Row>
            </Col>
            <Col>
              <Form.Group className="mb-3">
                <div className="d-flex justify-content-between">
                  <Form.Label column sm="3"></Form.Label>
                  <Button className="text-info" title="Eliminar" variant="outline-light" size="sm" onClick={addRecipe}><i className="fas fa-plus-square"></i> Agregar</Button>
                </div>
                <Form.Control type="hidden" isInvalid={!!recipesError} />
                <Form.Control.Feedback type="invalid">
                  {recipesError}
                </Form.Control.Feedback>
                <Table size="sm" variant="flush">
                  <thead>
                    <tr>
                      <th>Receta</th>
                      <th className="col-3">Fecha</th>
                      <th className="col-2">Cantidad</th>
                      <th className="col-2">Sesión</th>
                      <th className="col-2">Encargado</th>
                    </tr>
                  </thead>
                  <tbody>
                    {recipeRows &&
                      recipeRows.map((recipe: any, index: number) => (
                        <RecipeRow recipe={recipe} key={`in-row-${recipe._id}-${index}`} onChange={(i: any) => {setRecipeByIndex(index, i)}} onRemove={() => {removeRecipe(index)}}/>
                      ))
                    }
                  </tbody>
                </Table>
              </Form.Group>
            </Col>
          </Row>
        </Form>
        )}
        </Formik>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="danger" onClick={()=>close()}>Cancelar</Button>
        <Button variant="primary" type="submit" form="activity-form">Guardar</Button>
      </Modal.Footer>
    </Modal>
  )
}

export default ActivityForm;
