import { IPurchase } from "interfaces";
import { IState } from "./PurchaseState";

export default function purchaseReducer(state: IState, action: { type: string; payload: any; }) {
  switch (action.type) {
    case "LOADING_PURCHASES":
      return {
        ...state,
        loading: true,
      };
    case "LOAD_PURCHASES":
      return {
        ...state,
        loading: false,
        purchases: action.payload,
      };

    case "ADD_PURCHASE":
      return {
        ...state,
        purchases: [action.payload, ...state.purchases],
      };

    case "EDIT_PURCHASE":
      const updatedPurchase = action.payload;

      const updatedPurchases = state.purchases.map((purchase: IPurchase) => {
        if (purchase._id === updatedPurchase._id) {
          return updatedPurchase;
        }
        return purchase;
      });

      return {
        ...state,
        purchases: updatedPurchases,
      };

    case "REMOVE_PURCHASE":
      return {
        ...state,
        purchases: state.purchases.filter(
          (purchase: IPurchase) => purchase._id !== action.payload
        ),
      };

    default:
      return state;
  }
};