import "../assets/styles/views/loading.scss";

const Loading = ({ logo, scale }: any) => (
  <>
    <div className="app-loader" style={{ scale: (scale || 1) }}>
        <div className="app-loader-animation">
            <svg width="150" height="150" strokeWidth="2.67" viewBox="3.67 3.67 92.67 92.67">
                <path className="app-loader-track" d="M 50,50 m 0,-45 a 45,45 0 1 1 0,90 a 45,45 0 1 1 0,-90"></path>
                <path className="app-loader-head" d="M 50,50 m 0,-45 a 45,45 0 1 1 0,90 a 45,45 0 1 1 0,-90" pathLength="280" strokeDasharray="280 280" strokeDashoffset="210"></path>
            </svg>
        </div>
    </div>
    {!!logo &&
      <div className="app-loader-logo"></div>
    }
  </>
)

export default Loading;