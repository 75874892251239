import { IProduct } from 'interfaces';
import React, { createContext, useEffect, useReducer } from 'react';
import axios from "axios";
import productReducer from './ProductReducer';
import { useErrorStatus } from 'context/ErrorProvider';

export interface IState {
  products: IProduct[];
  loading?: boolean;
  productsHash: { [key: string]: IProduct }
  addProduct?: any;
  editProduct?: any;
  removeProduct?: any;
}

const initialState: IState = {
  products: [],
  productsHash: {}
};

export const ProductContext = createContext(initialState);

export const ProductProvider = ({ children }: { children: any }) => {
  const { setErrorStatusCode }: any = useErrorStatus();
  const [state, dispatch] = useReducer(productReducer, initialState);

  const getProducts = async () => {
    
    try {
      dispatch({
        type: "LOADING_PRODUCTS"
      });
      
      const res = await axios.get("/api/products");
      if (res){
        dispatch({
          type: "LOAD_PRODUCTS",
          payload: res.data
        });
      } else {
  
      }
    } catch (error:any) {
      // dispatch({ type: "LOGIN_FAILURE" });
      if (error.response.status) {
        setErrorStatusCode(error.response.status)
      }
      // throw(error);
    }
  }

  useEffect(()=> {
    getProducts();
  },[])

  const addProduct = async (product: IProduct) => {
    const res = await axios.post("/api/products", product);
    if (res){  
      dispatch({
        type: "ADD_PRODUCT",
        payload: res.data
      });
    }
  }

  const editProduct = async (product: IProduct) => {
    const res = await axios.put(`/api/products/${product._id}`, product);
    if (res){  
      dispatch({
        type: "EDIT_PRODUCT",
        payload: res.data
      });
    }
  }

  const removeProduct = async (product: IProduct) => {
    const res = await axios.put(`/api/products/${product._id}`, { ...product, state: 'inactive', name:`${product.name}-INACTIVO` });
    if (res){      
      dispatch({
        type: "EDIT_PRODUCT",
        payload: res.data
      });
      dispatch({
        type: "REMOVE_PRODUCT",
        payload: res.data
      });
    }
  }


  return (
    <ProductContext.Provider
      value={{
        products: state.products,
        productsHash: state.productsHash,
        loading: state.loading,
        addProduct,
        editProduct,
        removeProduct
      }}
    >
      {children}
    </ProductContext.Provider>
  );
};