import { IRecipe } from 'interfaces';
import React, { createContext, useEffect, useReducer } from 'react';
import axios from "axios";
import recipeReducer from './RecipeReducer';

export interface IState {
  recipes: IRecipe[];
  loading?: boolean;
  addRecipe?: any;
  editRecipe?: any;
  removeRecipe?: any;
  getRecipes?: any;
}

const initialState: IState = {
  recipes: []
};

export const RecipeContext = createContext(initialState);

export const RecipeProvider = ({ children }: { children: any }) => {
  const [state, dispatch] = useReducer(recipeReducer, initialState);

  const getRecipes = async () => {
    dispatch({
      type: "LOADING_RECIPES"
    });
    
    const res = await axios.get("/api/recipes");
    if (res){
      dispatch({
        type: "LOAD_RECIPES",
        payload: res.data
      });
    }
  }

  useEffect(()=> {
    getRecipes();
  },[])

  const addRecipe = async (recipe: IRecipe) => {
    const res = await axios.post("/api/recipes", recipe);
    if (res){  
      dispatch({
        type: "ADD_RECIPE",
        payload: res.data
      });
    }
  }

  const editRecipe = async (recipe: IRecipe) => {
    const res = await axios.put(`/api/recipes/${recipe._id}`, recipe);
    if (res){  
      dispatch({
        type: "EDIT_RECIPE",
        payload: res.data
      });
    }
  }

  const removeRecipe = async (recipe: IRecipe) => {
    const res = await axios.put(`/api/recipes/${recipe._id}`,{...recipe, state: 'inactive', name:`${recipe.name}-INACTIVA`});
    if (res){      
      dispatch({
        type: "EDIT_RECIPE",
        payload: res.data
      });
      dispatch({
        type: "REMOVE_RECIPE",
        payload: res.data
      });
    }
  }


  return (
    <RecipeContext.Provider
      value={{
        recipes: state.recipes,
        loading: state.loading,
        addRecipe,
        editRecipe,
        removeRecipe,
        getRecipes
      }}
    >
      {children}
    </RecipeContext.Provider>
  );
};