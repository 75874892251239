

import { useState, useContext, useEffect, useRef } from 'react';
import { Button, Card, Table, Container, Row, Col, Modal, Accordion, Form, ListGroup, Badge } from "react-bootstrap";
import { Formik, useFormikContext } from 'formik';
import { useHistory } from "react-router";
import CustomDatePicker from 'components/CustomDatePicker';
import LoadingBox from 'components/LoadingBox';
import { CatalogContext } from 'context/catalogs/CatalogState';
import { ActivityContext } from 'context/activities/ActivityState';
import { SupplierContext } from 'context/suppliers/SupplierState';
import { PreorderContext } from 'context/preorders/PreorderState';
import { IActivity, ICatalog, IRecipe } from 'interfaces';
import { calculateIva, round } from "utils/utilities";
import { IProduct } from 'interfaces';
import PreorderProductRow from './PreorderProductRow';
import moment from 'moment';
import * as Yup from 'yup';
import "moment/locale/es";
import { Link, useParams } from 'react-router-dom';
import CustomTypeahead from 'components/CustomTypeahead';
import { useAuth } from 'context/auth/AuthContext';
import ExportExcel from 'utils/exportExcel';

const filterSchema = Yup.object().shape({
  initial: Yup.string().required('Requerido'),
  final: Yup.string().required('Requerido'),
  activityType: Yup.array()
});

interface RecipePerActivity { id?: string; activity: string; session: string; recipe: IRecipe; activityObj: IActivity; servings: number };
const PreorderSelection = () => {
  const history = useHistory();
  const { catalogs } = useContext(CatalogContext);
  const { suppliers } = useContext(SupplierContext);
  const { foundActivities: activities, searchActivities } = useContext(ActivityContext);
  const { preorders, addPreorder, editPreorder } = useContext(PreorderContext);

  const [activitiesAvailable, setActivitiesAvailable] = useState<IActivity[]>([]);
  const [selectedRecipes, setSelectedRecipes] = useState<RecipePerActivity[]>([]);
  const [prefixPreorderAux, setPrefixPreorderAux] = useState<any>(new Date().getTime());
  const [preorderProducts, setPreorderProducts] = useState<any>({});
  const [preorderExtraProducts, setPreorderExtraProducts] = useState<any>({});
  const [preorderTotal, setPreorderTotal] = useState<number>(0);

  const saveDates = useRef<any>({});
  const saveRecipes = useRef<any>([]);
  const saveActivities = useRef<any>({});
  const readyProductsDinamic = useRef<any>({});

  const [excludeProducts, setSetExcludeProducts] = useState<any[]>([]);
  const [showRecipes, setShowRecipes] = useState(true);
  const [programs, setPrograms] = useState<ICatalog[]>([]);
  const [prioritySupplier, setPrioritySupplier] = useState<any>('');
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [preorderSaved, setPeorderSaved] = useState<boolean>(false);

  const [preorderPrint, setPreorderPrint] = useState<boolean>(false);
  const [preorderPrintDetails, setPreorderPrintDetails] = useState<any[]>([]);
  const [preorderRecipesPrintDetails, setPreorderRecipesPrintDetails] = useState<any[]>([]);

  const { matchRole } = useAuth();

  const preorderEditing = useRef<any>(null);
  const params = useParams<{ id?: string | undefined; }>();
  useEffect(() => {
    if (!params.id || !preorders.length || !activities.length) { return; }
    const preorderById = preorders.find(o => o._id === params.id);
    if (preorderById && activities.length && !preorderSaved) {
      preorderEditing.current = preorderById;
      applySearch({ initial: preorderEditing.current.initialDate, final: preorderEditing.current.endDate, activityType: preorderEditing.current.program?.map((p: any) => p._id) });
    }
  }, [params, preorders, activities]);

  useEffect(() => {
    if (catalogs && catalogs['programas']) { setPrograms(catalogs['programas']); }
    searchActivities();
  }, [catalogs]);

  const applySearch = (values: any) => {
    clearSelections();
    setSelectedRecipes([]);
    setActivitiesAvailable([]);
    let activitiesResult: IActivity[] = [];
    const selected: RecipePerActivity[] = [];
    activitiesResult = activities.filter(a => {
      let go = false;
      if (!!preorderEditing.current && a._id && !preorderEditing.current.recipesPerActivity[a._id]) { return false; }
      if (!values.activityType.includes(a.activityType?._id)) { return false; }
      a.recipes.forEach(r => {
        r.activity = a._id;
        if (moment(r.date).isBetween(values.initial, values.final, undefined, '[]')) {
          r.inRange = true;
          go = true;
        } else {
          r.inRange = false;
        }
        if (!!preorderEditing.current && a._id && preorderEditing.current.recipesPerActivity[a._id].includes(r.recipe._id)) {
          selected.push({ id: `${r.recipe._id}-${a._id}`, recipe: r.recipe, session: r.session, activity: a._id || '', activityObj: a, servings: r.servings });
        }
      })
      return go;
    });
    if (activitiesResult.length) {
      saveDates.current = { initialDate: values.initial, endDate: values.final }
    }
    setActivitiesAvailable(activitiesResult);
    if (selected.length) {
      setSelectedRecipes(selected);
    }
    if (preorderEditing.current) {
      handleUpdateRecipes(selected);
    }
  }

  // const handleSelectPrograms = (selected: any[]) => {
    
  // }

  const handleChangePrioritySupplier = (event: any) => {
    setPrioritySupplier(event.target.value);
	}

  const handleRecipeCheck = (e:any, recipe: IRecipe, activity: string,  activityObj: IActivity, session: string, servings: number ) => {
    const {checked} = e.target;
    const id = `${recipe._id}-${activity}`;
    if (checked) {
      setSelectedRecipes((prevState: any[])=>([
        ...prevState,
        { id, recipe, activity, activityObj, session, servings }
      ]));
    } else {
      setSelectedRecipes((prevState: any)=>([
        ...prevState.filter(({ id: selected_id }: any) => {
          return id !== selected_id;
        })
      ]));
    } 
  }

  const handleUpdateRecipes = (_recipes = null) => {
    const recipesSelected = _recipes || selectedRecipes;
    setPrefixPreorderAux(new Date().getTime());
    const products: {[key: string]: any} = {};
    recipesSelected.map(({ recipe: sR, program, servings }) => {
      sR.ingredients.map((i: any) => {
        const id: string = i.product?._id || 'n/a';
        if (!products[id]) {
          products[id] = {
            id: id.toString(),
            product: i.product,
            quantity: i.quantity * servings,
            required: i.quantity * servings,
            recipes: [sR.name],
            recipesObjects: [{ name: sR.name, amount: i.quantity * servings, program: program }],
          };
        } else {
          products[id].quantity += (i.quantity * servings);
          products[id].required = products[id].quantity;
          if (!products[id].recipes.includes(sR.name)) {
            products[id].recipes.push(sR.name);
          }
          products[id].recipesObjects.push({ name: sR.name, amount: i.quantity * servings, program: program });
        }
      })
    });
    setShowRecipes(false);
    setPreorderProducts(products);
    saveActivities.current = {};
    saveRecipes.current = recipesSelected.map(({ recipe: r, activity }) => {
      if (!saveActivities.current[activity]) {
        saveActivities.current[activity] = [r._id];
      } else {
        saveActivities.current[activity].push(r._id);
      }
      return r._id;
    });
  }

  const addPreorderExtraProducts = () => {
    const index = Object.keys(preorderExtraProducts).length;
    setPreorderExtraProducts({
      ...preorderExtraProducts, [`extra${index}`]: { id: `extra${index}`, product: {}, quantity: 0, recipes: [] }
    });
  }

  const productsToExclude = () => {
    const toExclude: any = [];
    Object.values(readyProductsDinamic.current).forEach((p: any) => { if (p.product.name) { toExclude.push(p.product.name.toLowerCase()); }} );
    setSetExcludeProducts(toExclude)
  }

  const handlePreorderRowChange = (id: string, row: any) => {
    constructReadyProducts({ [id.toString()]: row });
  }

  const handlePreorderRowRemove = (id: string) => {
    const newPreorderProducts = { ...preorderProducts };
    delete newPreorderProducts[id];
    setPreorderProducts(newPreorderProducts);
    handleRemove(id);
  }
  const handlePreorderExtraRowRemove = (id: string) => {
    const newPreorderProducts = { ...preorderExtraProducts };
    delete newPreorderProducts[id];
    setPreorderExtraProducts(newPreorderProducts);
    handleRemove(id);
  }

  const handleRemove = (id: string) => {
    delete readyProductsDinamic.current[id]
    constructReadyProducts();
    if (!Object.values(readyProductsDinamic.current).length) {
      setShowRecipes(true);
    }
  }

  const constructReadyProducts = (changes: any = {}) => {
    readyProductsDinamic.current = { ...readyProductsDinamic.current, ...changes }
    let total = 0;
    Object.values(readyProductsDinamic.current).forEach((rP:any) => {
      total += parseFloat(rP.totalPrice) || 0;
    });
    productsToExclude();
    setErrorMessage('');
    setPreorderTotal(round(total, 2));

    setTimeout(() => setPreorderPrint(true), 2000)
  }

  const clearSelections = () => {
    readyProductsDinamic.current = {};
    setPreorderProducts({});
    setPreorderExtraProducts({});
    setShowRecipes(true);
  }

  const savePreorder = () => {
    const { initialDate, endDate } = saveDates.current;
    const products = Object.values(readyProductsDinamic.current).filter((p: any) => p.quantity > 0 && !!p.supplier).map(({ id, ...rest }: any) => ({ ...rest, product: rest.product._id}))
    if (!products.length) {
      setErrorMessage('Debes seleccionar al menos un producto con su proveedor y cantidad requerida');
      return;
    }
    const savingPreorder = {
      activities: Object.keys(saveActivities.current),
      recipes: saveRecipes.current,
      recipesPerActivity: saveActivities.current,
      products
    }
    // addPreorder(newPreorder);
    if (preorderEditing.current) {
      editPreorder({
        _id: preorderEditing.current._id,
        ...savingPreorder
      });
    } else {
      const programs: any = [];
      activitiesAvailable.forEach(a => {
        if (!programs.includes(a.activityType?._id)) {
          programs.push(a.activityType?._id);
        }
      });
      addPreorder({
        preorderCode: `P${moment().format("DMMYYYYhmm")}-${moment(initialDate).format("DMM")}-${moment(endDate).format("DMM")}`,
        initialDate,
        endDate,
        program: programs,
        ...savingPreorder
      });
    }
    setPeorderSaved(true);
  }


  // Print

  useEffect(() => {
    if (preorderPrint && Object.values(readyProductsDinamic.current).length) {
      const products = Object.values(readyProductsDinamic.current).filter((p: any) => p.quantity > 0 && !!p.supplier).map(({ id, ...rest }: any) => {

        const rows: any[] = [];
        const recipes: any[] = [];
        let total = 0;
        rest.recipesObjects.forEach((r: any) => {
          total += r.amount;
          recipes.push(r.name);
        });
        return {
          name: rest.product.name,
          recipes: recipes.join(', '),
          amount: total,
          unit: rest.product.unitOfMeasure
        };
      });
      setPreorderPrintDetails([{ rows: products }]);
      // setPreorderPrintDetails(products);

      const recipesSelected = selectedRecipes;
      const recipes: any[] = [];
      recipesSelected.map(({ recipe: sR, activityObj, session, servings }) => {

        const rows: any[]  = [];
        let total = 0;
        sR.ingredients.forEach((i: any) => {
          rows.push({...i, name: i.product.name,  amount: `${i.quantity * servings}`, unit: i.product.unitOfMeasure});
          total += (i.quantity * servings);
        });

        const data = { 
          title: sR.name, 
          details: [`Programa: ${activityObj.activityType?.name}`, `Actividad: ${activityObj.name}`, `Sesión: ${session}`], 
          rows,
          total: total,
          totalIva: ' '
        }
        recipes.push(data);
      });

      setPreorderRecipesPrintDetails(recipes);
    }
  }, [preorderPrint])


  return (
    <>
      <Row>
        <Col md="12">
          { !preorderEditing.current &&
          <Formik
            onSubmit={applySearch}
            validationSchema={filterSchema}
            initialValues={{ initial: '', final: '', activityType: '' }}
          >
            {({
              values,
              errors,
              handleSubmit,
              handleChange,
            }) => (
              <Form noValidate onSubmit={handleSubmit} id="filter-form" auto="off">
                <Row className="d-flex justify-content-center">
                  <Col lg="5">
                    <Form.Group as={Row}>
                      <Form.Label column sm="2" className="text-end">Fechas:</Form.Label>
                      <Col sm="5">
                        <CustomDatePicker
                          name="initial"
                          placeholderText="Inicio"
                          isInvalid={!!errors.initial}
                          value={values.initial}
                          onChange={handleChange} />
                      </Col>
                      <Col sm="5">
                        <CustomDatePicker
                          name="final"
                          placeholderText="Fin"
                          isInvalid={!!errors.final}
                          value={values.final}
                          onChange={handleChange} />
                      </Col>
                    </Form.Group>
                  </Col>
                  <Col lg="5">
                    <Form.Group as={Row}>
                      <Form.Label column sm="4" className="text-end">Programas:</Form.Label>
                      <Col sm="8">
                        {/* <Form.Select aria-label="Programa"
                          name="activityType"
                          value={values.activityType}
                          isInvalid={!!errors.activityType}
                          onChange={handleChange}>
                          <option value=''>{ values.activityType ? 'No especificar' : 'Seleccionar' }</option>
                          {programs.map((a: any) => <option key={`a-type-o-${a._id}`} value={a._id}>{a.name}</option>)}
                        </Form.Select> */}
                        <CustomTypeahead
                          id="programs-multiple"
                          labelKey="name"
                          valueKey="_id"
                          multiple
                          name="activityType"
                          onChange={handleChange}
                          options={programs}
                          placeholder="Seleccione Programas..."
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                  <Col className="flex-grow-0">
                    <Button className="text-nowrap" variant="outline-primary" type="submit" form="filter-form">Buscar <i className="fas fa-search"></i></Button>
                  </Col>
                </Row>
              </Form>
            )}
          </Formik>
          }
          <Card border="secondary" className="mt-4">
            <Card.Header>
                <Row>
                  <Col md="8">
                    <Card.Title as="h4">Actividades / Recetas a realizar durante el periodo</Card.Title>
                  </Col>
                  <Col>
                    { !!Object.values(preorderProducts).length &&
                    <Button variant={showRecipes ? 'link' : 'outline-info'} className="float-end" size="sm" onClick={() => setShowRecipes(!showRecipes)}><i className="fas fa-tasks"></i></Button> }
                  </Col>
                </Row>
            </Card.Header>
            <Card.Body className={`${showRecipes ? '' : 'collapse' }`}>
              <LoadingBox empty={!activitiesAvailable.length} emptyText="No hay actividades en las fechas seleccionadas"/>
              { activitiesAvailable && activitiesAvailable.map((model: any, index: any) => (
                  <Row className="pb-3" key={`available-activity-${index}`}>
                      <h5 className="text-warning"><b>{model.name}</b>, recetas:</h5>
                      <ListGroup variant="flush" className="p-0">
                        {model.recipes &&
                          model.recipes.map((r: any, index: number) => (
                            <ListGroup.Item key={index} className="d-flex" variant={!r.inRange ? 'light' : ''}>
                              <div className="d-flex flex-grow-1 align-items-center">
                                <b>{r.recipe.name}</b>&nbsp; para {r.servings} servidas, el {moment(r.date).format('LL')}
                              </div>
                              {!r.inRange && <small>(fuera del rango)&nbsp;</small> }
                              <Form.Check
                                name={r.recipe._id}
                                type="switch"
                                isInvalid={!r.inRange}
                                checked={!!selectedRecipes.find(({ id }) => id == `${r.recipe._id}-${model._id}`)}
                                onChange={(e) => handleRecipeCheck(e, r.recipe, model._id, model, r.session, r.servings)}
                              />
                              {/* <Button title="Eliminar" variant="outline-success" size="sm" onClick={()=>{}}><i className="fas fa-cart-arrow-down"></i></Button> */}
                            </ListGroup.Item>
                          ))
                        }
                      </ListGroup>
                  </Row>
              ))}
              <Button title="Incluir" disabled={!selectedRecipes.length} variant="primary" className="float-end text-white" onClick={() => { handleUpdateRecipes(); }}><i className="fas fa-cart-arrow-down"></i> { Object.keys(preorderProducts).length ? 'Actualizar' : 'Incluir' }</Button>
            </Card.Body>
          </Card>
          { !!Object.values(preorderProducts).length &&
          <Card border="success" className="mt-4">
            <Card.Header>
                <Row>
                  <Col md="8">
                    <Card.Title as="h4">Lista de Productos a comprar</Card.Title>
                  </Col>
                  <Col md="4">
                    <Form.Select aria-label="Proveedor prioritario"
                      value={prioritySupplier}
                      onChange={handleChangePrioritySupplier}>
                      <option value=''>{ prioritySupplier ? 'No hay prioridad' : 'Proveedor prioritario' }</option>
                      {suppliers.map((a: any) => <option key={`a-type-o-${a._id}`} value={a._id}>{a.name}</option>)}
                    </Form.Select>
                  </Col>
                </Row>
            </Card.Header>
            <Card.Body className="pt-0 text-center">
              <Table striped borderless hover size="sm">
                <thead>
                  <tr>
                    <th className="col-3">Producto</th>
                    <th className="col-4">Proveedor</th>
                    <th>Cantidad</th>
                    <th></th>
                    <th>Precio</th>
                    {matchRole(['administrativo','academico_principal']) && 
                      <th style={{ width: "3%" }}></th>
                    }
                  </tr>
                </thead>
                <tbody>
                  {
                  Object.keys(preorderProducts).map((pid: any) => {
                    const p = preorderProducts[pid];
                    return <PreorderProductRow key={`${prefixPreorderAux}-${pid}`} ingredient={p} prioritySupplier={prioritySupplier} onChange={handlePreorderRowChange} onRemove={() => {handlePreorderRowRemove(pid)}}></PreorderProductRow>
                  })
                  }
                  <tr>
                    <td className="text-start border-0 border-top border-dark" colSpan={5}>
                      <Button className="text-success" title="Agregar" variant="light" size="sm" onClick={addPreorderExtraProducts}>Agregrar adicionales <i className="fas fa-plus-circle"></i></Button>
                    </td>
                  </tr>
                  {
                  Object.keys(preorderExtraProducts).map((pid: any) => {
                    const p = preorderExtraProducts[pid];
                    return <PreorderProductRow key={`${prefixPreorderAux}-extra-${pid}`} ingredient={p} isNew={true} exclude={excludeProducts} prioritySupplier={prioritySupplier} onChange={handlePreorderRowChange} onRemove={() => {handlePreorderExtraRowRemove(pid)}}></PreorderProductRow>
                  })
                  }
                  <tr className="table-success border-0 border-top border-dark">
                    <td colSpan={3}>
                      {errorMessage && <label className="text-danger">{ errorMessage }</label> }
                    </td>
                    <td className="text-end">Total:</td>
                    <td>
                      <label className="badge bg-light fs-3 me-6 text-success">
                        ₡{round(preorderTotal, 2)}
                      </label>
                    </td>
                  </tr>
                </tbody>
              </Table>
              {preorderSaved
                ? <Link to="/admin/compras/preorders"><Badge bg="light" text="primary" className="fs-5"><i className="fas fa-check"></i> Ir a lista de Preordenes</Badge></Link>
                : <Button title="Incluir" disabled={!!errorMessage} variant="outline-success" size="lg" onClick={savePreorder}><i className="fas fa-cash-register"></i>&nbsp; Guardar Preorden</Button>
              }
              {preorderPrint &&
                <>
                  &nbsp;&nbsp;
                  <ExportExcel
                    id="excel-preordenes-productos"
                    columns={[
                      { label: 'Ingrediente', key: 'name' },
                      { label: 'Recetas', key: 'recipes' },
                      { label: 'Cantidad', key: 'amount' },
                      { label: 'Unidad', key: 'unit' },
                      { label: 'Check', key: '' },
                    ]}
                    buttonText="Reporte por Producto"
                    csvDatas={preorderPrintDetails}
                    fileName="Guaitil_PreordenesProductos"
                  />
                </>
              }
              {preorderPrint &&
                <>
                  &nbsp;&nbsp;
                  <ExportExcel
                    id="excel-preordenes-recetas"
                    columns={[
                      { label: 'Receta', key: '' },
                      { label: 'Ingrediente', key: 'name' },
                      { label: 'Unidad', key: 'unit' },
                      { label: 'Cantidad', key: 'amount' },
                      { label: 'Check', key: '' },
                    ]}
                    buttonText="Reporte por Receta"
                    csvDatas={preorderRecipesPrintDetails}
                    fileName="Guaitil_PreordenesRecetas"
                  />
                </>
              }
            </Card.Body>
          </Card>
          }
        </Col>
      </Row>
    </>
  );
};

export default PreorderSelection;
