import { useAuth } from 'context/auth/AuthContext';
import { UserContext } from 'context/users/UserState';
import { Formik } from 'formik';
import { IUser } from 'interfaces';
import PurchaseProductRow from 'modules/sales/purchases/PurchaseProductRow';
import { useState, useEffect, useContext, useRef } from 'react';
import { Button, Card, Table, Container, Row, Col, Modal, Accordion, Form, ListGroup } from "react-bootstrap";
import * as Yup from 'yup';

export const userRoles = [
  'administrador',
  'academico_principal',
  'academico_secundario',
  'consulta',
  'contabilidad'
];

const schema = {
  name: Yup.string().required('Requerido'),
  userName: Yup.string().required('Requerido'),
  role: Yup.string().required('Requerido'),
  password: Yup.string().required('Requerido'),
  confirmPassword: Yup.string().required('Requerido').oneOf([Yup.ref("password"), null], "Contraseñas deben coincidir")
};

const UserForm = (props: { show: boolean, user: IUser, close: Function }) => {
  const { user: loggedUser } = useAuth();
  const { addUser, editUser } = useContext(UserContext);
  const { show, user, close } = props;
  const [editingUser, setEditingSelected] = useState<any>({});
  const [tempPass, setTempPass] = useState<string>('');
  const validationSchema = useRef<any>({});

  useEffect(() => { 
    setEditingSelected(user || {});
    const {password, confirmPassword, ...rest } = schema;
    validationSchema.current = Yup.object().shape(user._id === loggedUser._id ? { ...schema } : { ...rest });
  }, [user, loggedUser]);

  const checkUser = (values: any) => {
    // const { password, confirmPassword, ...resto } = values; 
    return user._id ? { ...values, userName: values.userName.trim(), _id: user._id } : { ...values };
  }
  
  const saveUser = (values: any) => {
    const savingUser = checkUser(values);
    savingUser._id ? editUser(savingUser) : addUser({ ...savingUser, password: tempPass });
    close();
  }

  const generateTempPass = () => {
    if (user._id) {
      const result = window.confirm(`Esto generará una contraseña temporal para el usuario, ¿Estás seguro de realizar esta acción?`);
      if (result) {
        const temPassword = Math.random().toString(36).slice(-8);
        setTempPass(temPassword);
        editUser({ _id: user._id, password: temPassword });
      }
    } else {
      setTempPass(Math.random().toString(36).slice(-8));
    }
  }

  console.log(user._id, loggedUser.name, loggedUser.role);
  return (
    <Modal show={show} onHide={close} centered>
      <Modal.Header closeButton>
        <div>
          <h3>{editingUser.userName ? 'Editar' : 'Nuevo'} Usuario</h3>
        </div>
      </Modal.Header>
      <Modal.Body>
        <Formik
          validateOnChange={false}
          validateOnBlur={false}
          validationSchema={validationSchema.current}
          onSubmit={saveUser}
          initialValues={{
            userName: user.userName,
            name: user.name,
            lastName: user.lastName,
            phoneNumber: user.phoneNumber,
            email: user.email,
            state: user.state,
            role: user.role,
            password: user.password,
            confirmPassword: user.password,
          }}>
          {({
            handleSubmit,
            handleChange,
            handleBlur,
            values,
            touched,
            isValid,
            errors,
          }) => (
          <Form id="user-form" noValidate onSubmit={(e)=> { e.preventDefault(); handleSubmit(); }}>
            <fieldset className="row pb-3" disabled={(user._id && user._id !== loggedUser._id) && loggedUser.role !== 'administrador'}>
              <Col md="6">
                <Form.Group className="mb-3">
                  <label>Nombre</label>
                  <Form.Control
                    name="name"
                    tabIndex = {1}
                    value={values.name || ''}
                    type="text"
                    placeholder="Nombre"
                    isInvalid={!!errors.name}
                    onChange={handleChange}/>
                  <Form.Control.Feedback type="invalid">
                    {errors.name}
                </Form.Control.Feedback>
                </Form.Group>
               <Form.Group className="mb-3">
                  <label>Teléfono</label>
                  <Form.Control
                    name="phoneNumber"
                    tabIndex = {3}
                    value={values.phoneNumber || ''}
                    type="text"
                    placeholder="Teléfono"
                    isInvalid={!!errors.phoneNumber}
                    onChange={handleChange}/>
                </Form.Group>
                <Form.Group className="mb-3">
                  <label>Usuario</label>
                  <Form.Control
                    name="userName"
                    tabIndex = {5}
                    value={values.userName || ''}
                    type="text"
                    placeholder="Usuario"
                    isInvalid={!!errors.userName}
                    onChange={handleChange}/>
                  <Form.Control.Feedback type="invalid">
                    {errors.userName}
                  </Form.Control.Feedback>
                </Form.Group>
                { 
                (user._id === loggedUser._id) &&
                <>
                <Form.Group className="mb-3">
                  <label>Contraseña</label>
                  <Form.Control
                    name="password"
                    tabIndex = {7}
                    value={values.password || ''}
                    type="password"
                    placeholder="Contraseña"
                    isInvalid={!!errors.password}
                    onChange={handleChange}/>
                  <Form.Control.Feedback type="invalid">
                    {errors.password}
                  </Form.Control.Feedback>
                </Form.Group>
                </>
                }
              </Col>
              
              <Col md="6">
              <Form.Group className="mb-3">
                  <label>Apellidos</label>
                  <Form.Control
                    name="lastName"
                    tabIndex = {2}
                    value={values.lastName || ''}
                    type="text"
                    placeholder="Apellidos"
                    isInvalid={!!errors.lastName}
                    onChange={handleChange}/>
                </Form.Group>
                <Form.Group className="mb-3">
                  <label>Correo Electrónico</label>
                  <Form.Control
                    name="email"
                    tabIndex = {4}
                    value={values.email || ''}
                    type="text"
                    placeholder="Correo Electrónico"
                    isInvalid={!!errors.email}
                    onChange={handleChange}/>
                </Form.Group>
                <Form.Group className="mb-3">
                  <label>Rol</label>
                  <Form.Select aria-label="Rol"
                    name="role"
                    tabIndex = {6}
                    value={values.role}
                    isInvalid={!!errors.role}
                    onChange={handleChange}
                    disabled={loggedUser.role !== 'administrador'}>
                    <option>Seleccione un Rol</option>
                    {userRoles.map(item => <option key={`unity-o-${item}`} value={item}>{item.charAt(0).toUpperCase() + item.slice(1)}</option>)}
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                      {errors.role}
                  </Form.Control.Feedback>
                </Form.Group>
                { 
                (user._id === loggedUser._id) &&
                <>
                <Form.Group className="mb-3">
                  <label>Confirmar Contraseña</label>
                  <Form.Control
                    name="confirmPassword"
                    tabIndex = {8}
                    value={values.confirmPassword || ''}
                    type="password"
                    placeholder="Confirmar Contraseña"
                    isInvalid={!!errors.confirmPassword}
                    onChange={handleChange}/>
                    <Form.Control.Feedback type="invalid">
                      {errors.confirmPassword}
                  </Form.Control.Feedback>
                </Form.Group>
                </>
                }
              </Col>  
            </fieldset>
            <Row>
              <Col> 
               {
                (loggedUser.role === 'administrador' && user._id !== loggedUser._id) &&
                <>
                  <Button variant='info' onClick={generateTempPass}>Generar Contraseña temporal</Button><br/><br/>
                  {!!tempPass &&
                  <>
                    Contraseña temporal: <span className='text-info'>{tempPass}</span>
                    <Button className="text-info" title="copiar" variant="outline-light" size="sm" onClick={()=>{ navigator.clipboard.writeText(tempPass) }}><i className="fas fa-copy"></i></Button>
                  </>
                  }
                </>
                }
              </Col>  
            </Row>
          </Form>
        )}
        </Formik>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="danger" onClick={()=>close()}>Cancelar</Button>
        <Button variant="primary" type="submit" form="user-form">Guardar</Button>
      </Modal.Footer>
    </Modal>
  )
}

export default UserForm;