import { IUser } from 'interfaces';
import React, { createContext, useEffect, useReducer } from 'react';
import axios from "axios";
import userReducer from './UserReducer';
import { useErrorStatus } from 'context/ErrorProvider';

export interface IState {
  users: IUser[];
  addUser?: any;
  editUser?: any;
  removeUser?: any;
}

const initialState: IState = {
  users: []
};

export const UserContext = createContext(initialState);

export const UserProvider = ({ children }: { children: any }) => {
  const { setErrorStatusCode }: any = useErrorStatus();
  const [state, dispatch] = useReducer(userReducer, initialState);

  const getUsers = async () => {
    
    try {
      const res = await axios.get("/api/users");
      if (res){
        dispatch({
          type: "LOAD_USERS",
          payload: res.data
        });
      } else {
  
      }
    } catch (error: any) {
      if (error.response.status) {
        setErrorStatusCode(error.response.status)
      }
      // throw(error);
    }
  }

  useEffect(()=> {
    getUsers();
  },[])

  const addUser = async (user: IUser) => {
    const res = await axios.post("/api/users", user);
    if (res){  
      dispatch({
        type: "ADD_USER",
        payload: res.data
      });
    }
  }

  const editUser = async (user: IUser) => {
    const res = await axios.put(`/api/users/${user._id}`, user);
    if (res){  
      dispatch({
        type: "EDIT_USER",
        payload: res.data
      });
    }
  }

  const removeUser = async (id: number) => {
    try {
      const res = await axios.delete(`/api/users/${id}`);
      if (res){      
        dispatch({
          type: "REMOVE_USER",
          payload: id
        });
      }
    } catch (error: any) {
      if (error.response.status) {
        setErrorStatusCode(error.response.status)
      }
    }
  }


  return (
    <UserContext.Provider
      value={{
        users: state.users,
        addUser,
        editUser,
        removeUser
      }}
    >
      {children}
    </UserContext.Provider>
  );
};