import { useState, useEffect, useContext } from 'react';
import { CatalogContext } from 'context/catalogs/CatalogState';
import { Button, Card, Table, Container, Row, Col, Modal, Accordion, Form, ListGroup } from "react-bootstrap";
import { Formik } from 'formik';
import { ProductContext } from 'context/products/ProductState';
import { IProduct } from 'interfaces';
import { unites } from 'utils/constants';
import * as Yup from 'yup';

const schema = Yup.object().shape({
  name: Yup.string().required('Requerido'),
  description: Yup.string(),
  unitOfMeasure: Yup.string().required('Requerido'),
  ivaApply: Yup.string().required('Requerido')
});

const ProductForm = (props: { show: boolean, product: IProduct, close: Function }) => {
  const { addProduct, editProduct } = useContext(ProductContext);
  const { show, product, close } = props;
  const [editingProduct, setEditingSelected] = useState<any>({});
  const { catalogs } = useContext(CatalogContext);
  const [ivaList, setIvaList] = useState<any>([]);

  useEffect(() => { 
    setEditingSelected(product || {});
    if (catalogs) { 
      if (catalogs['impuestos']) { setIvaList(catalogs['impuestos']); }
    }
  }, [catalogs,product]);

  // const handleChange = (e:any) => {
  //   const {name, value}=e.target;
  //   setEditingSelected((prevState: any)=>({
  //     ...prevState,
  //     [name]: value
  //   }));
  // }

  const checkProduct= (values: any) => {
    return { ...values, _id: product._id }
  }
  
  const saveProduct = (values: any) => {
    const savingProduct = checkProduct(values);
    savingProduct._id ? editProduct(savingProduct) : addProduct(savingProduct);
    close();
  }



  return (
    <Modal show={show} onHide={close} entered>
      <Modal.Header closeButton>
        <Modal.Title>{editingProduct.productCode ? 'Editar' : 'Nuevo'} Producto</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Formik
          validateOnChange={false}
          validateOnBlur={false}
          validationSchema={schema}
          onSubmit={saveProduct}
          initialValues={{
            name: product.name,
            description: product.description,
            unitOfMeasure: product.unitOfMeasure,
            ivaApply: product.ivaApply?._id,
            state: 'active'
          }}>
          {({
            handleSubmit,
            handleChange,
            handleBlur,
            values,
            touched,
            isValid,
            errors,
            setFieldValue
          }) => (
          <Form id="product-form" noValidate onSubmit={(e)=> { e.preventDefault(); handleSubmit(); }}>
            <Row className="pb-3 justify-content-md-center">
              <Col md="8">
                <Form.Group className="mb-3">
                  <label>Nombre</label>
                  <Form.Control
                    name="name"
                    value={values.name || ''}
                    type="text"
                    placeholder="Nombre"
                    isInvalid={!!errors.name}
                    onChange={handleChange}/>
                </Form.Group>
                <Form.Group className="mb-3">
                  <label>Descripción</label>
                  <Form.Control
                    name="description"
                    value={values.description || ''}
                    placeholder="Descripción"
                    rows={2}
                    isInvalid={!!errors.description}
                    onChange={handleChange}
                    as="textarea"/>
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Unidad de Medida</Form.Label>
                  <Form.Select aria-label="Seleccione una unidad"
                    name="unitOfMeasure"
                    value={values.unitOfMeasure}
                    isInvalid={!!errors.unitOfMeasure}
                    onChange={handleChange}>
                    <option></option>
                    {unites.map(item => <option key={`unity-o-${item}`} value={item}>{item}</option>)}
                  </Form.Select>
                </Form.Group>
                <Form.Group>
                <Form.Label>Impuesto Aplicado</Form.Label>
                <Form.Select aria-label="Impuesto"
                    name="ivaApply"
                    value={values.ivaApply}
                    isInvalid={!!errors.ivaApply}
                    onChange={handleChange}>
                    <option></option>
                    {ivaList.map((a: any) => <option key={`a-type-o-${a._id}`} value={a._id}>{a.name} {`${a.acronym > 0 ? `- ${a.acronym}% ` : ' '}`}</option>)}
                  </Form.Select>
                </Form.Group>
              </Col>
            </Row>
          </Form>
        )}
        </Formik>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="danger" onClick={()=>close()}>Cancelar</Button>
        <Button variant="primary" type="submit" form="product-form">Guardar</Button>
      </Modal.Footer>
    </Modal>
  )
}

export default ProductForm;


