import { Typeahead } from 'react-bootstrap-typeahead';
import { ProductContext } from 'context/products/ProductState';
import { useContext, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { IProduct } from 'interfaces';

const IngredientRow = ({ ingredient, onChange, onRemove, onAddProduct }: any) => {
  const { products } = useContext(ProductContext);
  const [ editingIngredient, setEditingIngredient ] = useState<any>(ingredient);
  const firstUpdate = useRef(true);
  let changed = false;
  let selected = null

  useEffect(() => { 
    setEditingIngredient(ingredient || {});
  }, [ingredient]);

  useLayoutEffect(() => { 
    if(firstUpdate.current) { firstUpdate.current = false; return; }
    onChange(editingIngredient);
  }, [editingIngredient]);

  // const changeProduct = (i: any) => {
  //   console.log(i);
  // }

  const handleProductChange = (selected: any[]) => {
    const [option] = selected;
    if (option && option.customOption) {
      onAddProduct(option.label);
      return;
    }
    changed = true;
    setEditingIngredient({ ...editingIngredient, product: selected.length ? option : '' });
    // onChange(editingCommodity);
  }

  const handleChange = (e:any) => {
    const {name, value}=e.target;
    changed = true;
    setEditingIngredient((prevState: any)=>({
      ...prevState,
      [name]: value
    }));
  }

  if (products && !products.length) {
    return null;
  } else if (ingredient.product) {
    selected = products.find(p => ingredient.product._id == p._id) || {};
  }

  return <tr>
    <td>
      <Typeahead
        allowNew
        clearButton
        id={`commodity-${ingredient._id}`}
        labelKey={(o) => `${o.name}${o.unitOfMeasure ? ' en ' + o.unitOfMeasure : ''}`}
        options={products}
        placeholder="Elige un producto..."
        newSelectionPrefix="Producto no existe aún. Agregar: "
        onChange={handleProductChange}
        selected={editingIngredient.product ? [editingIngredient.product] : []}
        defaultSelected={[selected]}
        maxHeight={200}
        positionFixed={true}
      />
    </td>
    <td className="col-3">
      <Form.Control
        name="quantity"
        type="number"
        step=".01"
        min="0"
        max="999"
        value={editingIngredient.quantity || ''}
        onChange={handleChange}
      ></Form.Control>
    </td>
    <td>
      <Button className="text-danger" title="Eliminar" variant="outline-light" size="sm" onClick={onRemove}><i className="fas fa-trash-alt"></i></Button>
    </td>
  </tr>;
}

export default IngredientRow;