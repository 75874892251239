import { IPreorder } from "interfaces";
import { IState } from "./PreorderState";

export default function preorderReducer(state: IState, action: { type: string; payload: any; }) {
  switch (action.type) {
    case "LOADING_PREORDERS":
      return {
        ...state,
        loading: true,
      };
    case "LOAD_PREORDERS":
      return {
        ...state,
        loading: false,
        preorders: action.payload,
      };

    case "ADD_PREORDER":
      return {
        ...state,
        preorders: [action.payload, ...state.preorders],
      };

    case "EDIT_PREORDER":
      const updatedPreorder = action.payload;

      const updatedPreorders = state.preorders.map((preorder: IPreorder) => {
        if (preorder._id === updatedPreorder._id) {
          return updatedPreorder;
        }
        return preorder;
      });

      return {
        ...state,
        preorders: updatedPreorders,
      };

    case "REMOVE_PREORDER":
      return {
        ...state,
        preorders: state.preorders.filter(
          (preorder: IPreorder) => preorder._id !== action.payload
        ),
      };

    default:
      return state;
  }
};