import { Typeahead } from 'react-bootstrap-typeahead';
import { ProductContext } from 'context/products/ProductState';
import { SupplierContext } from 'context/suppliers/SupplierState';
import { useContext, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { Link } from "react-router-dom";
import { IProduct } from 'interfaces';
import { useAuth } from 'context/auth/AuthContext';
import { round } from 'utils/utilities';


const PreorderProductRow = ({ ingredient, prioritySupplier, isNew, exclude, onChange, onRemove }: any) => {
  const { products } = useContext(ProductContext);
  const { suppliers } = useContext(SupplierContext);
  const [ editingIngredient, setEditingIngredient ] = useState<any>(ingredient);
  const [ productOptions, setProductOptions ] = useState<any>([]);
  const [ supplierOptions, setSupplierOptions ] = useState<any>([]);
  const [ selected, setSelected ] = useState<any>([]);
  const [ changed, setChanged ] = useState<boolean>(false);
  const firstUpdate = useRef(true);
  const { matchRole } = useAuth();


  useEffect(() => {
    if (!editingIngredient) {
      setEditingIngredient(ingredient || {});
    }
    chooseSupplierOptions(editingIngredient);
  }, [ingredient, isNew, prioritySupplier]);

  useLayoutEffect(() => { 
    if(firstUpdate.current) { firstUpdate.current = false; return; }
    onChange(editingIngredient.id, { ...editingIngredient, totalPrice: (selected.length && editingIngredient.quantity ? editingIngredient.quantity * selected[0].unitPrice : 0) });
  }, [editingIngredient]);

  useEffect(() => {
    if (isNew && exclude && exclude.length) {
      setProductOptions(products.filter(p => !exclude.includes(p.name.toLowerCase())))
    } else if (!productOptions.length){
      setProductOptions(products)
    }
  }, [products, exclude]);

  const chooseSupplierOptions = (i: any) => {
    let defautlSelected: any = null;
    if (suppliers && suppliers.length) {
      const matchSupplierProducts: any[] = [];
      suppliers.forEach(s => {
        s.products.forEach(p => {
          if (i.product && i.product.name && p.product && p.product.name.toLowerCase() === i.product.name.toLowerCase()) {
            const supplierOption = {
              id: p.product._id,
              supplier: s,
              unit: p.product.unitOfMeasure,
              unitPrice: p.price,
              product: p.product,
              presentation: p.presentation
            };
            matchSupplierProducts.push(supplierOption);
            if (p.product._id === i.product._id && (!defautlSelected || prioritySupplier == s._id)) {
              defautlSelected = supplierOption;
            }
          }
        });
      });
      if (!defautlSelected && matchSupplierProducts[0]) { defautlSelected = matchSupplierProducts[0]; }
      setSelected(defautlSelected ? [defautlSelected] : []);
      setSupplierOptions(matchSupplierProducts);
      let newEditingIngredientState = { supplier: '', quantity: 0, presentation: 1, totalPrice: 0, unitPrice: 0 };
      if (defautlSelected) {
        const quantity_calc = (editingIngredient.quantity || 0) / defautlSelected.presentation,
          quantity = Math.ceil(quantity_calc);
        newEditingIngredientState = {
          supplier: defautlSelected.supplier._id,
          quantity,
          presentation: defautlSelected.presentation,
          totalPrice: quantity * defautlSelected.unitPrice,
          unitPrice: defautlSelected.unitPrice
        };
      }
      setEditingIngredient({ ...editingIngredient, ...i, ...newEditingIngredientState });
    }
  }

  const handleSupplierOptionChange = (selected: any[]) => {
    setSelected(selected);
    let newIngredientState = { ...editingIngredient }
    if (selected[0]) {
      if (selected[0].unit !== editingIngredient.product.unitOfMeasure) { setChanged(true); }
      newIngredientState = { ...selected[0] }
      newIngredientState.totalPrice = 0;
      newIngredientState.required = editingIngredient.required;
      newIngredientState.recipes = editingIngredient.recipes;
      newIngredientState.recipesObjects = editingIngredient.recipesObjects;
      // newIngredientState.product = selected[0].product;
      newIngredientState.supplier = selected[0].supplier._id;
    } else {
      newIngredientState.quantity = 0;
      newIngredientState.totalPrice = 0;
    }
    setEditingIngredient(newIngredientState);
  }

  const handleProductChange = (selected: any[]) => {
    let newIngredient: any = { ...editingIngredient, quantity: 0, totalPrice: 0 };
    // setEditingIngredient(newIngredient);
    if (!selected[0]) {
      newIngredient.product = {};
      setEditingIngredient(newIngredient);
      setSelected([]);
      return;
    }
    newIngredient.product = selected[0];
    chooseSupplierOptions(newIngredient);
  }

  const handleQuantityChange = (e:any) => {
    const {value}=e.target;
    setChanged(true);
    setEditingIngredient((prevState: any)=>({
      ...prevState,
      quantity: Number(value),
      totalPrice: (selected.length ? round((value || 0 ) * selected[0].unitPrice, 2) : 0)
    }));
  }

  if (products && !products.length) {
    return null;
  }

  return <tr>
    <td className="text-capitalize text-start">
      { isNew
        ? <Typeahead
            clearButton
            id={`product-preorder-${ingredient._id}`}
            labelKey={(o) => `${o.name}${o.unitOfMeasure ? ' en ' + o.unitOfMeasure : ''}`}
            options={productOptions}
            placeholder="Products..."
            newSelectionPrefix="Producto no existe aún. Agregar: "
            onChange={handleProductChange}
            selected={editingIngredient.product && editingIngredient.product._id ? [editingIngredient.product] : []}
            defaultSelected={[]}
          />
        : <>
            {ingredient.product.name} <br/>
            {!!ingredient.recipesObjects.length && <small className="text-muted">{ingredient.recipesObjects.map((r: any) => (<div>{`${r.name}, ${r.amount}${ingredient.product.unitOfMeasure}`}</div>))}</small>}
          </>
      }
    </td>
    <td>
      { supplierOptions.length
        ?
        <Typeahead
            clearButton
            id={`supplier-options-${ingredient._id}`}
            labelKey={(o) => `${o.supplier.name} - ₡${o.unitPrice} por ${o.presentation}${o.unit}`}
            options={supplierOptions}
            placeholder="Opciones..."
            newSelectionPrefix="Producto no existe aún. Agregar: "
            onChange={handleSupplierOptionChange}
            selected={selected}
            defaultSelected={[]}
          />
        : <small className="text-warning">
            {editingIngredient.product && editingIngredient.product._id
            ? <>Debe incluir el producto en alguno de nuestros <Link to="/admin/administrativo/proveedores">proveedores</Link></>
            : <>Selecciona un producto</>}
          </small>
      }
    </td>
    <td>
      <Form.Control
        disabled={!selected.length}
        name="quantity"
        type="number"
        min="0"
        max="999"
        value={editingIngredient.quantity || 0}
        onChange={handleQuantityChange}
      ></Form.Control>
    </td>
    <td>
      {!!editingIngredient.quantity &&
        <><small className="text-info">a comprar: {editingIngredient.quantity * editingIngredient.presentation} {editingIngredient.product.unitOfMeasure}</small><br /></>
      }
      {!!ingredient.quantity &&
        <small className="text-info">requerido: {ingredient.quantity} {ingredient.product.unitOfMeasure}</small>
      }
    </td>
    <td>
      { `₡${round(editingIngredient.totalPrice, 2)}` }
    </td>
    {matchRole(['administrativo','academico_principal']) && 
      <td className="table-secondary px-2">
        <Button className="text-danger" title="Eliminar" variant="outline-light" size="sm" onClick={onRemove}><i className="fas fa-trash-alt"></i></Button>
      </td>
    }
  </tr>;
}

export default PreorderProductRow;