import { Typeahead } from 'react-bootstrap-typeahead';
import { RecipeContext } from 'context/recipes/RecipeState';
import CustomDatePicker from 'components/CustomDatePicker';
import DatePicker from "react-datepicker";
import { useContext, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { IRecipe } from 'interfaces';

const RecipeRow = ({ recipe: menuRecipe, onChange, onRemove }: any) => {
  const { recipes } = useContext(RecipeContext);
  const [ editingMenuRecipe, setEditingMenuRecipe ] = useState<any>(menuRecipe);
  const firstUpdate = useRef(true);
  let changed = false;
  let selected = null

  useEffect(() => { 
    setEditingMenuRecipe(menuRecipe || {});
  }, [menuRecipe]);

  useLayoutEffect(() => { 
    if(firstUpdate.current) { firstUpdate.current = false; return; }
    onChange(editingMenuRecipe);
  }, [editingMenuRecipe]);

  // const changeRecipe = (i: any) => {
  //   console.log(i);
  // }

  const handleRecipeChange = (selected: any[]) => {
    changed = true;
    setEditingMenuRecipe({ ...editingMenuRecipe, recipe: selected.length ? selected[0] : '' });
    // onChange(editingMenuRecipe);
  }

  const handleChange = (e:any) => {
    const {name, value}=e.target;
    changed = true;
    setEditingMenuRecipe((prevState: any)=>({
      ...prevState,
      [name]: value
    }));
  }

  if (recipes && !recipes.length) {
    return null;
  } else if (menuRecipe.recipe) {
    selected = recipes.find(p => menuRecipe.recipe._id == p._id) || {};
  }

  return <tr>
    <td>
      <Typeahead
        clearButton
        // paginate
        id={`menuRecipe-${menuRecipe._id}`}
        labelKey={(o) => `${o.name}`}
        options={recipes}
        placeholder="Elige una receta..."
        onChange={handleRecipeChange}
        selected={editingMenuRecipe.recipe ? [editingMenuRecipe.recipe] : []}
        defaultSelected={[selected]}
      />
    </td>
    <td>
      <DatePicker
        customInput={<Form.Control type="text"/>}
        selected={(editingMenuRecipe.date && new Date(editingMenuRecipe.date)) || null}
        dateFormat="dd/MM/yyyy"
        onChange={(value) => { handleChange({ target: {name: 'date', value } }) }}
      />
    </td>
    <td>
      <Form.Control
        name="servings"
        type="number"
        min="0"
        max="500"
        value={editingMenuRecipe.servings || ''}
        onChange={handleChange}
      ></Form.Control>
    </td>
    <td>
      <Form.Control
        name="session"
        type="number"
        min="0"
        max="500"
        value={editingMenuRecipe.session || ''}
        onChange={handleChange}
      ></Form.Control>
    </td>
    <td>
      <Form.Control
        name="teacher"
        type="string"
        min="0"
        max="500"
        value={editingMenuRecipe.teacher || ''}
        onChange={handleChange}
      ></Form.Control>
    </td>
    <td>
      <Button className="text-danger" title="Eliminar" variant="outline-light" size="sm" onClick={onRemove}><i className="fas fa-trash-alt"></i></Button>
    </td>
  </tr>;
}

export default RecipeRow;