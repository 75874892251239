import { ISupplier } from "interfaces";
import { IState } from "./SupplierState";

export default function supplierReducer(state: IState, action: { type: string; payload: any; }) {
  switch (action.type) {
    case "LOADING_Suppliers":
      return {
        ...state,
        loading: true,
      };
    case "LOAD_Suppliers":
      return {
        ...state,
        suppliers: action.payload,
        loading: false,
      };

    case "ADD_Supplier":
      return {
        ...state,
        suppliers: [action.payload, ...state.suppliers],
      };

    case "EDIT_Supplier":
      const updatedSupplier = action.payload;

      const updatedSuppliers = state.suppliers.map((supplier: ISupplier) => {
        if (supplier._id === updatedSupplier._id) {
          return updatedSupplier;
        }
        return supplier;
      });

      return {
        ...state,
        suppliers: updatedSuppliers,
      };

    case "REMOVE_Supplier":
      return {
        ...state,
        suppliers: state.suppliers.filter(
          (supplier: ISupplier) => supplier._id !== action.payload
        ),
      };

    default:
      return state;
  }
};