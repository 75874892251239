import { useAuth } from './context/auth/AuthContext';
import { Route, Redirect, RouteComponentProps, Switch } from 'react-router-dom';
import  { unauthenticatedRoutes }  from './config/unauthenticated.routes';
import AdminLayout from "./layouts/Admin";
import NotFound from './views/NotFound';
// import Landing from 'views/Landing';
import AppContextProvider from 'context/GlobalProvider';
import Loading from 'views/Loading';

import './App.css';

function App() {
  const  { user, isFetching }  = useAuth();
  if (isFetching) {
    return <Loading logo/>;
  }
  return (
    <>
      { user
      ?
        <AppContextProvider>
          <Switch>
            <Route path="/" exact>
              <Redirect to="/admin"/>
            </Route>
            <Route path="/admin" render={(props: any) => <AdminLayout {...props} />} />
            <Route component={NotFound}/>
          </Switch>
        </AppContextProvider>
      : <Switch>
          {/* <Route exact path="/" component={Landing}/> */}
          {unauthenticatedRoutes.map((route, index) => (
            <Route
              key={index} 
              path={route.path}
              exact={route.exact}
              render={(props: RouteComponentProps<any>) => (
                <route.component
                  name={route.name}
                  {...props}
                  {...route.props}
                />
              )}/>
          ))}
          <Redirect to="/login" />;
          <Route component={NotFound}/>
        </Switch> }
    </>
  );
}

export default App;