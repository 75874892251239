import { ICatalog } from 'interfaces';
import React, { createContext, useEffect, useReducer } from 'react';
import axios from "axios";
import catalogReducer from './CatalogReducer';
import { useErrorStatus } from 'context/ErrorProvider';

export interface IState {
  catalogs: { [key: string]: ICatalog[]; }
  getCatalogsByCode?: any;
  // catalogs: ICatalog[];
  addCatalog?: any;
  editCatalog?: any;
  removeCatalog?: any;
}

const initialState: IState = {
  catalogs: {}
};

export const CatalogContext = createContext(initialState);

export const CatalogProvider = ({ children }: { children: any }) => {
  const { setErrorStatusCode }: any = useErrorStatus();
  const [state, dispatch] = useReducer(catalogReducer, initialState);

  // const getCatalogs = async () => {
    
  //   try {
  //     const res = await axios.get("/api/catalogs");
  //     if (res){
  //       dispatch({
  //         type: "LOAD_CATALOGS",
  //         payload: res.data
  //       });
  //     } else {
  
  //     }
  //   } catch (error) {
  //     // dispatch({ type: "LOGIN_FAILURE" });
  //     if (error.response.status) {
  //       setErrorStatusCode(error.response.status)
  //     }
  //     // throw(error);
  //   }
  // }

  const getCatalogs = async () => {
    const res = await axios.get("/api/catalogs");
    if (res){
      dispatch({
        type: "LOAD_CATALOGS",
        payload: res.data
      });
    }
  }

  const getCatalogsByCode = async (code: string) => {
    const res = await axios.get(`/api/catalogs/code/${code}`);
    if (res){
      dispatch({
        type: "LOAD_CATALOGS_BY_CODE",
        payload: res.data
      });
    }
  }

  useEffect(()=> {
    getCatalogs();
  },[])

  const addCatalog = async (catalog: ICatalog) => {
    const res = await axios.post("/api/catalogs", catalog);
    if (res){  
      dispatch({
        type: "ADD_CATALOG",
        payload: { catalogCode: catalog.code, catalog: res.data }
      });
    }
  }

  const editCatalog = async (catalog: ICatalog) => {
    const res = await axios.put(`/api/catalogs/${catalog._id}`, catalog);
    if (res){  
      dispatch({
        type: "EDIT_CATALOG",
        payload: { catalogCode: catalog.code, catalog: res.data }
      });
    }
  }

  const removeCatalog = async (id: number, catalogCode: string) => {
    const res = await axios.delete(`/api/catalogs/${id}`);
    if (res){      
      dispatch({
        type: "REMOVE_CATALOG",
        payload: { catalogCode, catalog: id }
      });
    }
  }


  return (
    <CatalogContext.Provider
      value={{
        catalogs: state.catalogs,
        addCatalog,
        editCatalog,
        removeCatalog
      }}
    >
      {children}
    </CatalogContext.Provider>
  );
};