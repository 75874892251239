import { Context, FC } from 'react';
import { UserProvider } from './users/UserState';
import { CatalogProvider } from './catalogs/CatalogState';
import { ProductProvider } from './products/ProductState';
import { RecipeProvider } from './recipes/RecipeState';
import { combineComponents } from 'utils/combineComponents';
import { ActivityProvider } from './activities/ActivityState';
import { SupplierProvider } from './suppliers/SupplierState';
import { PurchaseProvider } from './purchases/PurchaseState';
import { PreorderProvider } from './preorders/PreorderState';
import { OrderProvider } from './orders/OrderState';


const providers = [
    UserProvider,
    CatalogProvider,
    ProductProvider,
    RecipeProvider,
    ActivityProvider,
    SupplierProvider,
    PurchaseProvider,
    PreorderProvider,
    OrderProvider
]
const AppContextProvider = combineComponents(...providers);
export default AppContextProvider;