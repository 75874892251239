import { IProduct } from "interfaces";

const round = (value: number, precision: number = 0) => {
    var multiplier = Math.pow(10, precision);
    return Math.round(value * multiplier) / multiplier;
}

const calculateIva = (product: IProduct, unitPrice: number, quantity: number, totalPrice: number) => {
    if (!product.ivaApply) { return totalPrice; }
    if (!product.ivaApply.acronym) { return totalPrice; }
    return  round((unitPrice * (parseInt(product.ivaApply.acronym) / 100) + unitPrice) * quantity, 2);
}

export { round, calculateIva };