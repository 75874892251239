
import { useState, useContext, useEffect } from 'react';
import { Button, Card, Table, Container, Row, Col, Modal, Accordion, Form, ListGroup, InputGroup, FormControl } from "react-bootstrap";
import { UserContext } from 'context/users/UserState';
import UserForm from './UserForm';
import { IUser } from 'interfaces';
import { useAuth } from 'context/auth/AuthContext';


const UserList = () => {
  const { users, addUser, editUser, removeUser } = useContext(UserContext);
  const [data, setData] = useState<IUser[]>([]);
  const [modalEdit, setModalEdit] = useState(false);
  const [modalEliminar, setModalEliminar] = useState(false);

  const [userSelected, setUserSelected] = useState<any>(null);
  const [userRole, setUserRole] = useState<any>('roles');
  const { matchRole } = useAuth();
  useEffect(() => {
    setData(users);
  }, [users]);

  const filterList = (event: any) => {
		let updatedList = [...users];
		updatedList = updatedList.filter(({ name, userName, lastName  }) => {
      return `${name}${lastName}${userName}`.toLowerCase().search(event.target.value.toLowerCase()) !== -1;
    });
    setData(updatedList);
	}

  const selectUser= (model: any)=>{
    setUserSelected(model);
    setModalEdit(true);
  }

  const eliminar =()=>{
    removeUser(userSelected._id, userSelected.role);
    setModalEliminar(false);
    setUserSelected(null);
  }

  const closeModal = () => {
    setModalEdit(false);
    setUserSelected(null);
  }

  return (
    <>
      <Col>
        <Row>
          <Col md="6">
            <h4>Lista de usuarios agregados</h4>
            <p className="card-category">
              Puedes agregar, editar y eliminar usuarios
            </p>
          </Col>
          <Col>
          <InputGroup className="mb-3">
              <InputGroup.Text id="search-input" >
                <i className="nc-icon nc-zoom-split"></i>
              </InputGroup.Text>
              <FormControl
                placeholder="Buscar Usuario"
                aria-label="Burcar"
                aria-describedby="search-input"
                onChange={filterList}
              />
            </InputGroup>
          </Col>
          <Col>
            <Button className="float-end" disabled={!matchRole(['administrador'])} variant="outline-primary" onClick={() => selectUser({})}>Agregar Usuario <i className="fas fa-plus"></i></Button>
          </Col>
        </Row>
        <Col>
          <ListGroup>
            {data.map((model, index)=>(
              <ListGroup.Item key={`user-${model._id || index}`} eventKey={`user-${model._id || index}`}>
                  <div className="d-flex flex-grow-1 align-items-center">
                    {model.name} {model.lastName} 
                    <small className='ms-2'>- {model.role}</small>
                    <span className="ms-auto btn btn-sm btn-outline-secondary" title="Editar" onClick={(e)=> {e.stopPropagation(); selectUser(model)}}>
                      <i className="fas fa-pencil-alt"></i></span>
                    <Button className="text-danger" title="Eliminar" variant="outline-light" size="sm" onClick={()=>{ setUserSelected(model); setModalEliminar(true);}}><i className="fas fa-trash-alt"></i></Button>
                  </div>
              </ListGroup.Item>
            ))}
          </ListGroup>
        </Col>
      </Col>
      <Modal show={modalEliminar}>
        <Modal.Body>
          Estás seguro que deseas eliminar el usuario {userSelected && userSelected.userName}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={()=>eliminar()}>Sí</Button>
          <Button variant="secondary" onClick={()=>setModalEliminar(false)}>No</Button>
        </Modal.Footer>
      </Modal>

      {modalEdit && 
      <UserForm show={modalEdit}
        user={userSelected}
        close={() => closeModal()}/>
      }
    </>
  );
};

export default UserList;
