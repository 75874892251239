import { ISupplier } from 'interfaces';
import React, { createContext, useEffect, useReducer } from 'react';
import axios from "axios";
import supplierReducer from './SupplierReducer';

export interface IState {
  suppliers: ISupplier[];
  loading?: true;
  addSupplier?: any;
  editSupplier?: any;
  removeSupplier?: any;
}

const initialState: IState = {
  suppliers: []
};

export const SupplierContext = createContext(initialState);

export const SupplierProvider = ({ children }: { children: any }) => {
  const [state, dispatch] = useReducer(supplierReducer, initialState);

  const getSuppliers = async () => {
    dispatch({
      type: "LOADING_Suppliers"
    });
    const res = await axios.get("/api/suppliers");
    if (res){
      dispatch({
        type: "LOAD_Suppliers",
        payload: res.data
      });
    }
  }

  useEffect(()=> {
    getSuppliers();
  },[])

  const addSupplier = async (supplier: ISupplier) => {
    const res = await axios.post("/api/suppliers", supplier);
    if (res){  
      dispatch({
        type: "ADD_Supplier",
        payload: res.data
      });
    }
  }

  const editSupplier = async (supplier: ISupplier) => {
    const res = await axios.put(`/api/suppliers/${supplier._id}`, supplier);
    if (res){  
      dispatch({
        type: "EDIT_Supplier",
        payload: res.data
      });
    }
  }

  const removeSupplier = async (id: number) => {
    const res = await axios.delete(`/api/suppliers/${id}`);
    if (res){      
      dispatch({
        type: "REMOVE_Supplier",
        payload: id
      });
    }
  }


  return (
    <SupplierContext.Provider
      value={{
        suppliers: state.suppliers,
        loading: state.loading,
        addSupplier,
        editSupplier,
        removeSupplier
      }}
    >
      {children}
    </SupplierContext.Provider>
  );
};