
import { useState, useContext, useEffect } from 'react';
import { Button, Card, Table, Container, Row, Col, Modal, Accordion, Form, ListGroup, InputGroup, FormControl, ToggleButton } from "react-bootstrap";
import { ProductContext } from 'context/products/ProductState';
import ProductForm from './ProductForm';
import { IProduct } from 'interfaces';
import { useAuth } from 'context/auth/AuthContext';
import Loading from 'views/Loading';


const ProductList = () => {
  const { products, loading, addProduct, editProduct, removeProduct } = useContext(ProductContext);
  const { matchRole } = useAuth();

  const [data, setData] = useState(products);
  const [modalEdit, setModalEdit] = useState(false);
  const [modalEliminar, setModalEliminar] = useState(false);

  const [productSelected, setProductSelected] = useState<any>(null);

  useEffect(() => {
    setData(products);
  }, [products]);

  const filterList = (event: any) => {
		let updatedList = [...products];
		updatedList = updatedList.filter(({ name }) => {
      return name.toLowerCase().search(event.target.value.toLowerCase()) !== -1;
    });
    setData(updatedList);
	}

  const selectProduct= (model: any)=>{
    setProductSelected(model);
    setModalEdit(true);
  }

  const eliminar =()=>{
    setModalEliminar(false);
    removeProduct(productSelected);
  }

  const closeModal = () => {
    setModalEdit(false);
    setProductSelected(null);
  }

  // const edit = (productEdited: IProduct) =>{
  //   // var dataNueva=data;
  //   // dataNueva.map((product: any) =>{
  //   //   if(product.productCode === productEdited.productCode){
  //   //     product.description = productEdited.description;
  //   //     product.name = productEdited.name;
  //   //   }
  //   // });
  //   // setData(dataNueva);
  //   editProduct(productEdited);
  //   closeModal();
  // }

  // const add = (productNew: IProduct) =>{
  //   addProduct(productNew);
  //   closeModal();
  // }
  return (
    <>
      <Col>
        <Row>
          <Col md="6">
            <h4 className="mt-0">Lista de productos agregados</h4>
            <p className="card-category">
              Puedes agregar, editar y eliminar productos
            </p>
          </Col>
          <Col md="3">  
            <InputGroup className="mb-3 float-end">
              <InputGroup.Text id="search-input" >
                <i className="nc-icon nc-zoom-split"></i>
              </InputGroup.Text>
              <FormControl
                placeholder="Buscar Producto"
                aria-label="Burcar"
                aria-describedby="search-input"
                onChange={filterList}
              />
            </InputGroup>
          </Col>
          <Col>
          {!matchRole(['consulta','contabilidad']) && 
            <Button className="float-end" variant="outline-primary" onClick={() => selectProduct({})}>Agregar Producto <i className="fas fa-plus"></i></Button>
          }
          </Col>
        </Row>
        {!!loading && <Loading scale="0.5"></Loading> }
        <Accordion>
          {data.map((model, index)=>(
            <Accordion.Item key={`product-${model._id || index}`} eventKey={`product-${model._id || index}`}>
              <Accordion.Header className="mt-0">
                <div className="d-flex flex-grow-1 align-items-center pe-4">
                  {model.name} en {model.unitOfMeasure}
                  {matchRole(['administrador','academico_principal']) && 
                  <span className="ms-auto btn btn-sm btn-outline-secondary" title="Editar" onClick={(e)=> {e.stopPropagation(); selectProduct(model)}}>
                    <i className="fas fa-pencil-alt"></i></span>}
                </div>
              </Accordion.Header>
              <Accordion.Body>
                <Row>
                  <Col>
                    {model.description && <><b>Descripción</b><p>{model.description}</p></>}
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <p>
                      {model.ivaApply?.name && <><b>Impuesto Aplicado: </b>{model.ivaApply?.name} {`${model.ivaApply?.acronym > 0 ? `- ${model.ivaApply?.acronym}% ` : ' '}`}</>}
                    </p>
                  </Col>
                </Row>
                <div className="d-flex justify-content-between align-items-center">
                {matchRole(['administrador','academico_principal']) && 
                  <Button className="text-danger" title="Eliminar" variant="outline-light" size="sm" onClick={()=>{ setProductSelected(model); setModalEliminar(true);}}><i className="fas fa-trash-alt"></i></Button>
                } 
                </div>
              </Accordion.Body>
            </Accordion.Item>
          ))}
        </Accordion>
      </Col>
      <Modal show={modalEliminar}>
        <Modal.Body>
          Estás seguro que deseas eliminar la producto {productSelected && productSelected.name}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={()=>eliminar()}>Sí</Button>
          <Button variant="secondary" onClick={()=>setModalEliminar(false)}>No</Button>
        </Modal.Footer>
      </Modal>
      {modalEdit && 
      <ProductForm show={modalEdit}
        product={productSelected}
        close={() => closeModal()}/>
      }
    </>
  );
};

export default ProductList;
