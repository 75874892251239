import { useFormikContext, useField } from "formik";
import { Typeahead } from 'react-bootstrap-typeahead';
// import { Form } from "react-bootstrap";
// import DatePicker from "react-datepicker";
// import "react-datepicker/dist/react-datepicker.css";
// import "./index.css";
// import InputMask from "react-input-mask";
 
const CustomTypeahead = (props) => {
  const { setFieldValue, validateField, values, handleBlur } = useFormikContext();
  const [field] = useField(props);


  return (
    <>
      {/* <DatePicker
        {...field}
        {...props}
        customInput={<Form.Control type="text" isInvalid={props.isInvalid}/>}
        selected={(field.value && new Date(field.value)) || null}
        dateFormat="dd/MM/yyyy"
        onChange={val => {
          setFieldValue(field.name, val);
        }}
      /> */}
      <Typeahead
        {...field}
        {...props}
        onChange={val => {
          const selected = props.valueKey ? val.map(s => s[props.valueKey]) : val;
          setFieldValue(field.name, selected);
        }}
      />
    </>
  );
};
 
export default CustomTypeahead;