
import Loading from "views/Loading";
import "../assets/styles/views/loadingbox.scss";


const LoadingBox = ({ empty, emptyText, loading, loadingText }: any) => {
  if (loading) {
    return <div className="loading-box">
      <Loading/> {loadingText}
    </div>
  }
  if (empty) {
    return <div className="loading-box">
        {emptyText || 'No hay resultados'}
    </div>
  }
  return null;
}

export default LoadingBox
