import { IOrder } from 'interfaces';
import React, { createContext, useEffect, useReducer } from 'react';
import axios from "axios";
import orderReducer from './OrderReducer';
import queryString from 'query-string';


export interface IState {
  orders: IOrder[];
  foundOrders: IOrder[];
  loading?: boolean;
  addOrder?: any;
  getOrder?: any;
  editOrder?: any;
  removeOrder?: any;
  searchOrders?: any

}

const initialState: IState = {
  orders: [],
  foundOrders: [],
};

export const OrderContext = createContext(initialState);

export const OrderProvider = ({ children }: { children: any }) => {
  const [state, dispatch] = useReducer(orderReducer, initialState);

  const getOrders = async () => {
    dispatch({
      type: "LOADING_ORDERS"
    });

    const res = await axios.get("/api/orders");
    if (res){
      dispatch({
        type: "LOAD_ORDERS",
        payload: res.data
      });
    }
  }

  const getOrder = async (id: number) => {
    const res = await axios.get(`/api/orders/${id}`);
    if (res){      
      dispatch({
        type: "EDIT_ORDER",
        payload: res.data
      });
    }
  }

  useEffect(()=> {
    getOrders();
  },[])

  const addOrder = async (order: IOrder) => {
    const res = await axios.post("/api/orders", order);
    if (res){  
      dispatch({
        type: "ADD_ORDER",
        payload: res.data
      });
    }
  }

  const editOrder = async (order: IOrder) => {
    const res = await axios.put(`/api/orders/${order._id}`, order);
    if (res){  
      dispatch({
        type: "EDIT_ORDER",
        payload: res.data
      });
    }
  }

  const searchOrders = async (filters: {}) => {
    const res = await axios.get(`/api/orders/search?${queryString.stringify(filters)}`);
    if (res){
      dispatch({
        type: "FOUND_ORDERS",
        payload: res.data
      });
    }
  }

  const removeOrder = async (id: number) => {
    const res = await axios.delete(`/api/orders/${id}`);
    if (res){      
      dispatch({
        type: "REMOVE_ORDER",
        payload: id
      });
    }
  }


  return (
    <OrderContext.Provider
      value={{
        orders: state.orders,
        foundOrders: state.foundOrders,
        loading: state.loading,
        addOrder,
        editOrder,
        getOrder,
        searchOrders,
        removeOrder
      }}
    >
      {children}
    </OrderContext.Provider>
  );
};