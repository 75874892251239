import { ICatalog } from "interfaces";
import { IState } from "./CatalogState";

export default function catalogReducer(state: IState, action: { type: string; payload: any; }) {
  const { catalogCode, catalog: saveCatalog } = action.payload;

  switch (action.type) {
    case "LOAD_CATALOGS":
      return {
        ...state,
        catalogs: action.payload,
      };

    case "LOAD_CATALOGS_BY_CODE":
      return {
        ...state,
        catalogs: { ...state, ...action.payload },
      };

    case "ADD_CATALOG":
      return {
        ...state,
        catalogs: { ...state.catalogs, [catalogCode]: [saveCatalog, ...( state.catalogs[catalogCode] ? (state.catalogs[catalogCode]) : [] )] },
      };

    case "EDIT_CATALOG":
      const updatedCatalogs = state.catalogs[catalogCode].map((catalog: ICatalog) => {
        if (catalog._id === saveCatalog._id) {
          return saveCatalog;
        }
        return catalog;
      });

      return {
        ...state,
        catalogs: { ...state.catalogs, [catalogCode]: updatedCatalogs }
      };

    case "REMOVE_CATALOG":
      const updatedCatalog = state.catalogs[catalogCode].filter(
        (catalog: ICatalog) => catalog._id !== saveCatalog
      );

      return {
        ...state,
        catalogs: { ...state.catalogs, [catalogCode]: updatedCatalog }
      };

    default:
      return state;
  }
};