export const unites = [
  "cda",
  "cdita",
  "gr",
  "kg",
  "L",
  "lib",
  "ml",
  "onz",
  "pizca",
  "pulgada",
  "tz",
  "unidad",
];