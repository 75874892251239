
import { SupplierContext } from 'context/suppliers/SupplierState';
import { ISupplier } from 'interfaces';
import { useState, useContext, useRef } from 'react';
import { Button, Table, Row, Col, Modal, Form } from "react-bootstrap";
import { Formik } from 'formik';
import CommodityRow from './CommodityRow';
import * as Yup from 'yup';
import ProductForm from 'modules/administrative/ProductForm';

const schema = Yup.object().shape({
  name: Yup.string().min(2, '¡Muy corto!').max(50, '¡Muy Largo!').required('Requerido'),
  
});

const SupplierForm = (props: { show: boolean, supplier: ISupplier, close: Function  }) => {
  const { addSupplier, editSupplier } = useContext(SupplierContext);
  const { show, supplier, close } = props;
  const [ commodityRows, setcommodityRows ] = useState<any>(supplier && supplier.products && supplier.products.length ?  supplier.products : [{}]);
  const [ products, setProducts ] = useState<any>([...commodityRows]);
  const [ productsError, setProductsError ] = useState<any>('');
  const [productAdd, setProductAdd] = useState<string>('');
  const table = useRef(null);

  const setCommodityByIndex = (index: number, commodity: any) => {
    setProductsError('');
    const exists = products.some((p: any, i: any) => i !== index && p?.product?._id === commodity.product?._id);
    if (exists) {
      alert('Producto ya existe!');
      setcommodityRows(Object.assign([...commodityRows], {[index]: {} }));
      return;
    }
    setProducts(Object.assign([...products], {[index]: commodity }));
  }

  const addCommodity = () => {
    setTimeout(() => {
      table.current.scrollTo(0, table.current.scrollHeight);
    });
    if (commodityRows.length > 50) { return; }
    const last = products[products.length - 1];
    if (!last.product) { return; }
    setcommodityRows([...commodityRows, { }]);
    setProducts([...products, { }]);
  }

  const removeCommodity = (index: number) => {
      if (commodityRows.length == 1) {
        setcommodityRows([{}]);
        setProducts([{}]);
      } else {
        setcommodityRows([...removeFromArray(commodityRows, index)]);
        setProducts([...removeFromArray(products, index)]);
      }
  }

  const removeFromArray = (arr: any[], indexToRemove: number) => {
    return [...arr.slice(0, indexToRemove), ...arr.slice(indexToRemove + 1)]
  }

  const checkSupplier = (values: any) => {
    return {...values,
      _id: supplier._id,
      products: products.filter((i: any) => !!i.product).map((i: any) => ({
        product: i.product._id || i.product._id,
        price: i.price || 0,
        priceIVA: i.priceIVA || 0,
        presentation: i.presentation || 0
      }))
    }
  }
  
  const saveSupplier = (values: any) => {
    const savingSupplier = checkSupplier(values);
 /*    if (!savingSupplier.products.length) {
      setProductsError('Debes agregar productos');
    } else {
      savingSupplier._id ? editSupplier(savingSupplier) : addSupplier(savingSupplier);
      close();
    } */
    savingSupplier._id ? editSupplier(savingSupplier) : addSupplier(savingSupplier);
      close();
  }

  return (
    <>
      <Modal show={show} onHide={close} size="xl" centered>
        <Modal.Header closeButton>
          <Modal.Title>{supplier._id ? 'Edición' : 'Nuevo'} Proveedor</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Formik
          validateOnChange={false}
          validateOnBlur={false}
          validationSchema={schema}
          onSubmit={saveSupplier}
          initialValues={{
            supplierCode: supplier.supplierCode,  
            name:         supplier.name,
            contactName:  supplier.contactName,
            phoneNumber:  supplier.phoneNumber,
            email:        supplier.email,
            schedule:     supplier.schedule,
            description:  supplier.description,
            province:     supplier.province,
            canton:       supplier.canton,
            address:      supplier.address,
          }}>
          {({
            handleSubmit,
            handleChange,
            handleBlur,
            values,
            touched,
            isValid,
            errors,
          }) => (
          <Form id="supplier-form" noValidate onSubmit={(e)=> { e.preventDefault(); handleSubmit(); }} autoComplete="off">
            <Row className="m-3">
              <Col md='5'>
                <Form.Group as={Row} className="mb-3">
                  <Form.Label column sm="3">Nombre</Form.Label>
                  <Col sm="9">
                    <Form.Control
                      name="name"
                      type="text"
                      isInvalid={!!errors.name}
                      onChange={handleChange}
                      value={values.name}/>
                  </Col>
                </Form.Group>
                <Form.Group  className="mb-3">
                  <Form.Label>Descripción</Form.Label>
                  <Form.Control
                    rows={2}
                    as="textarea"
                    name="description"
                    value={values.description}
                    onChange={handleChange}/>
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Nombre de Contacto</Form.Label>
                  <Form.Control
                    name="contactName" 
                    type="text"
                    isInvalid={!!errors.contactName}
                    value={values.contactName}
                    onChange={handleChange}/>
                </Form.Group>
                <Form.Group as={Row} className="mb-3">
                  <Form.Label column sm="3">Teléfono</Form.Label>
                  <Col sm="9">
                    <Form.Control
                      name="phoneNumber"
                      type="text"
                      isInvalid={!!errors.phoneNumber}
                      onChange={handleChange}
                      value={values.phoneNumber}/>
                  </Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-3">
                  <Form.Label column sm="3">Email</Form.Label>
                  <Col sm="9">
                    <Form.Control
                      name="email"
                      type="text"
                      isInvalid={!!errors.email}
                      onChange={handleChange}
                      value={values.email}/>
                  </Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-3">
                  <Form.Label column sm="3">Horario</Form.Label>
                  <Col sm="9">
                    <Form.Control
                      name="schedule"
                      type="text"
                      isInvalid={!!errors.schedule}
                      onChange={handleChange}
                      value={values.schedule}/>
                  </Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-3">
                  <Form.Label column sm="3">Provincia</Form.Label>
                  <Col sm="9">
                    <Form.Control
                      name="province"
                      type="text"
                      isInvalid={!!errors.province}
                      onChange={handleChange}
                      value={values.province}/>
                  </Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-3">
                  <Form.Label column sm="3">Canton</Form.Label>
                  <Col sm="9">
                    <Form.Control
                      name="canton"
                      type="text"
                      isInvalid={!!errors.canton}
                      onChange={handleChange}
                      value={values.canton}/>
                  </Col>
                </Form.Group>
                <Form.Group  className="mb-4">
                  <Form.Label>Dirección</Form.Label>
                  <Form.Control
                    rows={2}
                    as="textarea"
                    name="address"
                    value={values.address}
                    onChange={handleChange}/>
                </Form.Group>
                {/* <Form.Group className="mb-3">
                  <Form.Label>Preparación</Form.Label>
                  <Form.Control
                    name="preparation"
                    as="textarea"
                    rows={8}
                    isInvalid={!!errors.preparation}
                    value={values.preparation}
                    onChange={handleChange}/>
                </Form.Group> */}
              </Col>
              <Col md="7">
                <Row>
                  <Form.Group className="mb-3">
                    <div className="d-flex justify-content-between">
                      <Form.Label column sm="3">Productos</Form.Label>
                      <Button className="text-info" title="Agregar Producto" variant="outline-light" size="sm" onClick={addCommodity}><i className="fas fa-plus-square"></i> Agregar</Button>
                    </div>
                    <Form.Control type="hidden" isInvalid={!!productsError} />
                    <Form.Control.Feedback type="invalid">
                      {productsError}
                    </Form.Control.Feedback>
                    <Table size="sm" variant="flush" style={{ display: 'block', maxHeight: '500px', overflowY: 'auto' }} ref={table}>
                      <thead className="position-sticky top-0 bg-white" style={{ zIndex: 2 }}>
                        <tr>
                          <th className="col-6">Producto</th>
                          <th className="col-3">Presentación</th>
                          <th className="col-3">Precio (₡)</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        {commodityRows &&
                          commodityRows.map((commodity: any, index: number) => (
                            <CommodityRow commodity={commodity} key={`in-row-${commodity._id}-${index}`} onAddProduct={(label: string) => setProductAdd(label)} onChange={(i: any) => {setCommodityByIndex(index, i)}} onRemove={() => {removeCommodity(index)}}/>
                          ))
                        }
                      </tbody>
                    </Table>
                  </Form.Group>
                </Row>
              </Col>
            </Row>
          </Form>
          )}
          </Formik>
        </Modal.Body>
        <Modal.Footer>          
          <Button variant="danger" onClick={close}>Cancelar</Button>
          <Button variant="primary" type="submit" form="supplier-form">Guardar</Button>
        </Modal.Footer>
      </Modal>
      {productAdd && 
      <ProductForm show={!!productAdd}
        product={{ name: productAdd }}
        close={() => setProductAdd('')}/>
      }
    </>
  )
}

export default SupplierForm;