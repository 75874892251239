import { IActivity } from "interfaces";
import { IState } from "./ActivityState";

export default function activityReducer(state: IState, action: { type: string; payload: any; }) {
  switch (action.type) {
    case "LOAD_ACTIVITIES":
      return {
        ...state,
        activities: action.payload.filter(
          (activities: IActivity) => !activities.state || activities.state !== 'inactive'
        ),
      };

    case "FOUND_ACTIVITIES":
      return {
        ...state,
        foundActivities: action.payload,
      };

    case "ADD_ACTIVITY":
      return {
        ...state,
        activities: [action.payload, ...state.activities],
      };

    case "EDIT_ACTIVITY":
      const updatedActivity = action.payload;

      const updatedActivities = state.activities.map((activity: IActivity) => {
        if (activity._id === updatedActivity._id) {
          return updatedActivity;
        }
        return activity;
      });

      return {
        ...state,
        activities: updatedActivities,
      };

    case "REMOVE_ACTIVITY":
      return {
        ...state,
        activities: state.activities.filter(
          (activities: IActivity) => !activities.state || activities.state !== 'inactive'
        ),
      };

    default:
      return state;
  }
};