
import { useContext, useEffect, useRef, useState } from "react";
import { Accordion, Badge, Breadcrumb, Button, Col, ListGroup, Row, Table } from "react-bootstrap"
import { useHistory } from "react-router";
import moment from "moment";
import { PreorderContext } from "context/preorders/PreorderState";
import { SupplierContext } from "context/suppliers/SupplierState";
import { OrderContext } from "context/orders/OrderState";
import { IPreorder, IProduct } from "interfaces";
import { useAuth } from 'context/auth/AuthContext';
import { calculateIva, round } from "utils/utilities";
import { ProductContext } from "context/products/ProductState";
import Loading from "views/Loading";


const PreorderList = () => {
  const history = useHistory();
  const { addOrder } = useContext(OrderContext);
  const { productsHash } = useContext(ProductContext);
  const { preorders, loading, editPreorder } = useContext(PreorderContext);
  const { suppliers } = useContext(SupplierContext);
  const [data, setData] = useState<any>(preorders);
  const { matchRole } = useAuth();

  const orders = useRef<any>({});

  useEffect(() => {
    if (!(productsHash && Object.keys(productsHash).length) || (!suppliers.length || !preorders.length)) { return; }
    const supplierHash: any = {};
    suppliers.forEach(s => { if (s._id) supplierHash[s._id] = s.name; });
    const parsedData = preorders.map((pO: IPreorder) => {
      const possibleOrders: any = {};
      pO.products.forEach((_p: any) => {
        console.log(_p.product._id);
        const p = { ..._p, priceIva: calculateIva(productsHash[_p.product._id], _p.unitPrice, _p.quantity, _p.totalPrice) }
        if (possibleOrders[p.supplier]) {
          possibleOrders[p.supplier].products.push(p);
          possibleOrders[p.supplier].total += p.totalPrice;
          possibleOrders[p.supplier].totalIva += p.priceIva;
        } else {
          possibleOrders[p.supplier] = { id: p.supplier, name: supplierHash[p.supplier], products: [p], total: p.totalPrice, totalIva: p.priceIva }
        }
      });
      return { ...pO, suppliersReady: pO.suppliersReady || [], possibleOrders }
    })
    setData(parsedData);
  }, [preorders, suppliers, productsHash]);

  const generateOrder = (preorder: any, supplierReady: any) => {
    const suppliersReady = [...preorder.suppliersReady, supplierReady.id];
    addOrder({
      orderCode: `${preorder.preorderCode.replace('P', 'O')}-${supplierReady.name.trim()}`,
      supplier:  supplierReady.id,
      program:  preorder.program,
      products: supplierReady.products,
      total:    supplierReady.total,
      totalIva: supplierReady.totalIva,
    });
    editPreorder({ ...preorder, suppliersReady });
  }

  return (
    <Col>
      <Row>
        <Col md="6">
          <h4 className="mt-0">Lista de preórdenes</h4>
          <p className="card-category">
            Puedes agregar, editar y eliminar preórdenes
          </p>
        </Col>
        <Col md="3">  
          
        </Col>
        <Col>
        {!matchRole(['consulta','contabilidad']) && 
          <Button className="float-end" variant="outline-primary" onClick={() => { history.push('preorders/new'); }}>Agregar Preorden <i className="fas fa-plus"></i></Button>
        }
        </Col>
      </Row>
      {!!loading && <Loading scale="0.5"></Loading> }
      <Accordion>
        {data.map((model: any, index: any)=>(
          <Accordion.Item key={`preorder-${model._id || index}`} eventKey={`preorder-${model._id || index}`}>
            <Accordion.Header className="mt-0">
              <div className="d-flex flex-grow-1 align-items-center pe-4">
                <b className="text-capitalize">{model.preorderCode},</b>&nbsp;
                {model.createdAt && <span> {moment(model.createdAt).format('LL')} </span>}
                {!matchRole(['consulta','contabilidad']) && 
                <span className="ms-auto btn btn-sm btn-outline-secondary" title="Editar" onClick={(e)=> {history.push(`preorders/${model._id}`);}}>
                  <i className="fas fa-pencil-alt"></i>
                </span>
                }
              </div>
            </Accordion.Header>
            <Accordion.Body>
              <h4>Productos a ordenar</h4>
              <ListGroup variant="flush">
                {!!model.possibleOrders &&
                  Object.values(model.possibleOrders).map((supplierProducts: any, index: number) => (
                    <ListGroup.Item key={`preorder-list-${index}`} className="d-flex pt-4">
                      <Col md='8'>
                        <h5>{ supplierProducts.name }</h5>
                        <Table>
                          <thead>
                            <tr>
                              <th className="col-6">Producto (receta(s))</th>
                              <th className="col-3">Cantidad</th>
                              <th className="col-3">Precio</th>
                              <th className="col-3">Precio + IVA</th>
                            </tr>
                          </thead>
                          <tbody>
                            {supplierProducts.products.map(({ product: p, presentation, recipes, quantity, priceIva, totalPrice }: any, index: number) => (                                 
                              <tr key={`preorder-list-product${index}`}>
                                <td>{p.name} {presentation}{p.unitOfMeasure} ({ recipes.length ? recipes.join(',') : 'adicional' })</td>
                                <td>{quantity}</td>
                                <td>₡{round(totalPrice, 2)}</td>
                                <td>₡{round(priceIva, 2)}</td>
                              </tr>
                            ))}
                            <tr>
                              <td  colSpan={2} className="text-end border-0">total:</td>
                              <td  className="border-0">₡{round(supplierProducts.total, 2)}</td>
                              <td  className="border-0">₡{round(supplierProducts.totalIva, 2)}</td>
                            </tr>
                          </tbody>
                        </Table>
                      </Col>
                      {!matchRole(['consulta','contabilidad']) && 
                      <Col className="d-flex align-items-center justify-content-end">
                          {model.suppliersReady.includes(supplierProducts.id)
                            ? <Badge bg="light" text="primary" className="fs-6">Orden Generada <i className="fas fa-check"></i></Badge>
                            : <Button className="text-info" title="Agregar" variant="light" onClick={() => {const confirmBox = window.confirm('¿Está seguro que desea crear la Orden?')
                              if(confirmBox === true){generateOrder(model, supplierProducts) }}}>Generar Orden <i className="fas fa-plus-circle"></i></Button>
                          }
                      </Col>
                      }
                    </ListGroup.Item>
                  ))
                }
              </ListGroup>
            </Accordion.Body>
          </Accordion.Item>
        ))}
      </Accordion>
    </Col>
  )
}

export default PreorderList
