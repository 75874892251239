import { createContext, useReducer, useEffect, useContext} from "react";
import axios from "axios";
import jwt_decode from "jwt-decode";
import { useHistory } from "react-router-dom";
import AuthReducer from "./AuthReducer";
import { userInfo } from "os";

const INITIAL_STATE = {
    user: null,
    isFetching: true,
    error: false,
};

const AuthContext = createContext(INITIAL_STATE);

const AuthProvider = (props: any) => {
    // const [user, setUser] = useState<any>(null);
    // const [loggedIn, setLoggedIn] = useState(false);
    // const [error, setError] = useState(false);
    const [state, dispatch] = useReducer(AuthReducer, INITIAL_STATE);
    const history = useHistory();

    useEffect(() => {
        dispatch({ type: "LOGIN_START" });
        refreshToken(true);
    }, []);

    const login = async (username: string, password: string) => {
        try {
            // dispatch({ type: "LOGIN_START" });
            const res = await axios.post("/api/login", {username, password });
            if (res){
                dispatch({ type: "LOGIN_SUCCESS", payload: res.data });
                history.push('/');
            } else {
                dispatch({ type: "LOGIN_FAILURE" });
            } 
        } catch (error) {
            // dispatch({ type: "LOGIN_FAILURE" });
            throw(error);
        }
    }
    const logout = async () => {
        try {
            const res = await axios.post("/api/logout");
            if (res){      
                dispatch({ type: "LOGOUT_SUCCESS" });
                history.push('/login');
            }
        } catch (error) {
            throw(error);
        }
    }

    const refreshToken = async (firstLoad = false) => {
        try {
            if (!state.user) {
                const refresh = axios.post("/api/refresh"), 
                      delay = new Promise( (resolve) => {setTimeout(resolve, firstLoad ? 1000 : 0)})
                const [res] = await Promise.all([refresh, delay]);
                if (res){
                    dispatch({ type: "LOGIN_SUCCESS", payload: res.data });
                    // history.push("/");
                } else {
                    dispatch({ type: "LOGIN_FAILURE" });
                    history.push("/");
                } 
                return res.data;
            }
        } catch (err) {
            dispatch({ type: "LOGIN_FAILURE" });
        }
    };
    
    // const axiosJWT = axios.create()

    axios.interceptors.request.use(
        async (config) => {
        if (state.user && state.user.accessToken) {
            let currentDate = new Date();
            const decodedToken: any = jwt_decode(state.user.accessToken);
            if (decodedToken.exp * 1000 > currentDate.getTime()) {
                const data = await refreshToken();
                config.headers["authorization"] = "Bearer " + state.user.accessToken;
            } else {
                refreshToken();
            }
        }
        return config;
        },
        (error) => {
        return Promise.reject(error);
        }
    );

    const matchRole = (rolesArray : Array) => {   
        return (rolesArray.includes(state.user.role))
    };

    const autContextValue = {
        user: state.user,
        isFetching: state.isFetching,
        error: state.error,
        login,
        logout,
        matchRole,
    };

    return <AuthContext.Provider value = { autContextValue } { ...props } />;
};


const useAuth: any = () => useContext(AuthContext);

export { AuthProvider, useAuth }